import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react'
import useDeviceDetector from "./hooks/useDeviceDetector";
import A2HSPrompt from './components/A2HS/Modal';

const A2HS = () => {

    let deferredPrompt = useRef(null);
    // const [userAgent, setUserAgent] = useState('')
    const [isAndroid, setIsAndroid] = useState(false)
    const [isApple, setIsApple] = useState(false)
    const [isWindows, setIsWindows] = useState(false)
    const [beforeInstallPrompt, setBeforeInstallPrompt] = useState(false)
    const deviceType = useDeviceDetector();
    
    function showInAppInstallPromotion() {
        setBeforeInstallPrompt(true);
    }
    useEffect(()=>{
        if(deviceType==="Mobile"){
            if (navigator.userAgent.match(/Android/i)) {
                setIsAndroid(true)
              } else if (navigator.userAgent.match(/iPhone|iPod/i)) {
                setIsApple(true)
              } else {
                setIsWindows(true)
            }
    
            window.addEventListener('beforeinstallprompt', (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            deferredPrompt.current = e;
            // Show your customized install prompt for your PWA
            // Your own UI doesn't have to be a single element, you
            // can have buttons in different locations, or wait to prompt
            // as part of a critical journey.
            showInAppInstallPromotion();
            });
        }
    },[deviceType])

    const handleClick = (e) => {
        e.preventDefault()
        deferredPrompt.current.prompt();
        deferredPrompt.current.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                console.log("User accepted the A2HS prompt");
            } else {
                console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt.current = null;
        });
    }


    return (
        <>
            {beforeInstallPrompt?<A2HSPrompt handleClick={handleClick}/>:false}
        </>
    )
}

export default A2HS
