import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyFormatter = ({ data }) => {
  const [copied, setCopied] = useState(false);

  const onCopyText = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="CopyItem d-flex gapp-10 align-items-center">
      <CopyToClipboard text={data} onCopy={onCopyText}>
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.49992 0.708496C2.85355 0.708496 0.708252 2.8538 0.708252 5.50016V11.4226C0.708252 11.7677 0.988074 12.0476 1.33325 12.0476C1.67843 12.0476 1.95825 11.7677 1.95825 11.4226V5.50016C1.95825 3.54415 3.54391 1.9585 5.49992 1.9585H11.344C11.6891 1.9585 11.969 1.67867 11.969 1.3335C11.969 0.988318 11.6891 0.708496 11.344 0.708496H5.49992Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.3354 3.66121C10.6346 3.35936 7.86528 3.35936 5.16448 3.66121C4.39509 3.7472 3.77681 4.3527 3.68612 5.12806C3.3658 7.86679 3.3658 10.6335 3.68612 13.3723C3.77681 14.1476 4.39509 14.7531 5.16448 14.8391C7.86528 15.141 10.6346 15.141 13.3354 14.8391C14.1048 14.7531 14.7231 14.1476 14.8137 13.3723C15.1341 10.6335 15.1341 7.86679 14.8137 5.12806C14.7231 4.3527 14.1048 3.7472 13.3354 3.66121ZM5.30333 4.90348C7.91184 4.61194 10.588 4.61194 13.1965 4.90348C13.3943 4.92558 13.5498 5.08201 13.5722 5.27327C13.8812 7.91552 13.8812 10.5848 13.5722 13.2271C13.5498 13.4183 13.3943 13.5747 13.1965 13.5968C10.588 13.8884 7.91185 13.8884 5.30333 13.5968C5.10553 13.5747 4.95003 13.4183 4.92766 13.227C4.61862 10.5848 4.61862 7.91552 4.92766 5.27327C4.95003 5.08201 5.10553 4.92558 5.30333 4.90348Z"
              fill="white"
            />
          </svg>
        </span>
      </CopyToClipboard>
      <div>{data}</div>

      {copied ? (
        <span className="CopiedText" style={{ color: "#04D9FF" }}>
          Copied!
        </span>
      ) : null}
    </div>
  );
};

export default CopyFormatter;
