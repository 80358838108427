import { LoadingButton } from '@mui/lab'
import React from 'react'


const CustomLoadingButton = ({loading, text, ...rest}) => {
  return (
    <LoadingButton
        loading={loading}
        loadingPosition="end"
        variant="outlined"
        {...rest}
        >
        {text || "Enter"}
    </LoadingButton>
  )
}

export default CustomLoadingButton
