import React from "react";
import "./HeadAndSub.css";

const HeadAndDesc = (props) => {
  // console.log(props['Martingale Status'],'ldjfldjkfj')
  // const classList = props.SubTitle === "Free Trial" ? ["SubTitle", "FreeTrialStatus"] : ["SubTitle"] && props.SubTitle === "Challenge" ? ["SubTitle", "ChallengeSTatus"] :  ["SubTitle"]  && props.SubTitle === "Varification" ? ["SubTitle", "VarificationSTatus"] : [`SubTitle ${props.SubTitleClass}`]
  return (
    <>
      <div className={`HeadDescContainer ${props.HeadAndSubClassName}`}>
        <p className={`HeadDesc ${props.HeadTitleClass}`}>{props.HeadTitle}</p>
        <p
          style={{ marginBottom: "0" }}
          className={`SubDesc ${props.SubTitleClass}`}
        >
          {props.SubTitle || "--"}
        </p>
      </div>
    </>
  );
};
export default HeadAndDesc;
