import { axiosInstance } from "../../Axios";
import { setLoading } from "./loader";
import sessionLogoutReducer from "./logout";
import { tokenConfig } from "./tokenReducer";

// Action Types
const SET_AFF_LIST = "SAVE_AFF_LIST";
const SET_COMMISSION_PAYMENT = "SAVE_COMMISSION_LIST";
const SET_AFF_DASHBOARD = "SAVE_AFF_DASHBOARD";
const CREATE_AFFILIATE_CODE_SUCCESS = "CREATE_AFFILIATE_CODE_SUCCESS";
const CREATE_AFFILIATE_CODE_FAILURE = "CREATE_AFFILIATE_CODE_FAILURE";
const SET_AFFILIATES_PAGINATED = "SET_AFFILIATES_PAGINATED"; // New action type
const SET_VISITOR_CHART = "SET_VISITOR_CHART"; // New action type
const SET_SELECTED_AFFILIATE = "SET_SELECTED_AFFILIATE";

// Action Creators
const setAffList = (data) => ({ type: SET_AFF_LIST, payload: data });
const setCommissionPayment = (data) => ({ type: SET_COMMISSION_PAYMENT, payload: data });
const setAffDashboard = (data) => ({ type: SET_AFF_DASHBOARD, payload: data });
const createAffiliateCodeSuccess = (data) => ({ type: CREATE_AFFILIATE_CODE_SUCCESS, payload: data });
const createAffiliateCodeFailure = (error) => ({ type: CREATE_AFFILIATE_CODE_FAILURE, payload: error });
const setAffiliatesPaginated = (data) => ({ type: SET_AFFILIATES_PAGINATED, payload: data });
const setVisitorChart = (data) => ({ type: SET_VISITOR_CHART, payload: data });
export const setSelectedAffiliate = (affiliate) => ({ type: SET_SELECTED_AFFILIATE, payload: affiliate, });

// Initial State
const initialState = {
    selectedAffiliate: null,
    dashboard: {},
    list: [],
    commission_payment: [],
    affiliate_code_creation: null,
    paginated_affiliates: [],
    visitor_chart: [],
    error: null,
};

// Reducer
const affiliateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AFF_LIST:
            return { ...state, list: action.payload };
        case SET_COMMISSION_PAYMENT:
            return { ...state, commission_payment: action.payload };
        case SET_AFF_DASHBOARD:
            return { ...state, dashboard: action.payload };
        case CREATE_AFFILIATE_CODE_SUCCESS:
            return { ...state, affiliate_code_creation: action.payload, error: null };
        case CREATE_AFFILIATE_CODE_FAILURE:
            return { ...state, error: action.payload };
        case SET_AFFILIATES_PAGINATED:
            return { ...state, paginated_affiliates: action.payload };
        case SET_SELECTED_AFFILIATE:
            return { ...state, selectedAffiliate: action.payload };
        case SET_VISITOR_CHART:
            return { ...state, visitor_chart: action.payload };
        default:
            return state;
    }
};

export const getPaginatedAffList = (page = 1, pageSize = 10, search) => async (dispatch, getState) => {
    try {
        const { data } = await axiosInstance.get(`/v2/list/affiliate/?page=${page}&page_size=${pageSize}&search=${search}`, tokenConfig(getState));
        dispatch(setAffiliatesPaginated(data));
    } catch (error) {
        if (error.response && error.response.data?.detail === 'invalid-auth-token') {
            dispatch(sessionLogoutReducer());
            return;
        }
    }
};

// Fetch visitor overview data
export const visitorChart = () => async (dispatch, getState) => {
    try {
        const { data } = await axiosInstance.get("/affiliate/visitor-overeview/", tokenConfig(getState));
        dispatch(setVisitorChart(data));
    } catch (error) {
        if (error.response && error.response.data?.detail === 'invalid-auth-token') {
            dispatch(sessionLogoutReducer());
            return;
        }
    }
};


export const requestAffiliateApproval = (payload) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        payload['idToken'] = idToken;
        const { data, status } = await axiosInstance.post("/affiliate/apply/", payload);
        if (status > 399) throw (data);
        return data;
    } catch (error) {
        if (error.response && error.response?.detail === "invalid-auth-token") {
            dispatch(sessionLogoutReducer());
            return;
        }
        throw (error);
    }
}
export const getAffList = (id, search , page = 1, pageSize = 10) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axiosInstance.get(`/affiliate/v2/code/list/?page=${page}&page_size=${pageSize}&affiliate_id=${id}&search=${search}`, tokenConfig(getState));
        dispatch(setAffList(data));
        console.log(id)
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        if (error.response.data?.detail === 'invalid-auth-token') {
            dispatch(sessionLogoutReducer());
            return;
        }
        // Optionally handle other errors
        console.error("Error fetching affiliate list:", error);
    }
}


export const getCommissionPayment = (page = 1, pageSize = 10 ,selectedAffiliate , search , statusFilter) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axiosInstance.get(`/affiliate/v2/commission/payment/?page=${page}&page_size=${pageSize}&affiliate_id=${selectedAffiliate}&search=${search}&status=${statusFilter}`, tokenConfig(getState));
        dispatch(setCommissionPayment(data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        if (error.response && error.response?.detail === "invalid-auth-token") {
            dispatch(sessionLogoutReducer());
            return;
        }
    }
}

export const getAffDashboard = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axiosInstance.get("/affiliate/v2/dashboard/", tokenConfig(getState));
        dispatch(setAffDashboard(data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        if (error.response && error.response?.detail === "invalid-auth-token") {
            dispatch(sessionLogoutReducer());
            return;
        }
    }
}

export const createAffiliateCode = (payload) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const config = {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
        };
        const { data, status } = await axiosInstance.post("affiliate/v2/commission/", payload, config);
        if (status > 399) throw data;
        dispatch(createAffiliateCodeSuccess(data));
        return data;
    } catch (error) {
        console.error('Error creating affiliate code:', error);
        dispatch(createAffiliateCodeFailure(error));
        if (error.response && error.response.data?.detail === "invalid-auth-token") {
            dispatch(sessionLogoutReducer());
            return;
        }
        throw error;
    }
};

// Export the reducer
export default affiliateReducer;
