
export const toggleSidebar = (data) => {
    return {
        type: 'SIDEBAR_TOGGLE',
        payload: data
    }
}

const initialState = {
    isSidebarOpen: false
};

const sidebarMReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SIDEBAR_TOGGLE':
            return ({
                ...state,
                isSidebarOpen: action.payload
            })
        default:
            return state;
    }
};

export const fetchtoggleSidebarDispatch = () => async (dispatch, getState) => {  
    
        dispatch(toggleSidebar(!getState().sidebar.isSidebarOpen));
 
}

export default sidebarMReducer;