import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { Select, MenuItem } from "@mui/material";
import "./PaginationComponent.scss";

const PaginationComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  setPageSize,
}) => {
  const [gotoPageInput, setGotoPageInput] = useState("");

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
      setGotoPageInput("");
    }
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    onPageChange(1);
  };

  const handleEllipsisClick = (direction) => {
    let newPage = currentPage;
    if (direction === "start") {
      newPage = Math.max(2, currentPage - 2); // Go back 2 pages
    } else if (direction === "end") {
      newPage = Math.min(totalPages - 1, currentPage + 2); // Go forward 2 pages
    }
    onPageChange(newPage);
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 5;

    // Add 'Prev' button
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        {prevIcon} <span className="pagination_prev">Previous</span>
      </Pagination.Prev>
    );

    // Always show the first page
    items.push(
      <Pagination.Item
        key={1}
        onClick={() => onPageChange(1)}
        active={currentPage === 1}
      >
        {String(1).padStart(2, "0")}
      </Pagination.Item>
    );

    let startPage = Math.max(2, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage > 3) {
      items.push(
        <Pagination.Ellipsis
          key="start-ellipsis"
          onClick={() => handleEllipsisClick("start")}
        />
      );
    }

    // Add pages in the range
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          onClick={() => onPageChange(i)}
          active={i === currentPage}
        >
          {String(i).padStart(2, "0")}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages - 2) {
      items.push(
        <Pagination.Ellipsis
          key="end-ellipsis"
          onClick={() => handleEllipsisClick("end")}
        />
      );
    }

    // Always show the last page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          active={currentPage === totalPages}
        >
          {String(totalPages).padStart(2, "0")}
        </Pagination.Item>
      );
    }

    // Add 'Next' button
    items.push(
      <Pagination.Next
        key="next"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        <span className="pagination_next">Next</span> {nextIcon}
      </Pagination.Next>
    );

    return items;
  };

  return (
    <div className="paginationComponentContainer">
      <Pagination>{renderPaginationItems()}</Pagination>
      <div className="pagination_controls">
        <div className="goto_page_input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
        <div className="pageSizeSelectorComponent">
          <Select
            className="pageSizeSelector"
            value={pageSize}
            onChange={handlePageSizeChange}
            displayEmpty
            inputProps={{ "aria-label": "Page Size Selector" }}
          >
            <MenuItem value={10}>10 / page</MenuItem>
            <MenuItem value={20}>20 / page</MenuItem>
            <MenuItem value={30}>30 / page</MenuItem>
            <MenuItem value={50}>50 / page</MenuItem>
            <MenuItem value={100}>100 / page</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;

const prevIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
  >
    <path
      d="M0.869839 3.99891C0.869839 3.82129 0.927345 3.64366 1.05057 3.50343L3.95052 0.203334C4.18876 -0.0677782 4.58308 -0.0677782 4.82132 0.203334C5.05956 0.474447 5.05956 0.923185 4.82132 1.1943L2.35678 3.99891L4.82132 6.80352C5.05956 7.07464 5.05956 7.52338 4.82132 7.79449C4.58308 8.0656 4.18876 8.0656 3.95052 7.79449L1.05057 4.49439C0.927345 4.35416 0.869839 4.17654 0.869839 3.99891Z"
      fill="#F6F6F6"
    />
  </svg>
);

const nextIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
  >
    <path
      d="M5.00125 4.00109C5.00125 4.17871 4.94375 4.35634 4.82052 4.49657L1.92058 7.79667C1.68234 8.06778 1.28801 8.06778 1.04977 7.79667C0.811534 7.52555 0.811534 7.07681 1.04977 6.8057L3.51431 4.00109L1.04977 1.19648C0.811534 0.925363 0.811534 0.476625 1.04977 0.205512C1.28801 -0.0656004 1.68234 -0.0656004 1.92058 0.205512L4.82052 3.50561C4.94375 3.64584 5.00125 3.82346 5.00125 4.00109Z"
      fill="#F6F6F6"
    />
  </svg>
);
