import React, { useRef, useState } from "react";
import "./style.scss";
import CompanyLogo from "../../../assets/images/company-logo.png";
import EmailIcon from "../../../assets/images/SignupImg/Email.svg";

import Tooltip from "../../../reusableComponents/Tooltip";
import { axiosInstance } from "../../../Axios";
import CustomLoadingButton from "../../../reusableComponents/CustomLoadingButton";
import ErrorPopup from "../../Popups/Error";
import CombinedPopup from "../../Popups/CombinedPopup";
import { useNavigate } from "react-router";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState("");
  const [isInputError, setIsInputError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPopupVisible, setErrorPopupVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  // CombinedPopup states
  const [showPopup, setShowPopup] = useState(false);
  const [combinedPUHead, setCombinedPUHead] = useState("");
  const [combinedPUBody, setCombinedPUBody] = useState("");
  const [popUpType, setPopUpType] = useState("");

  const emailRef = useRef(null);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setInputError("");
  };

  const handleBlur = (e) => {
    emailRef.current.classList.remove("input-error");
  };

  const handleFocus = () => {
    setIsInputError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    setLoading(true);
    if (!email) {
      setLoading(false);
      setErrorPopupVisible(true);
      setErrorMsg("Please enter a valid email");
      return;
    }
    axiosInstance
      .post("password/reset-request/", data)
      .then((res) => {
        console.log(res.data);

        if (res?.data?.error) {
          setErrorPopupVisible(true);
          setErrorMsg(res?.data?.error?.message);
          setLoading(false);
          return;
        }
        if (res.data.email) {
          setShowPopup(true);
          setPopUpType("success");
          setCombinedPUHead("Success!");
          setCombinedPUBody("Password Reset Link has been Sent to your Email");
          // navigate("/login");
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setErrorPopupVisible(true);
        setErrorMsg("Please try after some time");
      });
  };

  return (
    <>
      <div className="forgotpasswordform-container">
        <div className="forgotpasswordform-subcontainer">
          <div className="forgotpasswordform-top-banner">
            <h2 className="forgotpasswordform-title">Forgot Password?</h2>
            <p className="forgotpasswordform-subtitle">
              Don&#39;t worry! It happens please enter the email address
              associated wih your account
            </p>
          </div>

          <form className="forgotpasswordform-innercontainer forgotpasswordform-container_form">
            <div className="forgotpasswordform-subcontainer_form">
              <div className="forgotpassword-form-container">
                <div
                  ref={emailRef}
                  className="forgotpasswordform-innercontainer_form"
                >
                  <img
                    src={EmailIcon}
                    alt=""
                    className="forgotpasswordform-icon"
                  />
                  {/* <label className="forgotpasswordform-label" htmlFor="email">
                    Email Address
                  </label> */}
                  <div className="forgotpasswordform-container_input">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="forgotpasswordform-input"
                      value={email}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      placeholder="Enter Your E-Mail"
                    />
                  </div>
                  {/* {validateEmail(email) && ( */}
                  {/* <img
                  src={CheckSolidGreenIcon}
                  alt=""
                  className="forgotpasswordform-icon"
                />
              )} */}
                </div>
                <Tooltip isActive={isInputError}>{inputError}</Tooltip>
              </div>
            </div>
            {/* <p className="forgotpasswordform-error">{inputError}</p> */}
          </form>
          <div className="forgotpasswordform-container_button">
            <CustomLoadingButton
              className="forgotpasswordform-button"
              onClick={handleSubmit}
              text="submit"
              loading={loading}
              endIcon={false}
              disabled={loading}
            />

            <CombinedPopup
              type={popUpType}
              trigger={showPopup}
              setTrigger={setShowPopup}
              head={combinedPUHead}
              subhead={combinedPUBody}
            />
          </div>
        </div>
      </div>{" "}
      <ErrorPopup
        trigger={errorPopupVisible}
        setTrigger={setErrorPopupVisible}
        head="Forgot password error"
        subhead={errorMsg}
        onClick={(e) => {
          setErrorPopupVisible(false);
        }}
      />
    </>
  );
}

export default ForgotPasswordForm;
