
export const isAdmin = (data) => {
    return {
        type: "IS_ADMIN",
        payload: data,
    };
};




const AdminReducer = (state = false, action) => {
    switch (action.type) {
        case "IS_ADMIN":
            return action.payload
        default:
            return state;
    }
}

// export const fetchloginReducer = () => async (dispatch, getState) => {
//     try {
//         const idToken = await getState().ACCtoken;
//         const payload = {
//             idToken,
//         };
//         const data = await axiosInstance.post("/email/signin/", payload)
//         console.log("LoginStat", data);
//         dispatch(LoginStat(data));
       

//     }
//     catch (error) { }
// }

export default AdminReducer