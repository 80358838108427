import React, { useState } from "react";
import "./popup.scss";
import Cross from "../../assets/icons/NewIcons/exittIcon.svg";
import { MenuItem, Select } from "@mui/material";

const AddNewAddressPopup = (props) => {
  const [SelectAddress, setSelectAddress] = useState();
  const handleSelectAddress = (e) => {
    setSelectAddress(e.target.value);
  };
  return props.trigger ? (
    <div className="Main-PoupupDiv allADD addNewAddressContainer">
      <div className="PopupMainCon addNewAddressWrapper ">
        {/* <div className="PopupMainCon-BG-Blur"></div> */}

        <div className="PopUpTextCon AllADD form_container">
          <form onSubmit={props.onSubmitHandle} className="main_form">
            <div className="AllADD-T titleSection ">
              Add New Address{" "}
              <div className="crossIconimg_div">
                <img
                  onClick={() => {
                    props.setTrigger(false);
                    try {
                      props.setTrigger2(false);
                    } catch (error) {
                      // console.log('noTrigger2')
                    }
                  }}
                  src={Cross}
                  alt=""
                />
              </div>
            </div>
            <div className="item_container">
              <div className="addNewADD-item formSection">
                <h1>Apartment No.</h1>
                <input
                  type="text"
                  value={props.AprtNo}
                  onChange={props.handleChangeAprtNo}
                />
              </div>
              <div className="addNewADD-item formSection">
                <h1>Street Address</h1>
                <input
                  type="text"
                  value={props.street}
                  onChange={props.handleChangeStreet}
                />
              </div>
              <div className="addNewADD-item formSection">
                <h1>State</h1>
                {/* <input
                  type="text"
                  value={props.State}
                  onChange={props.handleChangeState}
                /> */}
                <div className="selector_section">
                  <Select
                    className="selectAddress_div"
                    value={SelectAddress}
                    onChange={handleSelectAddress}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"address_1"}>State 1</MenuItem>
                    <MenuItem value={"address_2"}>State 2</MenuItem>
                    <MenuItem value={"address_3"}>State 3</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="addNewADD-item formSection">
                <h1>Zip Code</h1>
                <input
                  type="number"
                  value={props.Zip}
                  onChange={props.handleChangeZip}
                />
              </div>
              {/* <div className="addNewADD-item formSection">
                <h1>City</h1>
                <input
                  type="text"
                  value={props.City}
                  onChange={props.handleChangeCity}
                />
              </div> */}
              <div className="addNewADD-item formSection">
                <h1>Country</h1>
                {/* <input
                  type="text"
                  value={props.AddCountry}
                  onChange={props.handleChangeAddCountry}
                /> */}
                <div className="selector_section">
                  <Select
                    className="selectAddress_div"
                    value={SelectAddress}
                    onChange={handleSelectAddress}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"address_1"}>Nepal</MenuItem>
                    <MenuItem value={"address_2"}>India</MenuItem>
                    <MenuItem value={"address_3"}>USA</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <div className="button_section ">
              <button
                type="submit"
                // onClick={() => {
                //   props.setTrigger(false);
                //   try {
                //     props.setTrigger2(false);
                //   } catch (error) {
                //     // console.log('noTrigger2')
                //   }
                // }}
                className="AllADD-new-add save"
              >
                Save and add address
              </button>
            </div>
          </form>
        </div>
        <div className="PopupBoxThird"></div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default AddNewAddressPopup;
