import Axios from "axios";
import { myStore } from ".";
import { handleInvalidToken } from "./store/reducers/tokenReducer";

Axios.defaults.baseURL =
  window.location.origin === "http://139.5.189.142:3072"
    ? "http://13.41.109.19"
    : "https://backend.alphacapitalgroup.uk";

// Axios.defaults.baseURL = "http://localhost:8000/";
export const axiosInstance = Axios.create({});

let refreshPromise = null;
const clearRefreshPromise = () => (refreshPromise = null);
console.log(window.location.origin, "origin");
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If idToken expired, get a fresh token and process the request
    if (
      error.response.status === 401 &&
      error.response.data?.detail === "invalid-auth-token" &&
      !originalRequest._retry
    ) {
      const { getState, dispatch } = myStore;
      originalRequest._retry = true;
      if (!refreshPromise) {
        refreshPromise = dispatch(handleInvalidToken())
          .then((token) => {
            return token;
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(clearRefreshPromise);
      }
      const token = await refreshPromise;
      originalRequest.headers.Authorization = token;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
