import React from "react";
import "./popup.scss";
import errPU from "../../assets/images/popups/Red.svg";
import Cross from "../../assets/images/Cross.svg";
import Bubble from "../../assets/images/errortick.svg";
import Buttons from "../ReusableComponents/formButton";

const ErrorPopup = (props) => {
  return props.trigger ? (
    <div className="Main-PoupupDiv">
      <div className="PopupBubble">
        <img src={Bubble} alt="" />
      </div>
      <div className="PopupMainCon ">
        {/* <div className="PopupMainCon-BG-Blur"></div> */}
        <div className="PopupBubble-Btm">
          <img src={errPU} alt="" />
        </div>
        <div className="PopUpTextCon">
          <h1>{props.head}</h1>
          <h2>{props.subhead}</h2>
        </div>
        <div className="PopupBoxThird"></div>
      </div>{" "}
      <Buttons
        className="PopupBoxButton popup-btn"
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e);
          } else {
            props.setTrigger(false);
            try {
              props.setTrigger2(false);
            } catch (error) {
              // console.log('noTrigger2')
            }
            if (props.onClick) {
              console.log(props.onClick);
              props.onClick();
            }
          }
        }}
        src={Cross}
        alt=""
      >
        Continue
      </Buttons>
    </div>
  ) : (
    false
  );
};

export default ErrorPopup;
