import { combineReducers } from "redux";
import loggedReducer from "./isLogged";
import ipdReducer from "./ipd";
import { persistReducer } from "redux-persist";
import { tokenReducer, RefftokenReducer, tokenExpiryReducer } from "./tokenReducer";
import storage from "redux-persist/lib/storage";
import { SidebarMain, SidebarSub } from "./sidebarReducer";
import dashboardReducer from "./dashboardReducer";
import paymentReducer from "./paymentReducer";
import sidebarMReducer from "./sidebarMReducer";
import userProfileReducer from "./userProfileReducer";
import AddressReducer from "./AddressReducer";
import competitionReducer from "./competitionReduder";
import TraderListReducer from "./TraderListReducer";
import SupportCasesReducer from "./SupportReducer";
import AdminReducer from "./adminReducer";
import TrademateReducer from "./trademateReducer";
import affiliateReducer from "./affiliateReducer";
import notificationReducer from "./notifications";
import achievementsReducer from "./achievementsReducer"
import payoutReducer from "./payoutReducer";
import themeReducer from "./themeReducer"
import loaderReducer from "./loader";
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "isLogged",
    "ipd",
    "SidebarM",
    "SidebarS",
    "ACCtoken",
    "expiresIn",
    "REFFtoken",
    "dashboard",
    "payments",
    "user",
    "selectAdress",
    "accountCreate",
    "traderlist",
    "supportcases",
    "isAdmin",
    "trademate",
    "affiliate",
    "notificationReducer",
    "achievementsReducer",
    "payoutReducer",
    "themeReducer"
  ],
};

const rootReducer = combineReducers({
  isLogged: loggedReducer,
  ipd: ipdReducer,
  sidebarM: SidebarMain,
  sidebarS: SidebarSub,
  ACCtoken: tokenReducer,
  REFFtoken: RefftokenReducer,
  expiresIn: tokenExpiryReducer,
  dashboard: dashboardReducer,
  payments: paymentReducer,
  sidebar: sidebarMReducer,
  user: userProfileReducer,
  selectAdress: AddressReducer,
  accountCreate: competitionReducer,
  traderlist: TraderListReducer,
  supportcases: SupportCasesReducer,
  isAdmin: AdminReducer,
  trademate: TrademateReducer,
  affiliate: affiliateReducer,
  notifications: notificationReducer,
  achievements: achievementsReducer,
  payout: payoutReducer,
  theme: themeReducer ,
  loader: loaderReducer
});

export default persistReducer(persistConfig, rootReducer);
