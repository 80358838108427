import { SET_THEME, TOGGLE_THEME } from "../actions/index.jsx";

// Retrieve the theme mode from localStorage or default to "light"
const storedTheme = localStorage.getItem("themeMode");
const initialState = {
  theme: storedTheme || "light",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      // Toggle between "light" and "light-new"
      const newTheme = state.theme === "light-new" ? "light" : "light-new";
      localStorage.setItem("themeMode", newTheme); // Save the updated theme to localStorage
      return {
        ...state,
        theme: newTheme,
      };

    case SET_THEME:
      // Set the theme to the specified value
      localStorage.setItem("themeMode", action.payload); // Save the updated theme to localStorage
      return {
        ...state,
        theme: action.payload,
      };

    default:
      return state;
  }
};

export default themeReducer;
