import React from "react";
import "./style.scss";
// import FXLogo from "../../../assets/icons/fx-logo.svg";
import LaptopDashboard from "../../../assets/images/laptop.png";
import BackdropTop from "../../../assets/icons/backdrop-top.svg";
import BackdropMiddle from "../../../assets/icons/backdrop-middle.svg";
import BackdropRight from "../../../assets/icons/backdrop-right.svg";
import Logo from "../../../assets/images/SignupImg/Logo.svg";
import AuthPhaseImg from "../../../assets/images/SignupImg/AuthPhaseImg.svg";
import MobileAuthPhase from "../../../assets/images/SignupImg/MobileAuthPhase.svg";

function AuthFrame() {
  return (
    <div className="authframe-container">
      <div className="authframe-container_logo">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="auth_title">
        <h1 className="auth_title_tag">
          Where Demo Traders <span>Become Profitable Traders</span>
        </h1>
      </div>
      <div className="phaseImages">
        <img src={AuthPhaseImg} alt="AuthPhaseImg" />
      </div>
      {/* <div className="mobileAuthPhase">
        <img src={MobileAuthPhase} alt="AuthPhaseImg" />
      </div> */}
      <div className="mobileAuthPhase">
        <div className="phases">
          <div className="phase_main">
            <div className="phase_1">
              <h2>Phase 1</h2>
            </div>
            <div className="side_phase_desc">
              <h3>Choose Your Evaluation</h3>
            </div>
          </div>
          <div>
            <p>Achieve an 8% target without time limits</p>
          </div>
        </div>
        <div className="phase_line"></div>
        <div className="phases">
          <div className="phase_main">
            <div className="phase_1">
              <h2>Phase 2</h2>
            </div>
            <div className="side_phase_desc">
              <h3>Prove Your Skills</h3>
            </div>
          </div>
          <div>
            <p>Establish a track record, hit a 5% target</p>
          </div>
        </div>
        <div className="phase_line"></div>
        <div className="phases">
          <div className="phase_main">
            <div className="phase_1">
              <h2>Phase 3</h2>
            </div>
            <div className="side_phase_desc">
              <h3>Get Paid to Demo Trade</h3>
            </div>
          </div>
          <div>
            <p>
              Qualify as an Alpha analyst and start trading your way to
              profitability
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthFrame;
