import React, { useEffect, useState } from 'react'
// import SumsubWebSdk from '@sumsub/websdk-react'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getSumsubToken } from '../../store/reducers/payoutReducer'


const Sumsub = () => {

    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState('');

    const getAccessToken = () => {
        dispatch(getSumsubToken())
        .then(res=>{
            console.log("sumsum token", res);
            setAccessToken(res['token'])
        })
        .catch(err=>{console.log(err)})
    }

    useEffect(()=>{
        getAccessToken()
    },[])


    const accessTokenExpirationHandler = (args) => {
        console.log("accessTokenExpirationHandler args", args)
        getAccessToken();
    }
    
    const messageHandler = (message) => {
        console.log("messageHandler args", typeof(idCheck))
        if(message === "idCheck.applicantReviewComplete"){
            // KYC Approved
            console.log("KYC Approved")
        }
    }

    const errorHandler = (args) => {
        console.log("errorHandler args", args)
    }


    return (
        <div>
        <h1>Sumsub Integration</h1>
        {accessToken?<div
            accessToken={accessToken}
            expirationHandler={accessTokenExpirationHandler}
            // config={config}
            // options={options}
            onMessage={messageHandler}
            onError={errorHandler}
            />:false}
        <Button>Verify with Sumsub</Button>  
        </div>
    )
}

export default Sumsub
