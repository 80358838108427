import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import toast from "react-hot-toast";
dayjs.extend(utc);
dayjs.extend(timezone);

export function capitalizeFirstLetter(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
}

export function convertSnakeCaseToDefault(str) {
  return str
    .split("_")
    .map((item) => capitalizeFirstLetter(item))
    .join(" ");
}

export function convertToMillions(num) {
  const str = num.toString().split("").reverse().join("");
  const parts = str.match(/.{1,3}/g);
  const result = parts.join(",").split("").reverse().join("");
  return result;
}

export function validateLetters(key) {
  const validRegex = /^[a-zA-Z\s]$/;
  return validRegex.test(key) || key === "Backspace";
}

export function validateContact(key) {
  const validRegex = /^[0-9+]+$/;
  return validRegex.test(key) || key === "Backspace";
}

export function validateEmail(email) {
  const validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return validRegex.test(email);
}

export function validatePassword(password) {
  return password.length >= 8;
}

export const formatDateInTimeZone = (
  date,
  format = "DD-MM-YYYY",
  timeZone = "America/Chicago"
) => {
  if (!date) {
    return "N/A"; // Return "N/A" if no date is provided
  }

  // Parse the date string as UTC and then convert to the desired time zone
  return dayjs.utc(date).tz(timeZone).format(format);
};

export const FormatUSD = (data) => {
  return Number(data)?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const handleCopyToClipboard = (text) => {
  if (text !== "") {
    toast.success(text || "Copied to clipboard!", {
      style: {
        border: "1px solid #0D56EB",
        background: "#0E1011",
        color: "#fff",
      },
    });
  }
};
