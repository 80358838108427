import React from "react";
import "./style.scss";
import errorModalIcon from "../../assets/icons/errorModalIcon.svg";
import backdropL1 from "../../assets/icons/backdrop-L1.svg";
import backdropL2 from "../../assets/icons/backdrop-L2.svg";
import backdropL3 from "../../assets/icons/backdrop-L3.svg";
import backdropR1 from "../../assets/icons/backdrop-R1.svg";
import backdropR2 from "../../assets/icons/backdrop-R2.svg";
import backdropR3 from "../../assets/icons/backdrop-R3.svg";

function ErrorModal({ setModalOpen, desc, btnText }) {
  return (
    <div className="error_modal_overlay">
      <div className="error_modal_container_top">
        <div className="error_icon">
          <img src={errorModalIcon} alt="errorModalIcon" />
        </div>
      </div>
      <div className="error_modal_container">
        <div className="error_modal_container_bottom">
          <h1>Error</h1>
          <p>{desc}</p>
          <div className="middle-wrapper">
            <div className="backdrop-left">
              <img className="backdropL1" src={backdropL1} alt="backdropL1" />
              <img className="backdropL2" src={backdropL2} alt="backdropL2" />
              <img className="backdropL3" src={backdropL3} alt="backdropL3" />
            </div>
            <div className="backdrop-right">
              <img className="backdropR3" src={backdropR3} alt="backdropR3" />
              <img className="backdropR2" src={backdropR2} alt="backdropR2" />
              <img className="backdropR1" src={backdropR1} alt="backdropR1" />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-wrapper">
      <button
        className="error_modal_close_btn"
        onClick={() => setModalOpen(false)}
      >
        {btnText}
      </button>
      </div>
    </div>
  );
}

export default ErrorModal;
