import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const Loader = ({ open, top }) => {
  return (
    open && (
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => 99 || theme.zIndex.drawer + 1111,
          position: "fixed",
          height: "100%",
        }}
        open={open}
      >
        <CircularProgress
          color="inherit"
          sx={{ position: "absolute", top: top || "40vh" }}
        />
      </Backdrop>
    )
  );
};
export const Loader2 = ({ open, top }) => {
  return (
    open && (
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => 99 || theme.zIndex.drawer + 1111,
          position: "absolute",
          height: "100%",
        }}
        open={open}
      >
        <CircularProgress
          color="inherit"
          sx={{ position: "absolute", top: top || "40%" }}
        />
      </Backdrop>
    )
  );
};

export default Loader;
