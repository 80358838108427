import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import allReducer from "./store/reducers";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import swDev from "./swDev";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const myStore = createStore(
  allReducer,
  composeEnhancer(applyMiddleware(thunk))
);
const persistor = persistStore(myStore);
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
ReactDOM.render(
  <Provider store={myStore}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// swDev();
