const loggedReducer = (state = true, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return true;
    case "SIGN_OUT":
      return false;
    case "UPDATE_ADMIN":
      return action.payload;
    default:
      return true;
  }
};

export default loggedReducer;
