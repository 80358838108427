export const login = () => {
  return {
    type: "SIGN_IN",
  };
};
export const logout = () => {
  return {
    type: "SIGN_OUT",
  };
};

export const UserAdmin = (payload) => {
  return {
    type: "UPDATE_ADMIN",
    payload,
  };
};

export const std = (num) => {
  return {
    type: "SET",
    payload: num,
  };
};

export const AcctokenD = (num) => {
  return {
    type: "SETACCTOKEN",
    payload: num,
  };
};

export const RefftokenD = (num) => {
  return {
    type: "SETREFFACCTOKEN",
    payload: num,
  };
};

export const SidebarMainAC = (num) => {
  return {
    type: "SETSIDEBARMAIN",
    payload: num,
  };
};
export const SidebarSubAC = (num) => {
  return {
    type: "SETSIDEBARSUB",
    payload: num,
  };
};
export const TOGGLE_THEME = "TOGGLE_THEME";
export const SET_THEME = "SET_THEME";

export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});
export const setTheme = (str) => {
  return {
    type: SET_THEME,
    payload: str,
  };
};
