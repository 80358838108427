import React, {useState, useEffect} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Grid, Tab, LinearProgress} from "@mui/material";
import OnGoing from "../components/Competition/OnGoing";
import CompetitionBox from "../components/ReusableComponents/CompetitionBox";
import CompDetForm from "../components/Competition/CompDetForm";
import CompFunding from "../components/Competition/CompFunding";
import axios from "axios";
import {useSelector} from "react-redux";
import LeaderBoard from "./../components/Dashboard/Leaderboard";
import Leaderboard from "./Leaderboard";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import DateCountdown from "react-date-countdown-timer";
import Countdown from "react-countdown";
import Timer from "../components/ReusableComponents/Timer";
import {useParams} from "react-router";
import CountDown from "../utilities/Countdown";
import {useDispatch} from "react-redux";
import {registerCompetition} from "../store/reducers/competitionReduder";
import CombinedPopup from "../components/Popups/CombinedPopup";
import Title from "../components/ReusableComponents/title";
import CopyFormatter from "../utilities/CopyFormatter";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import logoutReducer from "../store/reducers/logout";
import {axiosInstance} from "../Axios";
import {tokenConfig} from "../store/reducers/tokenReducer";
import Loader from "../reusableComponents/Loader";
import { setLoading } from "../store/reducers/loader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: none;
`;

const LeaderboardList = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("End");
  const [SelectedCompId, setSelectedCompId] = React.useState(false);
  const tabHeader = ["Alpha", "Community"];
  const [innerTab, setInnerTab] = React.useState("Alpha");
  const accountCreate = useSelector(
    (state) => state.accountCreate.isAccountCreating
  );

  const loader = useSelector((state)=>state.loader.is_loading)
  const [open,setOpen] = useState(loader)
  useEffect(()=>{
    setOpen(loader)
  },[loader])
  const Params = useParams();
  useEffect(() => {
    if (Params.id) {
      setValue("Ongoing");
    }
  }, [Params]);

  const handleChange = (event, newValue) => {
    setValue(event.currentTarget.textContent);
  };
  const handleInnerTabChange = (event, newValue) => {
    setInnerTab(event.currentTarget.textContent);
  };
  // let [loading, setLoading] = useState(false);
  const [Rules, setRules] = useState(false);
  const [Awards, setAwards] = useState([]);
  const [CompName, setCompName] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [CombinedPUHead, setCombinedPUHead] = useState("");
  const [CombinedPUBody, setCombinedPUBody] = useState("");
  const [PopupClass, setPopupClass] = useState("");
  const [PopUpType, setPopUpType] = useState("");
  const ACCtoken = useSelector((state) => state.ACCtoken);
  const [Show, setShow] = useState(false);
  const [ShowRules, setShowRules] = useState(false);
  const [ShowList, setShowList] = useState(true);
  const [ShowSummary, setShowSummary] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showLeaderboard, setshowLeaderboard] = useState(false);
  const [LeaderBoardText, setLeaderBoardText] = useState();
  const [Submitbutton, setSubmitbutton] = useState(false);
  const [Data, setData] = useState();
  const [activeTab, setActiveTab] = useState(0)
  const [nastedActiveTab, setNastedActiveTab] = useState(0)
  const handleChangeOngoing = (e, id) => {
    setShowRules(true);
    setShow(true);

    setSelectedCompId(id);
    Object.keys(DataUpcomming).map((data) => {
      if (innerTab === "Alpha") {
        return DataUpcomming[data].map((info) => {
          if (info.id === id) {
            setRules(info.Competition_rules);
            setAwards(info.awards);
            setCompName(info.competition_name);
          }
        });
      } else {
        return isCommunityData[data].map((isData) => {
          if (isData.id === id) {
            setRules(isData.Competition_rules);
            setAwards(isData.awards);
            setCompName(isData.competition_name);
          }
        });
      }
    });
  };

  const handleLeaderboardClick = (e, id) => {
    navigate(`/CompetitionOverview/${id}`);
  };

  const handleChangeParticipated = (e, id) => {
    // e.preventDefault();
    // setshowLeaderboard(true);
    // const button = e.currentTarget.getAttribute("value");
    let data = [];
    DataUpcomming["Participated"].forEach((item) => {
      if (item.id === id) data = item.summary;
    });
    setData(data);
    setShowSummary(true);
    setShowRules(true);
    Object.keys(DataUpcomming).map((data) => {
      return DataUpcomming[data].map((info) => {
        if (innerTab === "Alpha") {
          return DataUpcomming[data].map((info) => {
            if (info.id === id) {
              setAwards(info.awards);
              setRules(info.Competition_rules);
            }
          });
        } else {
          return isCommunityData[data].map((isData) => {
            if (isData.id === id) {
              setAwards(isData.awards);
              setRules(isData.Competition_rules);
            }
          });
        }
      });
    });
    // setLeaderBoardText(button);

    // sessionStorage.setItem("compiType", button);
  };
  const navigate = useNavigate();
  const handleSubmitForm = (e, BasicDetails) => {
    e.preventDefault();

    // setShowPayment(true);
    if (SelectedCompId) {
      setShowPopup(true);
      setCombinedPUHead("Woo hoo !");
      setCombinedPUBody("We will inform you as soon as the form is submitted");
      setPopUpType("success");
      setPopupClass("loaderPopupsuccess");
      setSubmitbutton(true);
      setLoading(true);
      dispatch(registerCompetition(SelectedCompId, BasicDetails))
        .then((res) => {
          setLoading(false);
          setData(res.data);
          getCompetitionAPI();
          setSubmitbutton(false);
          window.location.reload();
        })
        .catch((error) => {
          setSubmitbutton(true);
          setLoading(false);
          if (error.message) {
            setShowPopup(true);
            setCombinedPUHead("Oh snap!");
            setCombinedPUBody(error.message);
            setPopUpType("error");
            setPopupClass("loaderPopupsuccess");
          }
        });
    }
    // setShow(true)

    // setShow(!Show)
  };

  const [DataUpcomming, setDataUpcomming] = useState([]);
  // const [isComDataTrue, setIsComDataTrue] = useState([]);
  // const [isComDataFalse, setIsComDataFalse] = useState([]);
  const [isCommunityData, setIsCommunityData] = useState([]);

  function truefilteredData(json) {
    let data = {
      End: [],
      Participated: [],
    };
    const endData = json?.End?.filter((item) => item.is_community === true);
    const participatedData = json?.Participated?.filter(
      (item) => item.is_community === true
    );
    data["End"] = endData;
    data["Participated"] = participatedData;
    return data;
  }
  function falsefilteredData(json) {
    let data = {
      End: [],
      Participated: [],
    };
    const endFilterData = json?.End?.filter(
      (item) => item.is_community === false
    );
    const endData = endFilterData.sort(
      (a, b) => new Date(b.End_Date) - new Date(a.End_Date)
    );
    const participatedData = json?.Participated?.filter(
      (item) => item.is_community === false
    );

    data["End"] = endData;
    data["Participated"] = participatedData;
    return data;
  }
  const getCompetitionAPI = () => {
    dispatch(setLoading(true))
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    config.headers["AUTHORIZATION"] = ACCtoken;
    axiosInstance
      .get("v2/get/competition/", config)
      .then((res) => {
        dispatch(setLoading(false))

        if (res.data === "No Cases found!") {
          alert("No Cases found!");
          return;
        }
        if (res.data === "Please provide Access Token!") {
          alert("Please provide Access Token!");
          return;
        }
        if (res.data === "Please verify the Access Token!") {
          dispatch(logoutReducer());
          return;
        }
        const slicedData = Object.fromEntries(
          Object.entries(res.data).slice(-2)
        );

        const trueData = truefilteredData(slicedData);
        const falseData = falsefilteredData(slicedData);
        // setIsComDataTrue(trueData);
        // setIsComDataFalse(falseData);
        setIsCommunityData(trueData);
        setDataUpcomming(falseData);
      })
      .catch(function (error) {
        dispatch(setLoading(false))

        console.log(error);
      });
  };

  useEffect(() => {
    setLeaderBoardText(sessionStorage.getItem("compiType"));
    getCompetitionAPI();

    // sessionStorage.setItem("compiType", "50k");
  }, []);

  const handleTabOne = (index) => {
    setShow(false);
    setShowList(true);
    setShowSummary(false);
    setShowRules(false);
    setActiveTab(index)
  };
  const handleTabTwo = (index) => {
    setShow(false);
    setShowList(true);
    setShowSummary(false);
    setShowRules(false);
    setNastedActiveTab(index)
  };

  const handleCross = () => {
    setShow(false);
    navigate("/Dashboard");
  };

  return (
    <>
      <CombinedPopup
        type={PopUpType}
        trigger={ShowPopup}
        setTrigger={setShowPopup}
        head={CombinedPUHead}
        subhead={CombinedPUBody}
        setShow={setShow}
        onClick={handleCross}
        Popupclass={PopupClass}
      />
      {!showPayment && (
        <Box className="ContainerTab MainTab leaderboard-page-container" p={1} style={{position:"relative"}}>
        <Loader open={open}/>
          <TabContext value={value}>
            <Box
              sx={{
                // borderBottom: 1,
                // borderColor: "divider",
                marginBottom: "15px",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="newTabination"
              >
                {Object.keys(DataUpcomming).map((data, idx) => {
                  return (
                    <Tab
                    className={activeTab === idx ? "newTabination-item active" : "newTabination-item"}
                      label={data}
                      value={data}
                      onClick={()=>handleTabOne(idx)}
                      key={idx}
                    />
                  );
                })}
              </TabList>
            </Box>
            <TabContext value={innerTab}>
              <Box
                sx={{
                  // borderBottom: 1,
                  // borderColor: "divider",
                  marginBottom: "15px",
                }}
              >
                <TabList
                  onChange={handleInnerTabChange}
                  aria-label="lab API tabs example"
                  className="newTabination"
                >
                  {tabHeader.map((data, idx) => {
                    return (
                      <Tab
                      className={nastedActiveTab === idx ? "newTabination-item nastedActive" : 'newTabination-item' }
                        label={data}
                        value={data}
                        onClick={()=>handleTabTwo(idx)}
                        key={idx}
                      />
                    );
                  })}
                </TabList>
              </Box>
            </TabContext>

            <TabPanel value={value} className="leaderboard-competition-tabs">
              {!Show && ShowList && !ShowSummary && (
                <div className="d-flex gapp-10 CompB">
                  <Grid className="CompListB" container spacing="10px">
                    {Object.keys(DataUpcomming).map((data) => {
                      if (innerTab === "Alpha") {
                        return DataUpcomming[data].map((info, idx) => {
                          if (value === data) {
                            const participated =
                              info.participated ||
                              data.toLowerCase().includes("participated");
                            const ended = data.toLowerCase().includes("end")
                              ? true
                              : false;
                            const is_enter_competition = new Date(
                              info.Schedule_competition
                            );
                            const now = new Date();
                            return (
                              <>
                                <CompetitionBox
                                  CompDate=""
                                  data={info}
                                  button={
                                    participated
                                      ? "See Leaderboard"
                                      : ended
                                      ? "See Leaderboard"
                                      : "Enter Competition"
                                  }
                                  ButtonShow={is_enter_competition < now}
                                  ended={ended}
                                  handleChangeButton={(e) =>
                                    handleChangeOngoing(e, info.id)
                                  }
                                  handleParticipated={(e) =>
                                    handleChangeParticipated(e, info.id)
                                  }
                                  // Submitbutton={Submitbutton}
                                  handleclick={(e) => {
                                    if (participated || ended) {
                                      // handleChangeParticipated(e, info.id)
                                      handleLeaderboardClick(e, info.id);
                                    } else if (!ended) {
                                      console.log("CHECK upcoming");
                                      handleChangeOngoing(e, info.id);
                                    }
                                  }}
                                  isCommunity={
                                    innerTab === "Community" ? true : false
                                  }
                                  Submitbutton={accountCreate}
                                  Participated={participated}
                                  key={idx}
                                />
                              </>
                            );
                          }
                        });
                      } else {
                        return isCommunityData[data].map((isData, index) => {
                          if (value === data) {
                            const participated =
                              isData.participated ||
                              data.toLowerCase().includes("participated");
                            const ended = data.toLowerCase().includes("end")
                              ? true
                              : false;
                            const is_enter_competition = new Date(
                              isData.Schedule_competition
                            );
                            const now = new Date();
                            return (
                              <>
                                <CompetitionBox
                                  CompDate=""
                                  data={isData}
                                  button={
                                    participated
                                      ? "See Leaderboard"
                                      : ended
                                      ? "See Leaderboard"
                                      : "Enter Competition"
                                  }
                                  ButtonShow={is_enter_competition < now}
                                  ended={ended}
                                  handleChangeButton={(e) =>
                                    handleChangeOngoing(e, isData.id)
                                  }
                                  handleParticipated={(e) =>
                                    handleChangeParticipated(e, isData.id)
                                  }
                                  // Submitbutton={Submitbutton}
                                  handleclick={(e) => {
                                    if (participated || ended) {
                                      // handleChangeParticipated(e, info.id)
                                      handleLeaderboardClick(e, isData.id);
                                    } else if (!ended) {
                                      console.log("CHECK upcoming");
                                      handleChangeOngoing(e, isData.id);
                                    }
                                  }}
                                  isCommunity={
                                    innerTab === "Community" ? true : false
                                  }
                                  Submitbutton={accountCreate}
                                  Participated={participated}
                                  key={index}
                                />
                              </>
                            );
                          }
                        });
                      }
                    })}
                  </Grid>
                </div>
              )}
              <div className="DetailsBoxComp">
                {Show && (
                  <CompDetForm
                    onSubmitChange={handleSubmitForm}
                    CompName={CompName}
                  />
                )}

                {ShowSummary && (
                  <div>
                    <Grid className="Container-p-20">
                      <Title six>Summary</Title>
                      <Grid className="SummaryList mtt-20">
                        <Title three>Account Size</Title>
                        <Title four>{Data && Data.account_size}</Title>
                      </Grid>
                      <Grid className="SummaryList">
                        <Title three>Competition</Title>
                        <Title four>{Data && Data.challenge}</Title>
                      </Grid>
                      <Grid className="SummaryList">
                        <Title three>Account ID</Title>
                        <Title four>
                          <CopyFormatter data={Data && Data.login_id} />{" "}
                        </Title>
                      </Grid>
                      {/* <Grid className="SummaryList">
                        <Title three>Server Type</Title>
                        <Title four>{Data && Data.type}</Title>
                      </Grid> */}
                      <Grid className="SummaryList">
                        <Title three>Server Name</Title>
                        <Title four>{Data && Data.server && Data.server}</Title>
                      </Grid>
                      <Grid className="SummaryList">
                        <Title three>Broker</Title>
                        <Title four>{Data && Data.broker && Data.broker}</Title>
                      </Grid>
                      {/* <Grid className="SummaryList">
                        <Title three>Investor Password</Title>
                        <Title four>
                          <CopyFormatter
                            data={Data && Data.investor_password}
                          />
                        </Title>
                      </Grid> */}
                      <Grid className="SummaryList">
                        <Title three>Master Password</Title>
                        <Title four>
                          <CopyFormatter data={Data && Data.password} />
                        </Title>
                      </Grid>
                      <Grid className="SummaryList">
                        <Title three>Leverage</Title>
                        <Title four>{Data && Data.leverage}</Title>
                      </Grid>
                      <svg
                        className="seperatorSVG"
                        width="364"
                        height="1"
                        viewBox="0 0 364 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.5"
                          y1="0.5"
                          x2="363.5"
                          y2="0.5"
                          stroke="url(#paint0_radial_783_9914)"
                          strokeLinecap="round"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_783_9914"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(189.105 1) rotate(180) scale(189.105 24238.7)"
                          >
                            <stop offset="0.42336" stopColor="white" />
                            <stop
                              offset="1"
                              stopColor="white"
                              stopOpacity="0"
                            />
                          </radialGradient>
                        </defs>
                      </svg>
                    </Grid>
                  </div>
                )}

                {ShowRules && (
                  <>
                    <Grid md={4} lg={4} xs={12} sx={{padding: "0 2rem"}}>
                      <Title six>Competition Rules</Title>
                      <div className="mtt-10">
                        <div className="d-flex gapp-10 align-items-center mbb-20">
                          <div className="RulesDetail">
                            <div
                              style={{color: "white"}}
                              dangerouslySetInnerHTML={{__html: Rules}}
                            ></div>
                            <Title six>Competition Awards</Title>
                            <ul className="comp-awards-list">
                              {Awards.map((award) => (
                                <li>
                                  Rank {award.rank}: {award.title}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid md={4} lg={4} xs={12}>
                      <Title six>Competition Awards</Title>
                      <div className="mtt-10">
                        <div className="d-flex gapp-10 align-items-center mbb-20">
                          <div className="RulesDetail">
                          
                          </div>
                        </div>
                      </div>
                    </Grid> */}
                  </>
                )}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      )}

      {showLeaderboard && (
        <div>
          <Leaderboard Participated="$100k" />
        </div>
      )}
    </>
  );
};

export default LeaderboardList;
