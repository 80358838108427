export const BANNED_COUNTRY_NAMES = [
  "afghanistan",
  "belarus",
  "burundi",
  "central african republic",
  "region of crimea",
  "eritrea",
  "iran",
  "iran (islamic republic of)",
  "iraq",
  // "usa",
  //   "united states of america",
  // "us",
  "libya",
  "myanmar",
  "somalia",
  "sudan",
  "syria",
  "syrian arab republic",
  "yemen",
  "venezuela",
  "venezuela (bolivarian republic of)",
  "vietnam",
];

export const BANNED_COUNTRY_CODES = [
  "AF",
  "BY",
  "BI",
  "CF",
  "ER",
  "IR",
  "IQ",
  // "US",
  "LY",
  "MM",
  "SD",
  "SO",
  "SY",
  "YE",
  "VN",
  "VE",
];
