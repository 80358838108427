import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FundingEvaluation from "../../pages/Payment/FundingEvaluation.js";
import PaymentHistory from "../../pages/Payment/PaymentHistory.jsx";
import {
  LoginForm,
  ForgotPassword,
  EnterOTP,
  SignUpForm,
  SuccessPopup,
  ResetPassword,
  PageLayout,
  UserRoute,
  CompetitionRule,
  ChallengesBoxM,
  AccountMetric,
  // FundingEvaluation,
  FundingEvaluationPayment,
  // SupportCenter,
  Profile,
  CompetitionList,
  LeaderboardParticipated,
  AccountAnalysis,
  // PaymentHistory,
  Achievements,
  LeaderboardMobile,
  CompetitionOverview,
  CompetitionOverviewV2,
  Trademate,
  AffiliateMarketing,
  GettingStarted,
  NewDashboard,
  UnAuthCheckout,
  Checkout,
  PaymentStatus,
  GetDocuSignSigningURL,
  DocuSignSigningCompleted,
  TradingPlatform,
  PageNotFound,
  NewWPCheckout,
  // Test
} from "../../pages";

import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import Sumsub from "../../pages/profile/Sumsub";
import LeaderboardList from "../../pages/LeaderboardList";
import GoogleLogin from "../../pages/GoogleLogin";

import SignInNew from "../../pages/Auth/Signin/index.jsx";
import ForgotPasswordNew from "../../pages/Auth/ForgotPassword/index.jsx";
import ResetPasswordNew from "../../pages/Auth/ResetPassword/index.jsx";
import PasswordOtpNew from "../../pages/Auth/PasswordOTP/index.jsx";
import SignUpFormNew from "../../pages/Auth/Signup/index.jsx";
import NewCheckout from "../../pages/Checkout/NewCheckout.jsx";
import NewUnAuthCheckout from "../../pages/UnAuthCheckout/IndexTwo.js";
import VerifyEmail from "../../pages/profile/VerifyEmail.jsx";
import UpdatedCheckoutPage from "../../pages/UpdatedCheckoutPage/UpdatedCheckoutPage.jsx";
import CompFunding from "../Competition/CompFunding.jsx";
import SigninForm from "../Auth/SigninForm/index.jsx";
import Signin from "../../pages/Auth/Signin/index.jsx";
// import GoogleLogin from './../../pages/GoogleLogin';

const fallbackStyle = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function MainRouter() {
  return (
    <div className="MainRouter">
      <Suspense
        fallback={
          <div style={fallbackStyle}>
            <CircularProgress
              sx={{
                "--CircularProgress-size": "40px",
                "--CircularProgress-track-thickness": "10px",
                "--CircularProgress-progress-thickness": "2px",
              }}
            />
          </div>
        }
      >
        <BrowserRouter>
          <React.Fragment>
            <Routes>
              {/* <Route exact path="/" element={<LoginForm />} /> */}
              {/* <Route exact path="/login" element={<LoginForm />} /> */}
              <Route exact path="/" element={<SignInNew />} />
              <Route exact path="/login" element={<SignInNew />} />
              {/* <Route path="/SignInNew" element={ <SignInNew />} /> */}
              {/* <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
              <Route path="/forgot-password" element={<SignInNew />} />
              {/* <Route path="/ResetPassword" element={<ResetPassword />} /> */}
              <Route path="/ResetPassword" element={<ResetPasswordNew />} />
              {/* <Route path="/enter-otp" element={<EnterOTP />} /> */}
              <Route path="/enter-otp" element={<PasswordOtpNew />} />
              <Route path="/success" element={<SuccessPopup />} />
              <Route path="/auth/google" element={<GoogleLogin />} />
              {/* USER Protected Routes START*/}
              <Route path="/signup/:referalCode" element={<SignInNew />} />
              <Route path="/signup" element={<SignInNew />} />
              {/* <Route path="/SignUpFormNew" element={<SignUpFormNew />} /> */}
              {/* <Route
                path="/funding-evaluation/checkout/:id"
                // element={<NewUnAuthCheckout />}
                // element={<NewCheckout isPublic={true}/>}
                element={<UnAuthCheckout />}
              /> */}
              <Route
                path="/funding-evaluation/checkout/:id"
                // element={<NewUnAuthCheckout />}
                // element={<NewCheckout isPublic={true}/>}
                element={<UnAuthCheckout anonymous isPublic={true} />}
              />

              <Route element={<UserRoute />}>
                {/* <Route path="/test" element={<PageLayout headerName="Test"><Test /></PageLayout>}/> */}
                <Route
                  path="/getting-started"
                  element={
                    <PageLayout headerName="Get started">
                      <GettingStarted />
                    </PageLayout>
                  }
                />

                <Route
                  path="/achievements"
                  element={
                    <PageLayout headerName="Achievements">
                      <Achievements />
                    </PageLayout>
                  }
                />
                <Route
                  path="/trading-platform"
                  element={
                    <PageLayout headerName="Trading Platform">
                      <TradingPlatform />
                    </PageLayout>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <PageLayout headerName="Dashboard">
                      <NewDashboard />
                    </PageLayout>
                  }
                />
                <Route
                  path="/AccountMetric"
                  element={
                    <PageLayout headerName="Account Metric">
                      <AccountMetric />
                    </PageLayout>
                  }
                />
                <Route
                  path="/FundingEvaluation"
                  element={
                    <PageLayout headerName="Funding Evaluation">
                      <FundingEvaluation />
                    </PageLayout>
                  }
                />
                {/* <Route
                  path="/FundingEvaluation"
                  element={
                    <PageLayout headerName="Funding Evaluation">
                      <FundingEvaluation />
                    </PageLayout>
                  }
                />
                {/* 
                <Route
                  path="/FundingEvaluationPayment_old/:id"
                  element={
                    <PageLayout headerName="Funding Evaluation">
                      <FundingEvaluationPayment />
                    </PageLayout>
                  }
                /> */}
                {/* <Route
                  path="/SupportCenter"
                  element={
                    <PageLayout headerName="Support Center">
                      <SupportCenter />
                    </PageLayout>
                  }
                /> */}
                <Route
                  path="/profile"
                  element={
                    <PageLayout headerName="My Profile">
                      <Profile />
                    </PageLayout>
                  }
                />
                <Route
                  path="/verify-email/*"
                  element={
                    <PageLayout headerName="Email Verification">
                      <VerifyEmail />
                    </PageLayout>
                  }
                />
                <Route
                  path="/competitionlist"
                  element={
                    <PageLayout headerName="Competition List">
                      <CompetitionList />
                    </PageLayout>
                  }
                />
                <Route
                  path="/competitionlist/competitionCheckout"
                  element={
                    <PageLayout headerName="Competition">
                      <CompFunding />
                    </PageLayout>
                  }
                />
                <Route
                  path="/competitionlist/:id"
                  element={
                    <PageLayout headerName="Competition List">
                      <CompetitionList />
                    </PageLayout>
                  }
                />
                <Route
                  path="/leaderboardlist"
                  element={
                    <PageLayout headerName="Leaderboard List">
                      <LeaderboardList />
                    </PageLayout>
                  }
                />
                <Route
                  path="/affiliateMarketing"
                  element={
                    <PageLayout headerName="Affiliate Marketing">
                      <AffiliateMarketing />
                    </PageLayout>
                  }
                />
                <Route
                  path="/affiliateMarketing?tab=2"
                  element={
                    <PageLayout headerName="Affiliate Marketing">
                      <AffiliateMarketing />
                    </PageLayout>
                  }
                />

                <Route
                  path="/CompetitionRule"
                  element={
                    <PageLayout headerName="Leaderboard">
                      <CompetitionRule />
                    </PageLayout>
                  }
                />
                <Route
                  path="/LeaderboardParticipated"
                  element={
                    <PageLayout headerName="Leaderboard">
                      <LeaderboardParticipated />
                    </PageLayout>
                  }
                />
                {/* <Route
                  path="/AccountAnalysis"
                  element={
                    <PageLayout headerName="Account Analysis">
                      <AccountAnalysis />
                    </PageLayout>
                  }
                /> */}
                <Route
                  path="/PaymentHistory"
                  element={
                    <PageLayout headerName="Payment History">
                      <PaymentHistory />
                    </PageLayout>
                  }
                />
                <Route
                  path="/LeaderboardM"
                  element={
                    <PageLayout headerName="Leaderboard">
                      <LeaderboardMobile />
                    </PageLayout>
                  }
                />
                <Route
                  path="/challengesBoxM"
                  element={
                    <PageLayout headerName="Leaderboar">
                      <ChallengesBoxM />
                    </PageLayout>
                  }
                />
                <Route
                  path="/challengesBoxM"
                  element={
                    <PageLayout headerName="Leaderboar">
                      <ChallengesBoxM />
                    </PageLayout>
                  }
                />
                <Route
                  path="/challengesBoxM"
                  element={
                    <PageLayout headerName="Leaderboard">
                      <ChallengesBoxM />
                    </PageLayout>
                  }
                />
                <Route
                  path="/CompetitionOverview"
                  element={
                    <PageLayout headerName="Competition Overview">
                      <CompetitionOverviewV2 />
                    </PageLayout>
                  }
                />
                <Route
                  path="/CompetitionOverview/:id"
                  element={
                    <PageLayout headerName="Competition Overview">
                      <CompetitionOverviewV2 />
                    </PageLayout>
                  }
                />
                <Route
                  path="/Leaderboard"
                  element={
                    <PageLayout headerName="Competition Overview">
                      <CompetitionOverview />
                    </PageLayout>
                  }
                />
                <Route
                  path="/Trademate"
                  element={
                    <PageLayout headerName="Trademate">
                      <Trademate />
                    </PageLayout>
                  }
                />

                <Route
                  path="/sumsub"
                  element={
                    <PageLayout headerName="Sumsub">
                      <Sumsub />
                    </PageLayout>
                  }
                />
                <Route
                  path="/docusign/get_signing_url/"
                  element={
                    <PageLayout headerName="DigiSigner">
                      <GetDocuSignSigningURL />
                    </PageLayout>
                  }
                />
                <Route
                  path="/doc/signing_completed/"
                  element={
                    <PageLayout headerName="DigiSigner">
                      <DocuSignSigningCompleted />
                    </PageLayout>
                  }
                />
              </Route>
              {/* <Route
                path="/FundingEvaluationPayment/:id"
                // element={<Checkout />}
                element={<NewCheckout isPublic={false} />}
              /> */}
              <Route
                path="/FundingEvaluationPayment/:id"
                // element={<Checkout />}
                element={<UpdatedCheckoutPage isPublic={false} />}
              />
              <Route
                path="/funding-evaluation/checkout/:id"
                element={<UnAuthCheckout anonymous isPublic={true} />}
              />
              <Route
                path="/funding-evaluation/checkout/wp/:id"
                element={<NewWPCheckout anonymous isPublic={true} />}
              />
              <Route
                path="/payment/checkout/:id/:code"
                element={<NewCheckout removeCoupon isPublic={false} />}
              />
              <Route
                path="/payment/checkout/:id"
                element={<NewCheckout removeCoupon isPublic={false} />}
              />
              <Route
                path="/evaluation/payment/success/"
                element={
                  <PageLayout headerName="Payment Success">
                    <PaymentStatus success />
                  </PageLayout>
                }
              />
              <Route
                path="/evaluation/payment/failure/"
                element={
                  <PageLayout headerName="Payment Failed">
                    <PaymentStatus failed />
                  </PageLayout>
                }
              />
              <Route
                path="/evaluation/payment/cancelled/"
                element={
                  <PageLayout headerName="Payment Cancelled">
                    <PaymentStatus cancelled />
                  </PageLayout>
                }
              />
              <Route path="*" element={<PageNotFound />} />

              {/* USER Protected Routes END */}
            </Routes>
          </React.Fragment>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}
