import React from "react";
import { ButtonBase, styled } from "@mui/material";

const StyledFilledButton = styled((props) => <ButtonBase {...props}  />)(
  ({ theme }) => ({
    background: "#04D9FF",
    borderRadius: "30px",
    border: "none",
    color: "white",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "10px",
    width: "100%",
  })
);

export default StyledFilledButton;
