import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import CompanyLogo from "../../../assets/images/company-logo.png";
import UserIcon from "../../../assets/images/SignupImg/UserIcon.svg";
import PhoneIcon from "../../../assets/images/SignupImg/PhoneIcon.svg";
import WebIcon from "../../../assets/images/SignupImg/CountryIcon.svg";
import EmailIcon from "../../../assets/images/SignupImg/Email.svg";
import LockIcon from "../../../assets/images/SignupImg/Password.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import GoogleIcon from "../../../assets/images/SignupImg/Google.svg";

import { Link, useNavigate } from "react-router-dom";
import {
  validateContact,
  validateEmail,
  validateLetters,
  validatePassword,
} from "../../../utilities/string";
import Tooltip from "../../../reusableComponents/Tooltip";
import { country } from "../../../utilities/country";

import { useDispatch } from "react-redux";
import { axiosInstance } from "../../../Axios";

import CombinedPopup from "../../../components/Popups/CombinedPopup";

import { AcctokenD, login, logout, RefftokenD } from "../../../store/actions";

import { getUserProfile } from "../../../store/reducers/userProfileReducer";
import Spinner from "../../../reusableComponents/Spinner";
import { setExpiry } from "../../../store/reducers/tokenReducer";
import { BANNED_COUNTRY_CODES } from "../../../constants/bannedCountries";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorModal from "../../../reusableComponents/ErrorModal";

function SignupForm({
  referalCode,
  setPopUpType,
  setShowPopup,
  setCombinedPUHead,
  setCombinedPUBody,
}) {
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const nameRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const countryRef = useRef(null);
  const contactRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [countryCode, setCountryCode] = useState("44");
  const [isCountrySelected, setIsCountrySelected] = useState("United Kingdom");

  const countryOptions = country;
  const [options, setOptions] = useState(countryOptions);

  const [formData, setFormData] = useState(new FormData());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState({
    first_name: "",
    last_name: "",
    country: "United Kingdom",
    contact: "",
    email: "",
    password: "",
    country_code: "44",
    referal_code: "",
  });
  const [inputError, setInputError] = useState({
    first_name: "",
    last_name: "",
    // name: "",
    country: "",
    contact: "",
    email: "",
    password: "",
  });
  const [isInputError, setIsInputError] = useState({
    first_name: false,
    last_name: false,
    // name: false,
    country: false,
    contact: false,
    email: false,
    password: false,
  });

  useEffect(() => {
    setInputValue((prev) => ({ ...prev, referal_code: referalCode }));
  }, [referalCode]);

  // const handleInputChange = (e) => {
  //   const { name, id, value } = e.target;
  //   id === "country" &&
  //     setOptions(
  //       countryOptions.filter((item) =>
  //         item.country.toLowerCase().includes(value.toLowerCase())
  //       )
  //     );
  //   setInputValue((prev) => ({ ...prev, [name]: value }));
  //   formData.set(name, value);
  // };

  const [errors, setErrors] = useState({});

  console.log("errors", errors.isValidCountry);

  const handleInputChange = (e) => {
    const { name, id, value } = e.target;

    if (id === "country") {
      const filteredOptions = countryOptions.filter((item) =>
        item.country.toLowerCase().includes(value.toLowerCase())
      );
      setOptions(filteredOptions);

      // Check for exact match with a valid country
      const exactMatch = countryOptions.find(
        (item) => item.country.toLowerCase() === value.toLowerCase()
      );

      if (exactMatch) {
        setCountryCode(exactMatch.code); // Set the country code if exact match
        setIsCountrySelected(exactMatch.country); // Set active country
        setErrors((prev) => ({
          ...prev,
          country: "",
          isInvalidCountry: false,
        })); // Clear errors
      } else {
        setCountryCode(""); // Clear country code if no exact match
        setIsCountrySelected(""); // Clear active country
        setErrors((prev) => ({
          ...prev,
          country: "Not a valid country",
          isInvalidCountry: true,
        }));
      }
    }

    // Update input values
    setInputValue((prev) => ({ ...prev, [name]: value }));
    formData.set(name, value);
  };

  const handleDropdownChange = (value) => {
    console.log(value, "test");
    const selectedOption = options.find((option) => option === value);
    if (selectedOption) {
      setInputValue((prev) => ({
        ...prev,
        country: value.country,
        country_code: value.code,
      }));
      setIsCountrySelected(value.country);
      setCountryCode(value.code);
      setIsDropdown(false);
      setIsInputError((prev) => ({ ...prev, country: false }));
      setErrors((prev) => ({
        ...prev,
        country: "",
        isInvalidCountry: false,
      }));
    }
  };
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const onChange = (token) => {
    setRecaptchaToken(token);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { first_name, last_name, country, contact, email, password } =
      inputValue;

    if (first_name.trim() === "") {
      firstNameRef.current.classList.add("input-error");
      setInputError((prev) => ({
        ...prev,
        first_name: "Please enter the name!",
      }));
      setIsInputError((prev) => ({ ...prev, first_name: true }));
    }
    if (last_name.trim() === "") {
      lastNameRef.current.classList.add("input-error");
      setInputError((prev) => ({
        ...prev,
        last_name: "Please enter the name!",
      }));
      setIsInputError((prev) => ({ ...prev, last_name: true }));
    }

    if (country.trim().toUpperCase() === "") {
      countryRef.current.classList.add("input-error");
      setInputError((prev) => ({
        ...prev,
        country: "Please enter the Valid Country!",
      }));
      setIsInputError((prev) => ({ ...prev, country: true }));
    }

    console.log("errors?.isValidCountry", errors?.isInvalidCountry);

    if (errors?.isInvalidCountry === true) {
      countryRef.current.classList.add("input-error");
      setInputError((prev) => ({
        ...prev,
        country: "Please enter the Valid Country!",
      }));
      setIsInputError((prev) => ({ ...prev, country: true }));
    }

    // if (!options.some((item) => item.country === country.toUpperCase())) {
    //   countryRef.current.classList.add("input-error");
    //   setInputError((prev) => ({
    //     ...prev,
    //     country: "Please select the country!",
    //   }));
    //   setIsInputError((prev) => ({ ...prev, country: true }));
    // }

    if (contact === "") {
      contactRef.current.classList.add("input-error");
      setInputError((prev) => ({
        ...prev,
        contact: "Please enter the phone number!",
      }));
      setIsInputError((prev) => ({ ...prev, contact: true }));
    }
    if (email === "") {
      setInputError((prev) => ({
        ...prev,
        email: "Please enter the email!",
      }));
    } else if (!validateEmail(email)) {
      setInputError((prev) => ({
        ...prev,
        email: "The email entered is wrong!",
      }));
    }
    if (password === "") {
      setInputError((prev) => ({
        ...prev,
        password: "Please enter the password!",
      }));
    } else if (!validatePassword(password)) {
      setInputError((prev) => ({
        ...prev,
        password: "Password must be atleast 8 characters!",
      }));
    }

    if (email === "" || !validateEmail(email)) {
      emailRef.current.classList.add("input-error");
      setIsInputError((prev) => ({ ...prev, email: true }));
    }
    if (password === "" || !validatePassword(password)) {
      passwordRef.current.classList.add("input-error");
      setIsInputError((prev) => ({ ...prev, password: true }));
    }
    if (
      !contact.trim() ||
      !email.trim() ||
      !password.trim() ||
      !first_name.trim() ||
      !last_name.trim() ||
      !country.trim() ||
      errors?.isInvalidCountry
    ) {
      console.error("All fields must be filled!", contact, email, password);
      setLoading(false);
      setPopUpType("error");
      setShowPopup(true);
      setCombinedPUHead("Oh snap!");
      setCombinedPUBody("All fields are required");
      return;
    }

    setLoading(true);
    formData.set("country", inputValue.country);
    formData.set("country_code", inputValue.country_code);
    formData.set(
      "full_name",
      `${inputValue.first_name + " " + inputValue.last_name}  `
    );
    formData.set("phone", inputValue.contact);
    referalCode && formData.set("referal_code", referalCode);
    // if (!recaptchaToken) {
    //   setLoading(false);
    //   // alert("Please complete the reCAPTCHA");
    //   setPopUpType("error");
    //   setShowPopup(true);
    //   setCombinedPUHead("Oh snap!");
    //   setCombinedPUBody("Please complete the reCAPTCHA ");
    //   return;
    // }
    axiosInstance
      .post("email/signup/", formData)
      .then((res) => {
        console.log("bhav", res);

        if (res.data.error) {
          setLoading(false);
          setPopUpType("error");
          setShowPopup(true);
          setCombinedPUHead("Oh snap!");
          setCombinedPUBody("Failed to Signup");
          dispatch(AcctokenD(0));
          dispatch(RefftokenD(0));
          dispatch(logout());

          return;
        }
        setLoading(false);

        dispatch(AcctokenD(res.data.firebase_cred.idToken));
        dispatch(RefftokenD(res.data.firebase_cred.refreshToken));
        const expiresInSeconds = 86400;
        const expiresAtDate = new Date();
        expiresAtDate.setSeconds(expiresInSeconds);
        dispatch(setExpiry(expiresAtDate));
        dispatch(login());
        dispatch(getUserProfile()).then((data) => {
          navigate("/FundingEvaluationPayment/4");
          if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length === 0
          ) {
            navigate("/FundingEvaluationPayment/4");
            setLoading(false);
          } else if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length > 0
          ) {
            navigate("/AccountMetric");
            setLoading(false);
          }
        });
        navigate("/FundingEvaluationPayment/4");
      })

      .catch(function (error) {
        setLoading(false);
        setErrorModal(true);
        setErrorMessage(error.response.data.message || "An error Occured");
        console.log("error.response", error.response);

        console.log("ok");
        setPopUpType("error");
        setShowPopup(true);
        setCombinedPUHead("Oh snap!");
        setCombinedPUBody(error.response.data.message);
        // }
        dispatch(AcctokenD(0));
        dispatch(RefftokenD(0));
        dispatch(logout());
      });

    // try {
    //   const response = await signupRequest(formData);
    //   if (!response.status === 200 || 201) {
    //     setErrorModal(true);
    //   }
    // } catch (err) {
    //   console.log(err, "ERROR2");
    //   setErrorModal(true);
    //   setErrorMessage("An error Occured");
    // }
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    // id === "name" && nameRef.current.classList.remove("input-error");
    id === "first_name" && firstNameRef.current.classList.remove("input-error");
    id === "last_name" && lastNameRef.current.classList.remove("input-error");
    id === "contact" && contactRef.current.classList.remove("input-error");
    id === "country" && countryRef.current.classList.remove("input-error");
    id === "email" && emailRef.current.classList.remove("input-error");
    id === "password" && passwordRef.current.classList.remove("input-error");
  };

  const handleFocus = (e) => {
    const { id } = e.target;
    setIsInputError((prev) => ({ ...prev, [id]: false }));
  };

  const handleKeyDown = (e) => {
    const { id } = e.target;
    if (id === "name" && !validateLetters(e.key)) {
      e.preventDefault();
    }

    if (id === "contact" && !validateContact(e.key)) {
      e.preventDefault();
    }
  };

  document.addEventListener("click", () => {
    const dropdown = document.getElementById("signupform-dropdown");
    if (!dropdown) {
      setIsDropdown(false);
    }
  });

  useEffect(() => {
    const handleKeyUp = (e) => {
      const { value } = e.target;
      if (validateContact(value)) {
        setInputValue((prev) => ({ ...prev, contact: value }));
      }
    };

    const contactInputValue = document.getElementById("contact");
    contactInputValue.addEventListener("keyup", handleKeyUp);

    return () => {
      contactInputValue.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  window.addEventListener("click", (e) => {
    setIsDropdown(false);
  });

  const handleGoogleAuth = async (e) => {
    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&state=pass-through value&redirect_uri=https://app.alphacapitalgroup.uk/auth/google&client_id=402277231112-imkppquf33eo2ap9btus3lr7m74okdvh.apps.googleusercontent.com";
  };

  return (
    <div className="signupform-container">
      {errorModal && (
        <ErrorModal
          desc={errorMessage}
          setModalOpen={setErrorModal}
          btnText="Cancel"
        />
      )}

      <div className="signupform-subcontainer">
        <div className="signupform-top-banner">
          {/* <img src={CompanyLogo} alt="" /> */}
          <h2 className="signupform-title">Sign Up</h2>
          <p className="signupform-subtitle">
            Start your new journey! Please enter your Details
          </p>
        </div>
        {/* <div className="signupform-innercontainer">
          <div className="signupform-container_toggle">
            <Link to="/" className="signupform-toggle">
              Sign In
            </Link>
            <div className="signupform-toggle">Sign Up</div>
          </div>
        </div> */}
        <form className="signupform-innercontainer signupform-container_form">
          <div className="signupform-subcontainer_form">
            <div
              className="signupform-firstname-container"
              style={{ marginBottom: "15px" }}
            >
              <div
                ref={firstNameRef}
                className="signupform-innercontainer_form"
              >
                <div className="signupform-container_icon">
                  <img src={UserIcon} alt="" className="signupform-icon" />
                </div>
                <label
                  className="signupform-label"
                  htmlFor="first_name"
                  style={{ bottom: "155px" }}
                >
                  First Name
                </label>
                <div className="signupform-container_input">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="First Name"
                    className="signupform-input"
                    value={inputValue.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                  />
                </div>
              </div>
            </div>

            <div className="signupform-lastname-container">
              <div ref={lastNameRef} className="signupform-innercontainer_form">
                <div className="signupform-container_icon">
                  <img src={UserIcon} alt="" className="signupform-icon" />
                </div>
                <label className="signupform-label" htmlFor="last_name">
                  Last Name
                </label>
                <div className="signupform-container_input">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="signupform-input"
                    value={inputValue.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
            {/* <Tooltip isActive={isInputError.name}>{inputError.name}</Tooltip> */}
          </div>

          <div className="signupform-subcontainer_form">
            <div className="signupform-contact-container">
              <div ref={contactRef} className="signupform-innercontainer_form">
                <div className="signupform-container_icon">
                  <img src={PhoneIcon} alt="" className="signupform-icon" />
                </div>
                <label className="signupform-label" htmlFor="contact">
                  Contact Number
                </label>
                <div className="signupform-container_input">
                  <div>
                    <span className="country_code">+{countryCode}</span>
                    <input
                      type="text"
                      name="contact"
                      id="contact"
                      className="signupform-input"
                      placeholder="Contact Number"
                      value={inputValue.contact}
                      onChange={(e) =>
                        e.target.value.length > 15 ? null : handleInputChange(e)
                      }
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              </div>
              {/* <Tooltip isActive={isInputError.contact}>
                {inputError.contact}
              </Tooltip> */}
            </div>
          </div>

          <div className="signupform-subcontainer_form">
            <div className="signupform-email-container">
              <div ref={emailRef} className="signupform-innercontainer_form">
                <div className="signupform-container_icon">
                  <img src={EmailIcon} alt="" className="signupform-icon" />
                </div>
                <label className="signupform-label" htmlFor="email">
                  Email
                </label>
                <div className="signupform-container_input">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autocomplete="off"
                    className="signupform-input"
                    value={inputValue.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Email Address"
                  />
                </div>

                <div className="signupform-container_icon">
                  {validateEmail(inputValue.email) && (
                    <img
                      src={CheckSolidGreenIcon}
                      alt=""
                      className="signupform-icon"
                    />
                  )}
                </div>
              </div>
              {/* <Tooltip isActive={isInputError.email}>
                {inputError.email}
              </Tooltip> */}
            </div>
          </div>

          <div className="signupform-container_dropdown">
            <div className="signupform-couuntry-container">
              <div
                className="signupform-subcontainer_form"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDropdown(true);
                }}
              >
                <div
                  className={`signupform-innercontainer_form ${
                    isDropdown ? "signupform-select" : ""
                  }`}
                  ref={countryRef}
                >
                  <div className="signupform-container_icon">
                    <img src={WebIcon} alt="" className="signupform-icon" />
                  </div>
                  <label className="signupform-label" htmlFor="email">
                    Country
                  </label>
                  <div className="signupform-container_input">
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="signupform-input"
                      value={inputValue.country}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                    />

                    {/* <p className="signupform-input">{inputValue.country}</p> */}
                  </div>

                  <div className="signupform-container_icon">
                    <img
                      src={CaretDownIcon}
                      alt=""
                      className="signupform-icon"
                    />
                  </div>
                </div>
                {errors.country && (
                  <span style={{ color: "red" }}>{errors.country}</span>
                )}
              </div>

              {/* <Tooltip isActive={isInputError.country}>
                {inputError.country}
              </Tooltip> */}
              {isDropdown && (
                <div
                  id="signupform-dropdown"
                  className="signupform-dropdown signupform-innercontainer_form"
                >
                  <ul>
                    {options.length !== 0 ? (
                      options.map((item, index) => {
                        if (BANNED_COUNTRY_CODES.includes(item.iso))
                          return false;
                        return (
                          <li
                            key={index}
                            className={`${
                              isCountrySelected === item.country
                                ? "signupform-dropdown_active"
                                : ""
                            }`}
                            onClick={() => handleDropdownChange(item)}
                          >
                            {item.country}
                          </li>
                        );
                      })
                    ) : (
                      <li className="list-no_country">No Country</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {inputValue.referal_code ? (
            <div className="signupform-subcontainer_form">
              <div className="signupform-contact-container">
                <div
                  ref={contactRef}
                  className="signupform-innercontainer_form"
                >
                  <div className="signupform-container_icon">
                    <img src={UserIcon} alt="" className="signupform-icon" />
                  </div>
                  <label className="signupform-label" htmlFor="referal_code">
                    Referral Code
                  </label>
                  <div className="signupform-container_input">
                    <div>
                      <input
                        type="text"
                        name="referal_code"
                        id="referal_code"
                        className="signupform-input"
                        value={inputValue.referal_code}
                        placeholder="Referral Code"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                </div>
                {/* <Tooltip isActive={isInputError.referal_code}>
                  {inputError.referal_code}
                </Tooltip> */}
              </div>
            </div>
          ) : (
            false
          )}

          <div className="signupform-subcontainer_form">
            <div className="signupform-password-container">
              <div ref={passwordRef} className="signupform-innercontainer_form">
                <div className="signupform-container_icon">
                  <img src={LockIcon} alt="" className="signupform-icon" />
                </div>
                <label className="signupform-label" htmlFor="password">
                  Password
                </label>
                <div className="signupform-container_input">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    id="password"
                    className="signupform-input"
                    value={inputValue.password}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Password"
                  />
                </div>

                <div className="signupform-container_icon">
                  <img
                    src={isPasswordVisible ? EyeIcon : EyeSlashedIcon}
                    alt=""
                    className="signupform-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                  />
                </div>
              </div>
              {/* <Tooltip isActive={isInputError.password}>
                {inputError.password}
              </Tooltip> */}
            </div>
          </div>

          {/* <p className="signupform-error">The password entered is wrong!</p> */}
        </form>

        <div className="signup_bottom">
          {/* <div className="captchContainer">
            <ReCAPTCHA
              sitekey="6LeJEjEqAAAAAIxK6hUEYE4aWskY7L-jTZ7e14NF"
              onChange={onChange}
            />
          </div> */}
          <div className="signupform-container_button">
            <button
              className="signupform-button"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  Sign Up
                  {/* <img
                    src={ArrowRightIcon}
                    alt=""
                    className="signinform-icon"
                  /> */}
                </>
              )}{" "}
              {/* <img src={ArrowRightIcon} alt="" className="signupform-icon" /> */}
            </button>
          </div>
          <div
            className="signup_with_google"
            onClick={handleGoogleAuth}
            style={{ cursor: "pointer" }}
          >
            <p className="signinform-continue">Or sign up using</p>
            <div className="signinform-innercontainer signinform-container_icon">
              <div className="signinform-subcontainer_icon">
                <img
                  style={{ cursor: "pointer" }}
                  src={GoogleIcon}
                  alt="google logo"
                />
              </div>
              {/* <div className="signinform-subcontainer_icon">
            <img
              src={DiscordIcon}
              alt=""
              className="signinform-icon"
            />
          </div> */}
              {/* <div className="signinform-subcontainer_icon">
            <img src={FacebookIcon} alt="" className="signinform-icon" />
            <div>
              <p>Facebook</p>
            </div>
          </div> */}
            </div>
            {/* <p className="signinform-link">
              Don&#39;t have an account?{" "}
              <Link to="/signup">
                <b className="signinform-link_bold">Sign Up</b>
              </Link>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
