import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  one: {
    fontSize: "48px", //Form head name//
    fontWeight: "700",
    lineHeight: "67px",
    fontFamily: "Outfit, sans-serif",
    textAlign: "left",
    color: "#ffffff",
  },
  two: {
    fontSize: "36px !important", //Page name//
    fontWeight: "700 !important",
    lineHeight: "50px !important",
    fontFamily: "Outfit, sans-serif !important",
    color: "white",
  },
  three: {
    fontSize: "14px !important", //form label//
    fontWeight: "400 !important",
    lineHeight: "19px",
    fontFamily: "Outfit, sans-serif !important",
    color: "#8B8E93",
    textAlign: "left !important",
  },
  four: {
    fontSize: "14px !important", //forgot password link//
    fontWeight: "700 !important",
    fontFamily: "Outfit, sans-serif !important",
    color: "white",
    textAlign: "left !important",
  },
  five: {
    fontSize: "18px", //popup desc//
    fontWeight: "300",
    lineHeight: "24px",
    fontFamily: "Outfit, sans-serif",
    color: "white",
    textAlign: "left !important",
  },
  // six: {
  //   fontSize: "18px !important", //section names//
  //   fontWeight: "bold !important",
  //   fontFamily: "Outfit, sans-serif !important",
  //   color: "white",
  //   textAlign: "left",
  // },
  seven: {
    fontSize: "14px !important", //plain text//
    fontWeight: "400 !important",
    fontFamily: "Outfit, sans-serif !important",
    color: "white",
    textAlign: "left",
  },
}));

const Title = ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  StyleName,
  children,
  Styles,
}) => {
  const classes = useStyles();
  return (
    <div className={StyleName} style={Styles}>
      {children && (
        <Typography
          className={
            one
              ? classes.one
              : two
              ? classes.two
              : three
              ? classes.three
              : four
              ? classes.four
              : five
              ? classes.five
              : six
              ? classes.six
              : seven
              ? classes.seven
              : classes.TextClass
          }
          variant="h6"
        >
          {children}
        </Typography>
      )}
    </div>
  );
};

export default Title;
