import axios from "axios";
import { axiosInstance } from "../../Axios";
import logoutReducer from "./logout";
import { tokenConfig } from "./tokenReducer";

export const saveChallenges = (data) => {
  return {
    type: "SAVE_CHALLENGES",
    payload: data,
  };
};

export const savePaymentIntent = (data) => {
  return {
    type: "SAVE_PAYMENT_INTENT",
    payload: data,
  };
};

export const removePaymentIntent = () => {
  return {
    type: "REMOVE_PAYMENT_INTENT",
  };
};

export const removePayment = () => {
  return { type: "REMOVE_PAYMENT" };
};

export const updatePaymentStatus = (status) => {
  return {
    type: "UPDATE_PAYMENT_STATUS",
    payload: status,
  };
};

export const paymentHistory = (data) => {
  return {
    type: "PAYMENT_HISTORY",
    payload: data,
  };
};

export const addChallenge = (data) => {
  return {
    type: "ADD_CHALLENGE",
    payload: data,
  };
};

export const clearChallenge = (data) => {
  return {
    type: "CLEAR_CHALLENGE",
    payload: data,
  };
};

const initialState = {
  paymenthistoryList: [],
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_CHALLENGES":
      return {
        ...state,
        challenges: action.payload,
      };
    case "SAVE_PAYMENT_INTENT":
      return {
        ...state,
        payment_intent: action.payload,
        payment_status: null,
        challenge_id: null,
      };
    case "ADD_CHALLENGE":
      return {
        ...state,
        challenge_id: action.payload["challenge"],
        account_details: action.payload,
      };
    case "REMOVE_PAYMENT_INTENT":
      return {
        ...state,
        payment_intent: "",
        payment_status: null,
        challenge_id: null,
        account_details: null,
      };
    case "PAYMENT_HISTORY":
      return {
        ...state,
        paymenthistoryList: action.payload,
      };
    case "UPDATE_PAYMENT_STATUS":
      return {
        ...state,
        payment_status: action.payload,
      };
    case "REMOVE_PAYMENT":
      return {};
    default:
      return state;
  }
};

export const getChallenges = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get(
      "v2/challenges/",
      tokenConfig(getState)
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(saveChallenges(data));
  } catch (error) {
    // dispatch(toggleLoading(false));
    // dispatch(saveError(error));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const stripePaymentInit =
  (payload, account_payload) => async (dispatch, getState) => {
    try {
      const idToken = await getState().ACCtoken;
      payload["idToken"] = idToken;
      const { data } = await axiosInstance.post(
        "/v2/transaction/checkout/",
        payload
      );
      if (data === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }

      dispatch(savePaymentIntent(data.data.payment_id));
      dispatch(addChallenge(account_payload));
      window.location.href = data.url;
    } catch (error) {
      // dispatch(toggleLoading(false));
      // dispatch(saveError(error));
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
    }
  };

export const addPaymentIntent =
  (payment_intent) => async (dispatch, getState) => {
    dispatch(savePaymentIntent(payment_intent));
  };

export const deletePaymentIntent = () => async (dispatch, getState) => {
  dispatch(removePaymentIntent());
};

export const getPaymentHistory = () => async (dispatch, getState) => {
  try {
    // const idToken = await getState().ACCtoken
    // dispatch(toggleLoading(true));
    const { data } = await axiosInstance.get(
      "/v2/transaction/history/",
      tokenConfig(getState)
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(paymentHistory(data));
    // dispatch(toggleLoading(false));
  } catch (error) {
    // dispatch(toggleLoading(false));
    // dispatch(saveError(error))
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const applyCouponCode =
  (challenge_id, coupon = null, tenPercentProfit = 0) =>
  async (dispatch, getState) => {
    try {
      let url = `v2/get/final-price/${challenge_id}/`;
      if (coupon) {
        url =
          url +
          `?coupon=${coupon}&ten_percent_profit_target=${
            (tenPercentProfit && 1) || 0
          }`;
      } else {
        url =
          url + `?ten_percent_profit_target=${(tenPercentProfit && 1) || 0}`;
      }
      const { data, status } = await axiosInstance.get(
        url,
        tokenConfig(getState)
      );
      if (status > 399) throw data;
      return data;
    } catch (error) {
      console.log(error.response.data);
      if (
        error.response.data.detail ===
        "Authentication credentials were not provided."
      ) {
        dispatch(logoutReducer());
        return;
      }
      throw error.response.data;
    }
  };

export const stripePaymentInitV4 =
  (payload, account_payload) => async (dispatch, getState) => {
    try {
      // const idToken = await getState().ACCtoken;
      // payload["idToken"] = idToken;
      // payload["refreshToken"] = refreshToken;
      console.log(payload);

      const formData = new FormData();
      const refreshToken = await getState().REFFtoken;
      formData.append("payment_method", payload["payment_method"]);
      formData.append("challenge_id", payload["challenge_id"]);
      payload["coupon"] && formData.append("coupon", payload["coupon"]);
      formData.append("email", payload["email"]);
      payload["EAs"] && formData.append("EAs", payload["EAs"]);
      payload["raw_spread"] &&
        formData.append("raw_spread", payload["raw_spread"]);
      payload["platform"] && formData.append("platform", payload["platform"]);
      formData.append("firstName", payload["firstName"]);
      formData.append("lastName", payload["lastName"]);
      formData.append("contact", payload["contact"]);
      formData.append("refresh_token", refreshToken);
      formData.append("cryptocurrency", payload["cryptocurrency"]);
      formData.append(
        "ten_percent_profit_target",
        payload["ten_percent_profit_target"]
      );
      payload["country_code"] &&
        formData.append("country_code", payload.country_code);

      const { data } = await axiosInstance.post(
        "v2/get/checkout/payment-url/",
        formData,
        tokenConfig(getState)
      );
      if (data === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }

      dispatch(savePaymentIntent(data.data.payment_id));
      dispatch(addChallenge(account_payload));

      return data;
    } catch (error) {
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      throw error.response.data;
    }
  };

// NEW
export const stripePaymentVerifyV4 = () => async (dispatch, getState) => {
  try {
    const { payment_intent, challenge_id, account_details } = await getState()
      .payments;
    const payload = {
      payment_id: payment_intent,
      challenge_id,
      email: account_details.email,
      EAs: account_details?.EAs,
      raw_spread: account_details?.raw_spread,
      account_details,
    };
    dispatch(deletePaymentIntent());

    await createHyrosOrder(payload, dispatch, getState);

    const { data } = await axios.post(
      "https://backend.alphacapitalgroup.uk/v2/get/checkout/payment/status/",
      payload,
      tokenConfig(getState)
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    return data;
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
    }
    throw error;
  }
};

export const createHyrosOrder = async (payload, dispatch, getState) => {
  const { payments } = getState();
  let challenge;

  for (const key in payments.challenges) {
    if (payments.challenges.hasOwnProperty(key)) {
      const arr = payments.challenges[key];
      challenge = arr.find((obj) => obj.id === payload.challenge_id);
      if (challenge) {
        break;
      }
    }
  }

  const body = {
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    date: new Date().toISOString(),
    items: [
      {
        name: challenge.name + " Challenge",
        price: challenge.Price,
      },
    ],
  };

  try {
    const res = await axiosInstance.post(
      "/create/hyros/order/",
      body,
      tokenConfig(getState)
    );
    return res;
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    throw error.response.data;
  }
};

export const createHyrosLead = async (payload, dispatch, getState) => {
  const body = {
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
  };

  try {
    const res = await axiosInstance.post(
      "/create/hyros/leads/",
      body,
      tokenConfig(getState)
    );
    return res;
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    throw error.response.data;
  }
};

export const getUserBasicDetails = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get(
      "payments/get/profile/",
      tokenConfig(getState)
    );

    return data;
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
    }
    throw error;
  }
};

export default paymentReducer;
