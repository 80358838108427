import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import A2HS from '../../A2HS'

export const useAuth = () => {
  //   const ACCtoken = true
  const ACCtoken = useSelector((state) => state.ACCtoken)
  return ACCtoken
}
const UserRoute = (props) => {
  const isLogged = useSelector((state) => state.isLogged)
  const isACCtoken = useAuth()
  return isACCtoken != 0 && isLogged === true ? (
    <>
      <Outlet />
      <A2HS/>
    </>
  ) : (
    <Navigate to="/login" />
  )
}

export default UserRoute
