import { axiosInstance } from "../../Axios";
import { tokenConfig } from "./tokenReducer";

export const traderJournal = (data) => {
    return {
        type: "TRADER_JOURNAL",
        payload: data,
    };
};
export const traderJournalCreate = (data) => {
    return {
        type: "JOURNAL_CREATE",
        payload: data,
    };
};
export const traderdetails = (data) => {
    return {
        type: "TRADER_DETAILS",
        payload: data,
    };
};



const initianstate = {
    trader_journal: [],
    trader_details: [],
    trader_journal_create: false,
    cal_date: false
}


const TrademateReducer = (state = initianstate, action) => {
    switch (action.type) {
        case "TRADER_JOURNAL":
            return {
                ...state,
                trader_journal: action.payload
            }
        case "JOURNAL_CREATE":
            return {
                ...state,
                trader_journal_create: action.payload
            }
        case "TRADER_DETAILS":
            return {
                ...state,
                trader_details: action.payload
            }
        default:
            return state;
    }
}

export const fetchTraderJournal = () => async (dispatch, getState) => {
    try {
        const { data } = await axiosInstance.get("v2/trademate/trading-journal/all/", tokenConfig(getState));
        dispatch(traderJournal(data));
    }
    catch (error) { }
}

export const fetchTraderJournalCreate = (Data, symbol, date) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        // const { date, symbol, trade_type, counter_trend, trigger, supporting_evidence, opposing_evidence, heads_up } = Data
        const { date, symbol, trade_type, counter_trend,
            trigger, supporting_evidence, opposing_evidence,
            heads_up, notes, alerts_placed, order_price,
            max_stop_price, size, max_loss,
            technical_stop, exit_traget, time_stop,
            risk_or_reward, system_rules, result_in_fear, profit, entry, greed_or_pride
        } = Data
        const payload = {
          idToken,
          symbol,
          date,
          trade_type,
          counter_trend,
          trigger,
          supporting_evidence,
          opposing_evidence,
          heads_up,
          notes,
          alerts_placed,
          order_price,
          max_stop_price,
          size,
          max_loss,
          technical_stop,
          exit_traget,
          time_stop,
          risk_or_reward,
          system_rules,
          result_in_fear,
          profit,
          entry,
          greed_or_pride,
        };

        const { data } = await axiosInstance.post("v2/trademate/trading-journal/create/",payload, tokenConfig(getState));
        dispatch(traderJournalCreate(data));
        return true;
    }
    catch (error) { }
}


export const fetchTraderDetails = (id) => async (dispatch, getState) => {
    try {
        const { data } = await axiosInstance.get(`v2/trademate/trading-journal/details/${id}/`, tokenConfig(getState));
        dispatch(traderdetails(data));
    }
    catch (error) { }
}


export default TrademateReducer