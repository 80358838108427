import React, { useState, useEffect } from "react";

export default function CountDown(props) {
  const [dateCount, setDateCount] = useState("");

  useEffect(() => {
    const countDownDate = new Date(props.CountDate).getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        setDateCount("Expired");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setDateCount(`${days}d ${hours}h ${minutes}m ${seconds}s left`);
      }
    };

    updateCountdown();

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [props.CountDate]);

  return <div>{dateCount}</div>;
}
