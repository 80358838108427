import {} from "react-router";
import { AcctokenD, logout } from "../actions";
import { RefftokenD } from "./../../store/actions/index";
import { removeDashboard } from "../../store/reducers/dashboardReducer";
import {
  resetProfile,
  sessionTimeout,
} from "../../store/reducers/userProfileReducer";
import { removePayment } from "../../store/reducers/paymentReducer";

const logoutReducer = () => async (dispatch, getState) => {
  dispatch(AcctokenD(0));
  dispatch(RefftokenD(0));
  dispatch(removeDashboard());
  dispatch(resetProfile());
  dispatch(removePayment());
  dispatch(logout());
  sessionLogoutReducer();
  window.location.href = "/";
};

export const sessionLogoutReducer = () => async (dispatch, getState) => {
  dispatch(AcctokenD(0));
  dispatch(RefftokenD(0));
  dispatch(removeDashboard());
  // dispatch(resetProfile());
  dispatch(removePayment());
  dispatch(logout());
  // window.location.href = "/";
  // Navigate.push("/");
  // dispatch(push("/my_url"));
  dispatch(sessionTimeout(true));
};

export default logoutReducer;
