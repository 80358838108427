import React from "react";
import "./popup.scss";
// import Cross from '../../assets/images/Cross.svg'
// import errPU from '../../assets/images/popups/Red.svg'
import errPU from "../../assets/images/popups/error-backdrop-left.png";
import errPUR from "../../assets/images/popups/error-backdrop-right.png";

import successPU from "../../assets/images/popups/success-backdrop-left.png";
import successPUR from "../../assets/images/popups/success-backdrop-right.png";
// import BubbleER from '../../assets/images/errortick.svg'
// import BubbleSC from '../../assets/images/successtick.svg'
import BubbleER from "../../assets/icons/errorModalIcon.svg";
import BubbleSC from "../../assets/icons/successModalIcon.svg";
import Buttons from "../ReusableComponents/formButton";
const CombinedPopup = (props) => {
  console.log(props, "props");
  return props.trigger ? (
    <div className={`Main-PoupupDiv ${props.Popupclass}`}>
      <div className="PopupBubble">
        {props.type === "error" ? (
          <img src={BubbleER} alt="" />
        ) : props.type === "success" ? (
          <img src={BubbleSC} alt="" />
        ) : (
          <></>
        )}
      </div>
      <div className={"PopupMainCon " + props.type}>
        {/* <div className="PopupMainCon-BG-Blur"></div> */}
        <div className="PopupBubble-Btm">
          {props.type === "error" ? (
            <img src={errPU} alt="" />
          ) : props.type === "success" ? (
            <img src={successPU} alt="" />
          ) : (
            <></>
            // <img src={successbubble} />
          )}
        </div>
        <div className="PopUpTextCon">
          <h1>{props.head}</h1>
          <h2>
            {props.subhead}
            {props.type === "error"
              ? ""
              : ""}
          </h2>
        </div>
        <div className="PopupBoxThird">
          <div className="PopupBubble-Right">
            {props.type === "error" ? (
              <img src={errPUR} alt="" />
            ) : props.type === "success" ? (
              <img src={successPUR} alt="" />
            ) : (
              <></>
              // <img src={successbubble} />
            )}
          </div>
        </div>
      </div>
      <button
        className="popup-btn"
        style={
          props.type === "error"
            ? { background: "#FD504F" }
            : { background: "#0CCE6B" }
        }
        onClick={() => {
          props.setTrigger(false);
          props.setModalClose(true);
          try {
            props.setTrigger2(false);
            props.setModalClose(true);
          } catch (error) {
            // console.log('noTrigger2')
          }
          if (props.onClick) {
            props.onClick();
            props.setOpen(true);
            props.setModalClose(true);
          }
        }}
        // src={Cross}
        alt=""
      >
        Continue
      </button>
    </div>
  ) : (
    ""
  );
};

export default CombinedPopup;
