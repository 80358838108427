import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import StyledFilledButton from "../ReusableComponents/Button";
import OutlinedButton from "../ReusableComponents/OutlinedButton";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 550,
  with: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  background:
    "linear-gradient(115.95deg, rgba(239, 239, 239, 0.3) 10.92%, rgba(255, 255, 255, 0.04) 96.4%)",
  backdropFilter: "blur(50px)",
  borderRadius: "10px",
  color: "white",
};

const A2HSPrompt = ({ handleClick }) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const handleInstall = (e) => {
    handleClick(e);
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            Install our app for better experience.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
            </Grid>
            <Grid item xs={6}>
              <StyledFilledButton onClick={handleInstall}>
                Install
              </StyledFilledButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default A2HSPrompt;
