export const dollarUS = (number, maximumSignificantDigits = 10) => {
  if (isNaN(number)) return "$0";
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits,
    minimumFractionDigits: 2,
  }).format(number);
};
export const dollarUSK = (number, maximumSignificantDigits = 10) => {
  if (isNaN(number) || number <= 0) return "$0";

  const abbreviateNumber = (num) => {
    if (num >= 1_000_000) {
      return `$${(num / 1_000_000).toFixed(0)}M`;
    } else if (num >= 1_000) {
      return `$${(num / 1_000).toFixed(0)}k`;
    } else {
      return `$${num.toFixed(0)}`;
    }
  };

  return abbreviateNumber(number);
};

export function utc_to_eet_new(date) {
  const EETOffset = 0 * 60; // EET is UTC+2
  const localTime = date && new Date(date?.getTime() + EETOffset * 60 * 1000);
  return localTime.toISOString(); // Adjust as needed for display format
}

export const formatDate = (date) => {
  if (date === null) return "";
  const DateObj = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${DateObj.getDate()} ${
    months[DateObj.getMonth()]
  } ${DateObj.getFullYear()}`;
};

export const formatDateTime = (dateTime) => {
  if (dateTime === null) return "";
  const DateTimeObj = new Date(dateTime);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${DateTimeObj.getDate()} ${
    months[DateTimeObj.getMonth()]
  } ${DateTimeObj.getFullYear()} ${DateTimeObj.getHours()}:${DateTimeObj.getMinutes()}:${DateTimeObj.getSeconds()}`;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const dateParser = (date_str) => {
  const [date, time] = date_str.split(" ");
  const [year, month, day] = date.split("-");
  const [hour, minute, seconds] = time.split(":");
  return new Date(
    Date.UTC(year, Number(month) - 1, day, hour, minute, seconds)
  );
};

export const utc_to_eet = (_date) => {
  const tzString = "EET";
  let date = _date;
  if (typeof date === "string") date = dateParser(date);
  return date.toLocaleString("en-US", { timeZone: tzString });
};
