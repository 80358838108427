import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./MobileTabs.scss";
import { customStyles } from "./customstyle";
const MobileTabs = ({
  activeTab = 0,
  setActiveTab = () => {},
  tabItems = [],
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(tabItems);
  }, [tabItems, activeTab]);

  // Map tab items to menu items for rendering in Select component
  const menuItems = items?.map((item, index) => ({
    label: item?.name,
    value: item?.value,
  }));

  // Handle selection change in mobile view
  const handleMobileTabChange = (selectedOption) => {
    setActiveTab(selectedOption?.value);
  };

  return (
    <div className="mobileTabContainer">
      <div className="mobileTabWrapper">
        <Select
          name="tabs"
          id="tabs"
          className="mobileTab"
          classNamePrefix="react-select"
          options={menuItems}
          styles={customStyles(49)}
          isSearchable={false}
          value={menuItems.find((item) => item?.value === activeTab)}
          onChange={handleMobileTabChange}
          placeholder="Select your option"
        />
      </div>
    </div>
  );
};

export default MobileTabs;
