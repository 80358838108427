import React from "react";
import "./style.scss";
import AuthFrame from "../../../components/Auth/AuthFrame";
import PasswordOTPForm from "../../../components/Auth/PasswordOTPForm";

function PasswordOTP() {
  return (
    <div className="passwordotp-container">
      <div className="passwordotp-left-wrapper">
        <div className="passwordotp-left">
          <PasswordOTPForm />
        </div>
      </div>
      <div className="passwordotp-right">
        <AuthFrame />
      </div>
    </div>
  );
}

export default PasswordOTP;
