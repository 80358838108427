import React from "react";
import { axiosInstance } from "../Axios";
import { useDispatch } from "react-redux";
import { AcctokenD, RefftokenD, login, logout } from "../store/actions";
import { getUserProfile } from "../store/reducers/userProfileReducer";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { setExpiry } from "../store/reducers/tokenReducer";
import axios from "axios";

const GoogleLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const googleLogin = () => {
    const code = searchParams.get("code");
    axios
      .get(
        `https://backend.alphacapitalgroup.uk/google/user/profile/?code=${code}`
      )
      .then((res) => {
        dispatch(AcctokenD(res.data.idToken));
        dispatch(RefftokenD(res.data.refreshToken));
        const expiresInSeconds = 86400;
        const expiresAtDate = new Date();
        expiresAtDate.setSeconds(expiresInSeconds);
        dispatch(setExpiry(expiresAtDate));
        dispatch(login());
        dispatch(getUserProfile()).then((data) => {
          if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length === 0
          ) {
            navigate("/FundingEvaluationPayment/4");
          } else if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length > 0
          ) {
            navigate("/AccountMetric");
          }
        });
      })
      .catch((err) => {
        navigate("/login");
      });
  };

  useEffect(() => {
    googleLogin();
  }, []);
  return (
    <div style={{ height: "100vh", color: "white", position: "relative" }}>
      <h3
        style={{
          position: "absolute",
          transform: "translate(-50%, 0)",
          left: "50%",
          top: "40%",
        }}
      >
        Please wait...
      </h3>
    </div>
  );
};

export default GoogleLogin;
