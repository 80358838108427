import React, { useState, useEffect } from "react";
import "./AllAddressPopup.scss";
import Cross from "../../assets/icons/NewIcons/exittIcon.svg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { dispatchSelectAddress } from "../../store/reducers/AddressReducer";
import { axiosInstance } from "../../Axios";
import { ReactComponent as ActiveRadioIcon } from "../../assets/icons/NewIcons/RadiobuttonnonActive.svg";
import { ReactComponent as RadioIcon } from "../../assets/icons/NewIcons/Radiobutton.svg";

const AllAddressPopup = (props) => {
  // const selectAdd = useSelector((state) => state.selectAddress);
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState();
  const [selectedRadio, setSelectedRadio] = useState(1);

  const [Data, setData] = useState([]);
  const ACCtoken = useSelector((state) => state.ACCtoken);
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    config.headers["AUTHORIZATION"] = ACCtoken;
    axiosInstance.get("v2/user/address/get/", config).then((res) => {
      setData(res.data.data);
    });
  }, []);
  const select = useSelector((state) => state.selectAdress.isSelected);
  useEffect(() => {
    dispatch(dispatchSelectAddress());
  });

  return props.trigger ? (
    <div className="addressMain-PoupupDiv ">
      <div className="addressPopupMainCon ">
        <div className="addressPopUpTextCon ">
          <div className="allAddressSection-T">
            <h4> Billing Address</h4>

            <div className="crossIconimg_div">
              <img
                onClick={() => {
                  props.setTrigger(false);
                  try {
                    props.setTrigger2(false);
                  } catch (error) {
                    // console.log('noTrigger2')
                  }
                }}
                src={Cross}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {/* <div className="allAddressList">
            {Object.keys(Data).map((data) => {
              return (
                <div
                  onClick={() => props.handleAddAddress(data)}
                  className="allAddress-item"
                >
                  <div className="rbtn">
                    <div
                      className="rbic"
                      onClick={() => setClicked(Data[data].id)}
                    ></div>
                  </div>
                  <div className="txtc">
                    <h3>{Data[data].Apt_no}</h3>
                    <h4>
                      {Data[data].Apt_no}, {Data[data].Street},{" "}
                      {Data[data].State}, {Data[data].Country},{Data[data].Zip}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="addressOptions">
            <div className="add_box">
              <div
                onClick={() => {
                  setSelectedRadio(1);
                }}
                className={"rbcon " + (selectedRadio === 1 && " active")}
              >
                <div className="rbtn">
                  <div className="rbic">
                    {selectedRadio === 1 ? <ActiveRadioIcon /> : <RadioIcon />}
                  </div>
                </div>
              </div>
              <div className="address_one">
                <div className="name_div">
                  <h5>David Hanson</h5>
                </div>
                <div className="street_address">
                  <p>
                    1623 Hunningon Place, 8 Lousiville, Kentucky, 40220, Canada
                  </p>
                </div>
              </div>
            </div>
            <div className="add_box">
              <div
                onClick={() => {
                  setSelectedRadio(2);
                }}
                className={"rbcon " + (selectedRadio === 2 && " active")}
              >
                <div className="rbtn">
                  <div className="rbic">
                    {selectedRadio === 2 ? <ActiveRadioIcon /> : <RadioIcon />}
                  </div>
                </div>
              </div>
              <div className="address_two">
                <div className="name_div">
                  <h5>David Hanson</h5>
                </div>
                <div className="street_address">
                  <p>B-45 Hunningon Place, 8 Lousiville, Kentucky, 40220, US</p>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              try {
                props.setTrigger3(true);
              } catch (error) {
                // console.log('noTrigger2')
              }
            }}
            className="allAddress-new-add"
            style={{ cursor: "pointer" }}
          >
            Add new address
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default AllAddressPopup;
