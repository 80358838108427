import React, { useState, useEffect, useRef } from "react";
import * as ReactBootstrap from "react-bootstrap";
import "../../assets/css/payment.scss";
import Title from "./../../components/ReusableComponents/title";
import caret from "../../assets/images/charm_tick.svg";
import line from "../../assets/images/Line.svg";
import invoice from "../../assets/icons/invoice.svg";
import { useSelector, useDispatch } from "react-redux";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios";
import HeadAndDesc from "./../../components/ReusableComponents/HeadAndDesc";
import loggedReducer from "../../store/reducers/isLogged";
import useMobile from "../../hooks/useMobile";
import { dollarUS, dollarUSK, formatDate } from "../../utilities/formatters";
import { axiosInstance } from "../../Axios";
import { tokenConfig } from "../../store/reducers/tokenReducer";
import { myStore } from "../..";
import Loader from "../../reusableComponents/Loader";
import { setLoading } from "../../store/reducers/loader";
import { Pagination } from "@mui/material";
import dropdownIcon from "../../assets/icons/dropdown.svg";
import { Calendar } from "react-calendar";
import PaginationComponent from "../../utilities/Pagination";
import TickIcon from "../../assets/icons/tick-close.svg";
import crossIcon from "../../assets/icons/circle-close.svg";
import progressIcon from "../../assets/icons/progress-cricle.svg";
import dayjs from "dayjs";
const StatFormatter = (data) => {
  const Stat = data.toLowerCase();

  const classList =
    Stat === "succeeded"
      ? "StatusPassed"
      : Stat.includes("pending")
      ? "StatusProgress"
      : "StatusFailed";

  return (
    <div className={`Status ${classList}`}>
      {Stat === "succeeded" ? (
        <img src={TickIcon} alt="" style={{ marginRight: "6px" }} />
      ) : Stat.includes("pending") ? (
        <img src={progressIcon} alt="" style={{ marginRight: "6px" }} />
      ) : (
        <img src={crossIcon} alt="" style={{ marginRight: "6px" }} />
      )}
      {data.includes("Pending") ? "Pending" : data}
    </div>
  );
};

const userNameFormatter = (cell, row) => {
  const dp = row?.dp ? (
    <img src={row.dp} alt={cell} width="30" height="30" />
  ) : (
    <div className="alphabet-cirlce">
      <span>{cell[0].toUpperCase()}</span>
    </div>
  );

  return (
    <div className="column username" style={{ whiteSpace: "nowrap" }}>
      {/* {dp} {cell} */}
      {cell}
    </div>
  );
};

const PaymentHistory = () => {
  const [DataInfo, setDataInfo] = useState([]);
  // const [width, setWidth] = useState(window.innerWidth);
  const ACCtoken = useSelector((state) => state.ACCtoken);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.is_loading);
  const [open, setOpen] = useState(loader);
  useEffect(() => {
    setOpen(loader);
  }, [loader]);

  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  const isMobile = useMobile();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [showFundingPopup, setShowFundingPopup] = useState(false);
  const [showBalancePopup, setShowBalancePopup] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedPlan, setSelectedPlan] = useState("All");

  const [showChall, setshowChall] = useState(false);
  const [showPlan, setshowPlan] = useState(false);
  const [showCalender, setshowCalender] = useState(false);
  const [List, setList] = useState([]);
  const [ListAll, setListAll] = useState(true);

  const planPopupRef = useRef(null);
  const challengePopupRef = useRef(null);
  const calendarRef = useRef(null);

  const challengeValues = {
    "25K Challenge": 25000,
    "50K Challenge": 50000,
    "100K Challenge": 100000,
    "200K Challenge": 200000,
    All: null, // No limit
  };

  const [selectedChallenge, setSelectedChallenge] = useState("All");

  useEffect(() => {
    setOpen(loader);
  }, [loader]);

  useEffect(() => {
    fetchPaymentHistory();
  }, [dateRange, selectedPlan, selectedChallenge]);

  const fetchPaymentHistory = () => {
    dispatch(setLoading(true));
    const params = {};

    if (dateRange[0]) {
      params.start_date = dayjs(dateRange[0]).format("YYYY-MM-DD");
    }
    if (dateRange[1]) {
      params.end_date = dayjs(dateRange[1]).format("YYYY-MM-DD");
    }
    if (search) {
      params.search = search;
    }
    if (selectedPlan && selectedPlan !== "All") {
      params.Funding_evaluation = selectedPlan;
    }

    const maxBalance = challengeValues[selectedChallenge];
    if (maxBalance !== null) {
      params.account_balance_max = maxBalance;
      params.account_balance_min = maxBalance;
    }

    axiosInstance
      .get("/v2/transaction/history/", {
        ...tokenConfig(myStore.getState),
        params,
      })
      .then((res) => {
        dispatch(setLoading(false));
        if (res.data === "No Cases found!") {
          alert("No Cases found!");
          return;
        }
        if (res.data === "Please verify the Access Token!") {
          dispatch(loggedReducer());
          return;
        }
        setDataInfo(res.data);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.error(error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        planPopupRef.current &&
        !planPopupRef.current.contains(event.target) &&
        showPlan
      ) {
        setshowPlan(false);
      }
      if (
        challengePopupRef.current &&
        !challengePopupRef.current.contains(event.target) &&
        showChall
      ) {
        setshowChall(false);
      }
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        showCalender
      ) {
        setshowCalender(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPlan, showChall, showCalender]);

  console.log(selectedPlan, dateRange);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const ShowCalender = () => {
    setshowCalender(!showCalender);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const pageCount = Math.ceil(DataInfo.length / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentData = DataInfo.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  console.log(currentData.length, "currennnnnnnt");

  const ShowChallenge = () => {
    setshowChall(!showChall);
  };

  const ShowPlan = () => {
    setshowPlan(!showPlan);
  };

  const ChallengePopup = () => {
    const handleChallengeSelect = (challenge) => {
      setSelectedChallenge(challenge);
      setCurrentPage(1);
    };

    const challenges = [
      "All",
      "25K Challenge",
      "50K Challenge",
      "100K Challenge",
      "200K Challenge",
    ];

    return (
      <div className="DropChall" ref={challengePopupRef}>
        {challenges.map((challenge) => (
          <div
            key={challenge}
            className={`DropOpt d-flex justify-content-between ${
              selectedChallenge === challenge ? "selected" : ""
            }`}
            onClick={() => handleChallengeSelect(challenge)}
          >
            <Title four>{challenge}</Title>
            <div className="checkbox">
              {selectedChallenge === challenge && <img src={caret} alt="" />}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setCurrentPage(1);
  };

  const PlanPopup = () => {
    const plans = [
      "All",
      "Alpha pro",
      "Free Trial",
      "Alpha Pro Plus",
      "Scaling",
      "Swing",
    ];

    return (
      <div className="DropChall" ref={planPopupRef}>
        {plans.map((plan) => (
          <div
            key={plan}
            className={`DropOpt d-flex justify-content-between ${
              selectedPlan === plan ? "selected" : ""
            }`}
            onClick={() => handlePlanSelect(plan)}
          >
            <Title four>{plan}</Title>
            <div className="checkbox">
              {selectedPlan === plan && <img src={caret} alt="" />}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const [date, setDate] = useState(new Date());
  const handleChangeCalender = (e) => {
    ListAll(false);
    List(false);
    var doptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    let var1 = e.target.value.toLocaleDateString("en-GB", doptions);
    setDate(var1);
    alert(var1);
  };

  const handleDateRangeChange = (range) => {
    if (range.length === 2) {
      const [startDate, endDate] = range;
      setDateRange([startDate, endDate]);
      setCurrentPage(1)

      console.log("Selected Date Range:", {
        start: dayjs(startDate).format("YYYY-MM-DD"),
        end: dayjs(endDate).format("YYYY-MM-DD"),
      });
    }
  };

  const handleResetCalendar = () => {
    setDateRange([null, null]);
    setshowCalender(false)
  };

  const TableCalender = () => {
    return (
      <div className="TableCalenderBox" ref={calendarRef}>
        <Calendar
          selectRange={true}
          onChange={handleDateRangeChange}
          value={dateRange}
        />
        <div className="calendar_btns">
        <button onClick={handleResetCalendar} className="reset-calendar-btn">
          Reset
        </button>
        <button
          onClick={() => setshowCalender(false)} 
          className="reset-calendar-btn"
        >
          Close
        </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="PageContainer LeaderboardPage PaymentHistoryPage payment_history mtt-20"
      style={{ position: "relative" }}
    >
      <Loader open={open} />
      <div className="HistoryDesk">
        <div className="affiliateTableContainer">
          <div className="affiliateTableWrapper">
            <ReactBootstrap.Table className="LeaderboardTable">
              <thead>
                <tr>
                  <th>
                    Funding Evaluation
                    <span style={{ cursor: "pointer" }} onClick={ShowPlan}>
                      <img src={dropdownIcon} alt="" />
                    </span>
                    <div className="ShowChallengePopup">
                      {showPlan && <PlanPopup />}
                    </div>
                  </th>
                  {/* <th>
                    Account Balance
                    <span style={{ cursor: "pointer" }} onClick={ShowChallenge}>
                      <img src={dropdownIcon} alt="" />
                    </span>
                    <div className="ShowChallengePopup">
                      {showChall && <ChallengePopup />}
                    </div>
                  </th> */}
                  <th>Transaction ID</th>
                  <th
                    style={{ position: "relative" }}
                    className="dateCalendarContainer"
                  >
                    Date
                    <span style={{ cursor: "pointer" }} onClick={ShowCalender}>
                      <img src={dropdownIcon} alt="" />
                    </span>
                    <div className="ShowChallengePopup">
                      {showCalender && <TableCalender />}
                    </div>
                  </th>
                  <th>Amount</th>
                  {/* <th>Invoice</th> */}
                  <th>Invoice</th>
                  <th>Status</th>
                </tr>
              </thead>
              {/* <tbody>{Data.map(renderLeaderBoard)}</tbody> */}
              <tbody>
                {currentData && currentData?.length > 0 ? (
                  Object?.keys(currentData).map((data, key) => {
                    return (
                      <tr key={key} style={{}}>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          {userNameFormatter(
                            currentData[data].Funding_evaluation
                          )}

                          {currentData[data].Account_balance
                            ? dollarUSK(
                                Number(currentData[data].Account_balance)
                              )
                            : false}
                        </td>
                        {/* <td>
                        {currentData[data].Account_balance
                          ? dollarUS(Number(currentData[data].Account_balance))
                          : false}
                      </td> */}
                        <td className="paymentTD">
                          {currentData[data].payment_id}
                        </td>
                        <td>
                          {currentData[data].date
                            ? formatDate(new Date(currentData[data].date))
                            : false}
                        </td>
                        <td>
                          <span>
                            {currentData[data].amount
                              ? dollarUS(Number(currentData[data].amount) / 100)
                              : false}
                          </span>
                        </td>
                        {/* <td>
                      <a href={currentData[data].invoice}>
                        <img src={invoice} style={{ filter: "invert(1)" }} />
                      </a>
                    </td> */}
                        <td>
                          {currentData[data].invoice &&
                          currentData[data].invoice !== "N/A" ? (
                            <a
                              href={currentData[data].invoice}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#0D56EB" }}
                            >
                              <img
                                src={invoice}
                                alt=""
                                style={{ marginLeft: "10px" }}
                              />
                            </a>
                          ) : (
                            <span style={{ marginLeft: "10px" }}>N/A</span>
                          )}
                        </td>
                        <td>
                          {StatFormatter(currentData[data].payment_status)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td className="no-data-message">No data available</td>
                )}
              </tbody>
            </ReactBootstrap.Table>
          </div>
          <PaginationComponent
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={handlePageChange}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentHistory;
