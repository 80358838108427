import "./customSelect.scss";

const customStyles = (
  height = 49,
  bgcolor = "#0E1011",
  color = "#fff",
  inputcolor = "#24282a"
) => ({
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Outfit",
    color: color,
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    zIndex: 1,
    background: "transparent !important",
  }),
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Outfit",
    color: color,
    width: "100%",
    borderRadius: 10,
    border: state.isFocused
      ? "1px solid #0D56EB !important"
      : "1px solid #2D2D2D !important",
    background: bgcolor,
    cursor: "pointer",
    paddingRight: "10px",
    paddingLeft: "10px",
    outline: "none !important",
    boxShadow: "none !important",
    height: `${height}px`, // Overall height
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    zIndex: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px", // Add some padding for alignment
    backgroundColor: bgcolor,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0 !important",
    padding: "0 !important",
    backgroundColor: state.isFocused
      ? "transparent !important"
      : `${bgcolor} !important`,
    color: `${color} !important`,
    border: "none !important",
    boxShadow: "none !important",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? "#fff" : "#fff",
    backgroundColor: isSelected ? "#0640B9" : isFocused ? "#1c1c1c" : undefined,
    border: "none",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    height: `${height - 1}px`,
    borderRadius: 10,
    cursor: "pointer",
    textAlign: "center",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    "&:hover": {
      borderColor: "#0640B9",
      backgroundColor: "#0640B9",
      color: "#fff",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1c1c1c",
    border: "none",
    zIndex: "1 !important",
    overflow: "hidden",
    width: "100%",
    padding: 10,
    borderRadius: 10,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    height: `${height}px`, // Adjust to match control height
    display: "flex",
    alignItems: "center",
    color: "#fff",
  }),
  indicatorSeparator: () => ({
    display: "none", // Hides the separator
  }),
});

export { customStyles };
