import React from "react";
import MainRouter from "./components/ReusableComponents/MainRouter";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import HubSpotChat from "./components/HubSpotChat";
import Chat from "./Chat";
import { useEffect } from "react";

import "./App.scss";
import "./neonMode.scss";
import "./light-new.scss";
import { Toaster } from "react-hot-toast";
import zIndex from "@mui/material/styles/zIndex";

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: "light",
  },
});

function App() {
  // Get the selected theme from Redux state
  const theme = useSelector((state) => state.theme);

  // Effect to handle context menu
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // Effect to apply the selected theme
  useEffect(() => {
    // Remove existing theme classes
    document.body.classList.remove("light", "light-new");
    // Add class for the selected theme
    document.body.classList.add(theme.theme);
  }, [theme]);

  // remove all console logs
  console.log = function () {};

  return (
    <div className="App">
      {/* ThemeProvider to apply the dark theme */}
      <ThemeProvider theme={darkTheme}>
        {/* Apply CSS baseline */}
        <CssBaseline />
        {/* Render Chat component */}
        <Chat />
        {/* Render MainRouter component */}
        <MainRouter />
        <Toaster
          style={{ zIndex: "100000000" }}
          position="top-right"
          reverseOrder={false}
        />
      </ThemeProvider>
      {/* Other components */}
      {/* <Notification /> */}
      {/* <HubSpotChat/> */}
    </div>
  );
}

export default App;
