import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { axiosInstance } from "../../Axios";
import CombinedPopup from "../../components/Popups/CombinedPopup";

const VerifyEmail = () => {
  const param = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [combinedPUHead, setCombinedPUHead] = useState("");
  const [combinedPUBody, setCombinedPUBody] = useState("");
  const [popUpType, setPopUpType] = useState("");
  const navigate = useNavigate()

  const handleConfirmVerifyEmail = async () => {
    try {
      const response = await axiosInstance.get(`/user/verify-email/${param["*"]}`);

      setShowPopup(true);
      console.log(response,'responsedfdfd')
      setPopUpType("success");
      setCombinedPUHead("Woo hoo !");
      setCombinedPUBody(response?.data?.detail);
    } catch (error) {
   console.log(error)
   navigate('/profile')
    }
  };
  
  useEffect(() => {
    param && handleConfirmVerifyEmail();
  }, []);
  return <CombinedPopup
  type={popUpType}
  trigger={showPopup}
  setTrigger={setShowPopup}
  head={combinedPUHead}
  subhead={combinedPUBody}
  onclick={()=>navigate('/profile')}
/>;
};

export default VerifyEmail;
