import { axiosInstance } from "../../Axios";
import { sessionLogoutReducer } from "./logout";
import { tokenConfig } from "./tokenReducer";

const initialState = {count: 0, results:[]};

const notificationReducer = (state = initialState, action) => {
    switch(action.type){
        case "SET_NOTIFICATIONS":
            return {
                ...state,
                ...action.payload
            }
        case "SET_PAGINATED_NOTIFICATIONS":
            return {
                ...state,
                ...action.payload,
                results: [
                    ...state.results,
                    ...action.payload.results
                ]
            }
        default: return state
    }
}

const setNotification = (payload) => ({
    type: 'SET_NOTIFICATIONS',
    payload
})

const setPaginatedNotification = (payload) => ({
    type: 'SET_PAGINATED_NOTIFICATIONS',
    payload
})



export const getNotifications = (page=1)  => async (dispatch, getState) => {
    try {
        const {data, status} = await axiosInstance.get(`v2/get/notifications/?page=${page}`, tokenConfig(getState));
        if(status>399) throw(data);
        if(page>1) dispatch(setPaginatedNotification(data));
        else dispatch(setNotification(data));
    } catch (error) {
        console.log("Get notification API error", error)
    }
}

export const markRead = (ids) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const {data, status} = await axiosInstance.post(`/notifications/mark/read/`, {idToken, ids});
        if (data === "Please verify the Access Token!") {
            dispatch(sessionLogoutReducer());
            return false;
        }
        if(status>399) throw(data);
        dispatch(getNotifications())
    } catch (error) {
        console.log("Mark read API error", error)
    }
}

export default notificationReducer;