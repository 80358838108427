import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import "../../assets/css/competition.scss";
import Title from "../ReusableComponents/title";
import CombinedPopup from "../../components/Popups/CombinedPopup";
import countryCodes from "../../utilities/countriesData";
import Select from "react-select";
import { customStyles } from "../../reusableComponents/MobileTabs/customstyle";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../Axios";
import { getUserProfile } from "../../store/reducers/userProfileReducer";

const CompDetForm = ({ isCommunity, onSubmitChange }) => {
  const user_profile = useSelector((state) => state.user);
  const ACCtoken = useSelector((state) => state.ACCtoken);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [popupConfig, setPopupConfig] = useState({
    header: "",
    body: "",
    type: "",
  });

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    Contact_number: "",
    country_code: "",
    Country: "",
    password: "",
    City: "",
  });
  const [disableFields, setDisableFields] = useState({
    first_name: false,
    last_name: false,
    email: false,
    Contact_number: false,
    country_code: false,
    City: false,
    Country: false,
    password: false,
    communityPassword: false, // if applicable
  });

  useEffect(() => {
    console.log(user_profile, "user_profile");

    // Update form data with values from user_profile
    setFormData({
      first_name: user_profile.first_name,
      last_name: user_profile.last_name,
      email: user_profile.email,
      Contact_number: user_profile.Contact_number,
      country_code: user_profile.country_code,
      City: user_profile.City,
      Country: user_profile.Country,
    });

    // Check each field in formData and match with user_profile to determine if it should be disabled
    const newDisableFields = {};

    Object.keys(formData).forEach((key) => {
      const userValue = user_profile[key];

      // Check if the value exists and if it's not an empty string or zero (for numbers)
      if (
        userValue &&
        (typeof userValue === "string" ? userValue.length > 0 : userValue !== 0)
      ) {
        newDisableFields[key] = true; // Disable the field if the value is non-empty or non-zero
      } else {
        newDisableFields[key] = false; // Keep the field enabled if the value is empty or zero
      }
    });

    setDisableFields(newDisableFields); // Update state with disable field status
  }, [user_profile]); // Dependency on user_profile so it updates when the profile changes

  console.log(disableFields, "disableFields");

  const countryOptions = Object?.entries(countryCodes)?.map(([key, value]) => ({
    label: key,
    value: key,
    code: value,
  }));

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const {
      first_name,
      last_name,
      email,
      Contact_number,
      country_code,
      City,
      Country,
      password,
      communityPassword, // If the community password is also required
    } = formData;

    // Check if any of the required fields are empty
    if (
      !first_name ||
      !last_name ||
      !email ||
      !Contact_number ||
      !country_code ||
      !City ||
      !Country ||
      !password ||
      (isCommunity && !communityPassword) // Only check for communityPassword if isCommunity is true
    ) {
      setPopupConfig({
        header: "Oh snap!",
        body: "All fields are mandatory.",
        type: "error",
      });
      setShowPopup(true);
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (validateForm()) {
      const { communityPassword, ...data } = formData;
      const payload = {
        ...data,
        ...(isCommunity && { competition_password: communityPassword }),
      };
      const updateProfile = async () => {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: ACCtoken,
          },
        };

        try {
          const response = await axiosInstance.post(
            "/v2/user/profile/create/",
            formData,
            config
          );
          console.log(response, "response");

          dispatch(getUserProfile());
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      };

      if (
        Object.keys(disableFields).some(
          (key) => key !== "password" && disableFields[key] === false
        )
      ) {
        updateProfile();
      }
      const response = onSubmitChange(e, {
        name: payload.first_name + payload?.last_name,
        pwd: payload.password,
      });
      console.log(response, "responseresponse");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <Grid container item lg={8} sm={12}>
      <CombinedPopup
        type={popupConfig.type}
        trigger={showPopup}
        setTrigger={setShowPopup}
        head={popupConfig.header}
        subhead={popupConfig.body}
      />
      <Grid item container>
        <form
          style={{
            width: "100%",
            border: "1px solid #8B8E93",
            borderRadius: "12px",
            height: "fit-content",
          }}
          className="form_div"
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: "1rem" }}
            className="form_innerWrapper"
          >
            {[
              { label: "First Name", field: "first_name", type: "text" },
              { label: "Last Name", field: "last_name", type: "text" },
              { label: "Email", field: "email", type: "email" },
              {
                label: "Contact Number",
                custom: (
                  <div className="contactContainer">
                    <TextField
                      fullWidth
                      className="form-input-text countryCode_div"
                      value={formData.country_code}
                      onChange={(e) =>
                        handleChange("country_code", e.target.value)
                      }
                      disabled={disableFields?.country_code}
                    />
                    <TextField
                      fullWidth
                      className="form-input-text contactNumber_div"
                      value={formData.Contact_number}
                      onChange={(e) =>
                        handleChange("Contact_number", e.target.value)
                      }
                      disabled={disableFields?.Contact_number}
                    />
                  </div>
                ),
              },

              {
                label: "City",
                field: "City",
                type: "text",
              },
              {
                label: "Country",
                custom: (
                  <Select
                    className="selectState_div react-select-container"
                    classNamePrefix="react-select"
                    value={countryOptions.find(
                      (itm) =>
                        itm.label.toLowerCase() ===
                        formData.Country?.toLowerCase()
                    )}
                    options={countryOptions}
                    onChange={(selectedOption) =>
                      handleChange("Country", selectedOption.label)
                    }
                    styles={customStyles(
                      56,
                      "#24282a",
                      disableFields?.Country ? "#161718" : "white",
                      "#24282a"
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    isDisabled={disableFields?.Country}
                    components={
                      disableFields?.Country
                        ? { DropdownIndicator: () => null }
                        : undefined
                    }
                  />
                ),
              },
              { label: "Password", field: "password", type: "text" },

              ...(isCommunity
                ? [
                    {
                      label: "Community Password",
                      field: "communityPassword",
                      type: "password",
                    },
                  ]
                : []),
            ].map((input, index) => (
              <Grid key={index} item lg={6} sm={12}>
                <Title three>{input.label}</Title>
                {input.custom || (
                  <TextField
                    fullWidth
                    type={input.type || "text"}
                    value={formData[input.field] || ""}
                    onChange={(e) => handleChange(input.field, e.target.value)}
                    disabled={disableFields[input?.field]}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <button
            className="Button"
            type="submit"
            style={{ maxWidth: "390px", width: "100%", marginBottom: "1rem" }}
            disabled={isLoading} // Disable button while is_loading
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </Grid>
    </Grid>
  );
};

CompDetForm.propTypes = {
  isCommunity: PropTypes.bool,
  onSubmitChange: PropTypes.func.isRequired,
};

export default CompDetForm;
