import { axiosInstance } from "../../Axios";
import logoutReducer from "./logout";
import { sessionLogoutReducer } from "./logout";
import { getNotifications } from "./notifications";
import { tokenConfig } from "./tokenReducer";

const saveUserProfile = (data) => {
  
  return {
    type: "SAVE_USER_PROFILE",
    payload: data,
  };
};
const saveUserSettings = (data) => {
  return {
    type: "SAVE_USER_SETTING",
    payload: data,
  };
};
const getUserSettings = (data) => {
  return {
    type: "GET_USER_SETTING",
    payload: data,
  };
};
export const addCurrentAccount = (data) => {
  return {
    type: "ADD_CURRENT_ACCOUNT",
    payload: data,
  };
};

export const removeCurrentAccount = () => {
  return {
    type: "REMOVE_CURRENT_ACCOUNT",
  };
};

export const resetProfile = () => {
  return {
    type: "RESET_PROFILE",
  };
};
const saveUserCommission = (data) => {
  return {
    type: "SAVE_USER_COMMISSION",
    payload: data,
  };
};

export const removeUserCommission = () => {
  return {
    type: "REMOVE_USER_COMMISSION"
  }
}

const initialState = { sessionTimeout: false , url: '', referalCode: '' };
const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_USER_PROFILE":
      return {
        ...state,
        ...action.payload,
      };
    case "ADD_CURRENT_ACCOUNT":
      if(typeof(action.payload) === 'object'){
        return {
          ...state,
          current_account: action.payload.login_id,
          current_platform: action.payload.platform,
          current_type: action.payload.accountType,
          current_status: action.payload.activeStatus
        };
      }
      else{
        return {
          ...state,
          current_account: action.payload,
          current_platform: "mt5",
        };
      }
    case "REMOVE_CURRENT_ACCOUNT":
      return {
        ...state,
        current_account: null,
        current_platform: null
      };
    case "SAVE_USER_SETTING":
      return {
        ...state,
        User_settings: action.payload,
      };
    case "GET_USER_SETTING":
      return {
        ...state,
        Get_User_settings: action.payload,
      };
    case "RESET_PROFILE":
      const curr_acc = state.current_account;
      const { sessionTimeOut } = state;
      return { current_account: curr_acc, current_platform: state.current_platform, sessionTimeOut };
    case "SET_SESSION_TIME_OUT":
      return {
        sessionTimeOut: action.payload,
      };
    case "SAVE_USER_COMMISSION":
      return {
        ...state,
        url: action.payload.url,
        referalCode: action.payload.code,
      }
    case "REMOVE_USER_COMMISSION":
      return {
        ...state,
        url:"",
        referalCode:""
      }
    default:
      return state;
  }
};
// const userSettingsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case 'SAVE_USER_SETTING':
//       return {
//         ...state,
//         ...action.payload,
//       }
//     case 'RESET_PROFILE':
//       return {}
//     default:
//       return state
//   }
// }

// export const getUserAccountSettings = () => async (dispatch, getState) => {
//   try {
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // };
      // config.headers['AUTHORIZATION'] = getState().ACCtoken;
//     const { data } = await axiosInstance.post('v2/user/settings/', config)
//     if (data === 'No User Profile found!') {
//       dispatch(resetProfile())
//     }
//     dispatch(saveUserProfile(data.data))
//   } catch (error) {
//     dispatch(resetProfile())
//   }
// }
export const sessionTimeout = (data) => {
  return {
    type: "SET_SESSION_TIME_OUT",
    payload: data,
  };
};



export const getUserProfile = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get("v3/user/profile/get/", tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    if (data === "No User Profile found!") {
      dispatch(resetProfile());
    }
    dispatch(
      saveUserProfile({
        ...data.data,
        trading_accounts: data["accounts"],
      })
    );

    
    const cur_acc = await getState().user.current_account;
    const accounts = data.accounts.map(acc=>acc.login_id);
    
    if (!cur_acc || !accounts.includes(cur_acc)) {
      data.accounts &&
        data.accounts.length &&
        dispatch(
          addCurrentAccount(
            {login_id: data.accounts[0].login_id, platform: data.accounts[0].platform}
          )
        );
    }
    return data;
  } catch (error) {
    console.log(error)
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const fetchUserSettingDispatch = () => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    config.headers['AUTHORIZATION'] = getState().ACCtoken;
    const { data } = await axiosInstance.post("v2/user/settings/", config);
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(saveUserSettings(data));
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const getUserSettingDispatch = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get("v3/user/profile/get/", tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    if (data === "No User Profile found!") {
      dispatch(resetProfile());
    }

    dispatch(
      saveUserProfile({
        ...data.data,
        trading_accounts: data["accounts"],
      })
    );
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const LoadUser = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get("load/user/", tokenConfig(getState));
    if (data === "Please verify the Access Token!") {
      dispatch(sessionLogoutReducer());
      return false;
    }

    dispatch(getNotifications())
  } catch (error) {
    dispatch(sessionLogoutReducer());
    return;
  }
};

export const pushLead = (referalCode) => async (dispatch) => {
  try {
    const payload = {
      referal_code: referalCode,
    };
    const { data } = await axiosInstance.post("/affiliate/pushlead/", payload);
    return data;
  } catch (error) {
    return error
  }
};

export const createCommissionDispatch = (payload) => async (dispatch, getState) => {
  try {
    const { data, status } = await axiosInstance.post("/affiliate/v2/commission/", payload, tokenConfig(getState));
    if(status>399) throw(data);

    dispatch(
      saveUserCommission({
        ...data
      })
    );
    return data
  } catch (error) {
    dispatch(removeUserCommission())
    throw(error)
  }
};

export default userProfileReducer;
