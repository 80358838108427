import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  FormButton: {
    backgroundColor: "#04D9FF",
    boxShadow: "4px 4px 30px rgba(62, 175, 255, 0.25)",
    borderRadius: "30px",
    width: "404px",
    height: "50px",
    textTransform: "capitalize",
  },
}));

const Buttons = ({ children, textStyle, disabled = false, ...rest }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {children && (
          <Button
            type="submit"
            className={classes.FormButton}
            variant="contained"
            disabled={disabled}
            {...rest}
          >
            {children}
          </Button>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Buttons;
