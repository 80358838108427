import React, { useState } from "react";
import "./CompFunding.scss";
import { Select, MenuItem, Checkbox } from "@mui/material";
import AllAddressPopup from "./../../components/Popups/AllAddressPopup";
import AddNewAddressPopup from "./../../components/Popups/AddNewAddressPopup";
import CombinedPopup from "./../../components/Popups/CombinedPopup";
import axios from "axios";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../Axios";
import { ReactComponent as ActiveRadioIcon } from "../../assets/icons/NewIcons/RadiobuttonnonActive.svg";
import { ReactComponent as RadioIcon } from "../../assets/icons/NewIcons/Radiobutton.svg";

const CompFunding = () => {
  const [accountBalance, setAccountBalance] = useState("50k  Challenge");
  const [fundingEval, setFundingEval] = useState("Standard");
  const [checked, setChecked] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(0);
  const [editMode, setEditMode] = useState(0);
  // edit mode state 0: view mode, 1: edit General Info , 2: edit Billing Info, 3: edit address
  const handleSelectChange = (event) => {
    setAccountBalance(event.target.value);
  };
  const handleSelectChangeFE = (event) => {
    setFundingEval(event.target.value);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  // all popup states
  const [allAddressPop, setAllAddressPop] = useState(false);
  const [addNewAddressPop, setAddNewAddressPop] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [combinedPUHead, setCombinedPUHead] = useState("");
  const [combinedPUBody, setCombinedPUBody] = useState("");
  const [popUpType, setPopUpType] = useState("");

  const handleFinalPayment = (e) => {
    e.preventDefault();

    if (selectedRadio === 0) {
      setShowPopup(true);
      setCombinedPUHead("Oh snap!");
      setCombinedPUBody("Please select a payment method");
      setPopUpType("error");
      return;
    }
    if (checked === false) {
      setShowPopup(true);
      setCombinedPUHead("Oh snap!");
      setCombinedPUBody("Please accept the terms and conditions");
      setPopUpType("error");
      return;
    }

    setShowPopup(true);
    setCombinedPUHead("Woo hoo !");
    setCombinedPUBody("Congratulation transaction was successful");
    setPopUpType("success");
  };

  const [AprtNo, setAprtNo] = useState();
  const handleChangeAprtNo = (e) => {
    e.preventDefault();
    setAprtNo(e.target.value);
  };
  const [street, setStreet] = useState();
  const handleChangeStreet = (e) => {
    e.preventDefault();
    setStreet(e.target.value);
  };
  const [State, setState] = useState();
  const handleChangeState = (e) => {
    e.preventDefault();
    setState(e.target.value);
  };
  const [Zip, setZip] = useState();
  const handleChangeZip = (e) => {
    e.preventDefault();
    setZip(e.target.value);
  };
  const [AddCountry, setAddCountry] = useState();
  const handleChangeAddCountry = (e) => {
    e.preventDefault();
    setAddCountry(e.target.value);
  };

  const [SelectAddress, setSelectAddress] = useState();
  const handleSelectAddress = (e) => {
    setSelectAddress(e.target.value);
  };

  const ACCtoken = useSelector((state) => state.ACCtoken);

  const handleSubmitAddAddress = (e) => {
    e.preventDefault();

    setAddNewAddressPop(false);

    const AddData = {
      Apt_no: AprtNo,
      State: State,
      Street: street,
      Zip: Zip,
      Country: AddCountry,
      idToken: ACCtoken,
    };
    axiosInstance.post("/user/15/address/", AddData).catch(function (error) {
      console.log(error);
    });
  };

  return (
    <div className="CompetitionContainer PageContainer">
      <CombinedPopup
        type={popUpType}
        trigger={showPopup}
        setTrigger={setShowPopup}
        head={combinedPUHead}
        subhead={combinedPUBody}
      />
      <AllAddressPopup
        trigger={allAddressPop}
        setTrigger={setAllAddressPop}
        setTrigger3={setAddNewAddressPop}
      />
      <AddNewAddressPopup
        trigger={addNewAddressPop}
        setTrigger={setAddNewAddressPop}
        onSubmitHandle={handleSubmitAddAddress}
        AprtNo={AprtNo}
        handleChangeAprtNo={handleChangeAprtNo}
        street={street}
        handleChangeStreet={handleChangeStreet}
        State={State}
        handleChangeState={handleChangeState}
        Zip={Zip}
        handleChangeZip={handleChangeZip}
        AddCountry={AddCountry}
        handleChangeAddCountry={handleChangeAddCountry}
      />
      <div className="competitionSection-L">
        <div className="competitionBoxcon">
          <div className="competitionBoxcon-Top">
            General Information
            {editMode === 1 ? (
              <div
                onClick={() => {
                  setEditMode(0);
                }}
                className="saveChangeSCON"
              >
                Save Changes
              </div>
            ) : (
              <svg
                onClick={() => {
                  if (editMode === 1) {
                    setEditMode(0);
                  } else {
                    setEditMode(1);
                  }
                }}
                className="edtICON"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.1369 0.46967C11.9963 0.329018 11.8055 0.25 11.6066 0.25C11.4077 0.25 11.2169 0.329018 11.0763 0.46967L1.88388 9.66206C1.78965 9.75628 1.72223 9.87391 1.68856 10.0028L0.688556 13.8313C0.621266 14.0889 0.695605 14.3629 0.88388 14.5511C1.07215 14.7394 1.34614 14.8138 1.60375 14.7465L5.43218 13.7465C5.56111 13.7128 5.67874 13.6454 5.77297 13.5511L14.9654 4.35876C15.2582 4.06586 15.2582 3.59099 14.9654 3.2981L12.1369 0.46967ZM3.08843 10.5788L11.6066 2.06066L13.3744 3.82843L4.8562 12.3466L2.46344 12.9716L3.08843 10.5788Z"
                  fill="white"
                />
                <path
                  d="M1 16.25C0.585786 16.25 0.25 16.5858 0.25 17C0.25 17.4142 0.585786 17.75 1 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H1Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
          <div className="competitionBoxcon-Btm upper_details">
            <div className="DpboxEDIT-Con">
              <div className="competitionBoxcon-Btm-tcon-editcon">
                <h1>Full Name</h1>
                <div className="input_div">
                  <input type="text" className="input_sect" />
                </div>
              </div>
              <div className="competitionBoxcon-Btm-tcon-editcon">
                <h1>Email</h1>
                <div className="input_div">
                  <input type="text" className="input_sect" />
                </div>
              </div>
              <div className="competitionBoxcon-Btm-tcon-editcon">
                <h1>Contact Number</h1>
                <div className="cont_div_">
                  <div className="input_div">
                    <input type="text" className="input_sect contact_1" />
                  </div>
                  <div className="input_div">
                    <input type="text" className="input_sect contact_2" />
                  </div>
                </div>
              </div>
            </div>
            {/* (
              <>
                <div className="competitionBoxcon-Btm-tcon">
                  <h1>Full Name</h1>
                  <div className="inputSection_div">
                    <h2>David Hanson</h2>
                  </div>
                </div>
                <div className="competitionBoxcon-Btm-tcon tw">
                  <h1>Email</h1>
                  <div className="inputSection_div">
                    <h2>DavidHanson@gmail.com</h2>
                  </div>
                </div>
                <div className="competitionBoxcon-Btm-tcon thr">
                  <h1>Contact Number</h1>
                  <div className="inputSection_div">
                    <h2>+1 26596875657</h2>
                  </div>
                </div>
              </>
            )} */}
          </div>
        </div>
        <div className="competitionBoxcon dbmt">
          <div className="competitionBoxcon-Top">
            Billing Information
            <svg
              onClick={() => {
                setEditMode(3);
                setAllAddressPop(true);
              }}
              className="edtICON"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1369 0.46967C11.9963 0.329018 11.8055 0.25 11.6066 0.25C11.4077 0.25 11.2169 0.329018 11.0763 0.46967L1.88388 9.66206C1.78965 9.75628 1.72223 9.87391 1.68856 10.0028L0.688556 13.8313C0.621266 14.0889 0.695605 14.3629 0.88388 14.5511C1.07215 14.7394 1.34614 14.8138 1.60375 14.7465L5.43218 13.7465C5.56111 13.7128 5.67874 13.6454 5.77297 13.5511L14.9654 4.35876C15.2582 4.06586 15.2582 3.59099 14.9654 3.2981L12.1369 0.46967ZM3.08843 10.5788L11.6066 2.06066L13.3744 3.82843L4.8562 12.3466L2.46344 12.9716L3.08843 10.5788Z"
                fill="white"
              />
              <path
                d="M1 16.25C0.585786 16.25 0.25 16.5858 0.25 17C0.25 17.4142 0.585786 17.75 1 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H1Z"
                fill="white"
              />
            </svg>
          </div>
          {/* <div className="competitionBoxcon-Top">
            Billing Information
            {editMode === 2 ? (
              <div
                onClick={() => {
                  setEditMode(0);
                }}
                className="saveChangeSCON"
              >
                Save Changes
              </div>
            ) : (
              <svg
                onClick={() => {
                  if (editMode === 2) {
                    setEditMode(0);
                  } else {
                    setEditMode(2);
                  }
                }}
                className="edtICON"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.1369 0.46967C11.9963 0.329018 11.8055 0.25 11.6066 0.25C11.4077 0.25 11.2169 0.329018 11.0763 0.46967L1.88388 9.66206C1.78965 9.75628 1.72223 9.87391 1.68856 10.0028L0.688556 13.8313C0.621266 14.0889 0.695605 14.3629 0.88388 14.5511C1.07215 14.7394 1.34614 14.8138 1.60375 14.7465L5.43218 13.7465C5.56111 13.7128 5.67874 13.6454 5.77297 13.5511L14.9654 4.35876C15.2582 4.06586 15.2582 3.59099 14.9654 3.2981L12.1369 0.46967ZM3.08843 10.5788L11.6066 2.06066L13.3744 3.82843L4.8562 12.3466L2.46344 12.9716L3.08843 10.5788Z"
                  fill="white"
                />
                <path
                  d="M1 16.25C0.585786 16.25 0.25 16.5858 0.25 17C0.25 17.4142 0.585786 17.75 1 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H1Z"
                  fill="white"
                />
              </svg>
            )}
          </div> */}
          <div className="competitionBoxcon-Btm">
            {editMode === 2 ? (
              <div className="DpboxEDIT-Con">
                <div className="competitionBoxcon-Btm-tcon-editcon">
                  <h1>Apartment No.</h1>
                  <input type="text" />
                </div>
                <div className="competitionBoxcon-Btm-tcon-editcon">
                  <h1>Street Address</h1>
                  <input type="email" />
                </div>
                <div className="competitionBoxcon-Btm-tcon-editcon">
                  <h1>State</h1>
                  <input type="number" />
                </div>
                <div className="competitionBoxcon-Btm-tcon-editcon">
                  <h1>Zip Code</h1>
                  <input type="number" />
                </div>
                <div className="competitionBoxcon-Btm-tcon-editcon">
                  <h1>Country</h1>
                  <input type="number" />
                </div>
              </div>
            ) : (
              <div className="competitionBoxcon-Btm-tcon addressSect_Container">
                <h1>Billing Address</h1>
                <div className="billingAddress">
                  <div className="address_container">
                    {/* <h2>
                      1623 Hunningon Place, 8 Lousiville, Kentucky, 40220,
                      Canada
                    </h2> */}
                    <Select
                      className="selectAddress_div"
                      value={SelectAddress}
                      onChange={handleSelectAddress}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"address_1"}>
                        1623 Hunningon Place, 8 Lousiville, Kentucky, 40220,
                        Canada
                      </MenuItem>
                      <MenuItem value={"address_2"}>
                        17888 Hunningon Place, 8 Lousiville, Kentucky, 40220,
                        Canada
                      </MenuItem>
                      <MenuItem value={"address_3"}>
                        8777 Hunningon Place, 8 Lousiville, Kentucky, 40220,
                        Canada
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                {/* 
                <div className="competitionBoxcon-Btm-tcon thrrjr">
                  <div className="btn-cad">Change Address</div>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <div className="competitionBoxcon dbmt btm_section">
          <div className="competitionBoxcon-Top">Payment Method</div>
          <div className="competitionBoxcon-Btm rbs">
            <div
              onClick={() => {
                setSelectedRadio(1);
              }}
              className={"rbcon " + (selectedRadio === 1 && " active")}
            >
              <div className="rbtn">
                <div className="rbic">
                  {selectedRadio === 1 ? <ActiveRadioIcon /> : <RadioIcon />}
                </div>
              </div>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.2917 3.5H6.70833C4.93908 3.5 3.5 4.93967 3.5 6.70833V21.2917C3.5 23.0603 4.93908 24.5 6.70833 24.5H21.2917C23.0609 24.5 24.5 23.0603 24.5 21.2917V6.70833C24.5 4.93967 23.0609 3.5 21.2917 3.5ZM13.7083 20.4167C12.4197 20.4167 11.1218 20.0842 10.1097 19.7342C9.9925 19.6939 9.91667 19.5831 9.91667 19.4588V16.7399C9.91667 16.6355 10.0217 16.5637 10.1173 16.6052C10.7981 16.898 12.0237 17.36 13.2282 17.4866C13.7807 17.5443 14.3226 17.4837 14.6277 17.1675C14.7338 17.0573 14.8744 16.8327 14.8744 16.4926C14.8744 16.2318 14.8237 15.6211 13.3962 15.2011C12.1123 14.8412 9.625 14.0128 9.625 11.375C9.625 9.62617 10.8471 7.58333 14.2917 7.58333C15.5802 7.58333 16.8782 7.91583 17.8903 8.26583C18.0075 8.30608 18.0833 8.41692 18.0833 8.54117V11.2548C18.0833 11.3598 17.9777 11.431 17.8821 11.389C17.2043 11.0927 15.9827 10.6283 14.7782 10.5216C14.2333 10.4522 13.6407 10.5047 13.3513 10.7893C13.2574 10.8815 13.125 11.0676 13.125 11.3394C13.125 11.5628 13.132 12.0616 14.5769 12.5008C17.0975 13.2364 18.375 14.5734 18.375 16.4786C18.375 18.2957 17.1529 20.4167 13.7083 20.4167Z"
                  fill="#635BFF"
                />
              </svg>
              <p>Stripe</p>
            </div>
            <div
              onClick={() => {
                setSelectedRadio(2);
              }}
              className={"rbcon " + (selectedRadio === 2 && " active")}
            >
              <div className="rbtn">
                <div className="rbic">
                  {" "}
                  {selectedRadio === 2 ? <ActiveRadioIcon /> : <RadioIcon />}
                </div>
              </div>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7874 25.1998C13.7874 25.1998 23.3746 21.9441 23.3746 9.97141V5.66544C23.3746 5.20034 23.0145 4.81025 22.5494 4.78025C20.809 4.70523 16.6831 4.34515 13.7874 2.7998C10.8917 4.34515 6.76578 4.70523 5.02538 4.78025C4.56028 4.81025 4.2002 5.20034 4.2002 5.66544V9.97141C4.2002 21.9441 13.7874 25.1998 13.7874 25.1998Z"
                  fill="#009FE8"
                />
                <path
                  d="M17.6559 11.5464C17.5358 10.2411 16.4256 9.791 15.0003 9.65597V7.82555H13.89V9.59596C13.605 9.59596 13.2899 9.59596 12.9898 9.59596V7.81055H11.8796L11.8946 9.62596C11.6545 9.62596 9.67407 9.64097 9.67407 9.64097V10.7962H11.0844V16.6326H9.83911L9.82411 17.9679C9.82411 17.9679 11.5795 17.9529 11.8346 17.9679V19.7983H12.9298V17.9829C13.2299 17.9979 13.5299 17.9979 13.815 17.9979V19.7983H14.9252V17.9679C16.7857 17.8779 18.076 17.4128 18.256 15.6724C18.391 14.277 17.7459 13.6319 16.6956 13.3768C17.3408 13.0768 17.7459 12.5066 17.6559 11.5464ZM16.0805 15.4473C16.0655 16.8126 13.74 16.6476 13.0048 16.6476V14.217C13.74 14.217 16.0955 14.022 16.0805 15.4473ZM15.5854 12.0265C15.5704 13.2718 13.635 13.1218 13.0198 13.1068V10.9013C13.635 10.9013 15.6004 10.7212 15.5854 12.0265Z"
                  fill="white"
                />
              </svg>

              <p>Bitgo</p>
            </div>
          </div>
        </div>
      </div>
      <div className="competitionSection-R">
        <div className="competitionSection-R-Wrapper">
          <div className="headT">Order Summary</div>
          <div className="competitionSection-R-BTM">
            <div className="competitionSection-R-itm">
              <div className="itm-l">Account Balance</div>
              <div className="itm-r">
                {/* <Select
                  id="accountBalance"
                  className="feval-input-select"
                  value={accountBalance}
                  label="accountBalance"
                  onChange={handleSelectChange}
                >
                  <MenuItem key="1" value="25k  Challenge">
                    25k Challenge
                  </MenuItem>
                  <MenuItem key="2" value="50k  Challenge">
                    50k Challenge
                  </MenuItem>
                  <MenuItem key="3" value="100k  Challenge">
                    100k Challenge
                  </MenuItem>
                </Select> */}
                <p className="right_title">200k Challenge</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Competition</div>
              <div className="itm-r">
                {/* <Select
                  id="FundingEval"
                  className="feval-input-select"
                  value={fundingEval}
                  label="FundingEval"
                  onChange={handleSelectChangeFE}
                >
                  <MenuItem key="1" value="Free">
                    Free
                  </MenuItem>
                  <MenuItem key="2" value="Standard">
                    Standard
                  </MenuItem>
                  <MenuItem key="3" value="Aggressive">
                    Aggressive
                  </MenuItem>
                  <MenuItem key="4" value="Swing">
                    Swing
                  </MenuItem>
                  <MenuItem key="5" value="Instant">
                    Instant
                  </MenuItem>
                </Select> */}
                <p className="right_title">February 2022</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Transaction ID</div>
              <div className="itm-r">
                <p>17730536</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Provider</div>
              <div className="itm-r">
                <p>Alpha Capital</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Platform</div>
              <div className="itm-r">
                <p>Alpha Capital</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Price</div>
              <div className="itm-r">
                <p>$350</p>
              </div>
            </div>
            <div className="competitionSection-R-itm">
              <div className="itm-l">Discount</div>
              <div className="itm-r">
                <p>$0.00</p>
              </div>
            </div>
            {/* <div className="competitionSection-R-itm">
            <div className="itm-l">Discount</div>
            <div className="itm-r">
              <p>$350</p>
            </div>
          </div> */}
            {/* <svg
              className="seperatorSVG"
              width="364"
              height="1"
              viewBox="0 0 364 1"
              fill="#0D56EB"
              xmlns="http://www.w3.org/2000/svg"
            > */}
            {/* <line
                x1="0.5"
                y1="0.5"
                x2="363.5"
                y2="0.5"
                stroke="#0D56EB"
                strokeLinecap="round"
              /> */}
            {/* <defs> */}
            {/* <radialGradient
                  id="paint0_radial_783_9914"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(189.105 1) rotate(180) scale(189.105 24238.7)"
                >
                  <stop offset="0.42336" stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient> */}
            {/* </defs> */}
            {/* </svg> */}

            <div className="competitionSection-R-itm total">
              <div className="itm-l bl total_sect">Total</div>
              <div className="itm-r bl">
                <p>$350</p>
              </div>
            </div>
          </div>
        </div>

        <div className="competitionSection-R-under">
          <div className="competitionSection-R-under-top">
            <Checkbox
              // className='cbmi'
              sx={{
                color: "#8F8F8F",
                "&.Mui-checked": {
                  color: "#0640B9 ",
                },
              }}
              checked={checked}
              onChange={handleChange}
              // inputProps={{ 'aria-label': 'controlled' }}
            />

            <p>
              I declare that I have read and agree with <br />
              <div className="cancellation_box">
                <span className="cancellation_sect">Cancellation </span>{" "}
                <span style={{ margin: "0 5px" }}>&</span>{" "}
                <span className="cancellation_sect">Refund Policy</span>
              </div>
            </p>
          </div>
          <div className="competitionSection-R-under-btm">
            <button onClick={handleFinalPayment}>Pay $350 USD</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompFunding;
