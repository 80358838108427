import { Grid } from "@mui/material";
import React from "react";
import "../../assets/css/competition.scss";
import date from "../../assets/icons/NewIcons/CalenderCompetition.svg";
import timer from "../../assets/icons/NewIcons/clockCompetition.svg";
import Title from "./title";
import first from "../../assets/icons/NewIcons/GoldCompetition.svg";
import second from "../../assets/icons/NewIcons/SilverCompetition.svg";
import third from "../../assets/icons/NewIcons/BronzeCompetition.svg";
import Popover from "@mui/material/Popover";
import edit from "../../assets/images/Edit-alt.svg";
import Delete from "../../assets/images/delete.svg";
import CountDown from "../../utilities/Countdown";

const CompetitionBox = ({
  data,
  button,
  Edit,
  status,
  handleDelete,
  handleFormEdit,
  ButtonShow,
  handleLeaderboardClick,
  handleclick,
  isCommunity = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  console.log(data,'data')

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid lg={3.9} xl={3} className="Container-p-20 CompetitionBoxContainer">
      <div className="d-flex justify-content-between align-items-center">
        <div className="CompName">
          {/* <div className="CBHead">props.CompDate</div> */}
          <div className="CBHead mbb-10">
            {data &&
              data?.competition_name?.replace("Competition", "-")?.trim()}
            <span className="challenge_name">
              <p>200k</p> <p>Challenge</p>
            </span>
          </div>
        </div>
        <div>{/*StatFormatter(props.CompStat)*/}</div>
        {Edit === "true" && (
          <div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "white",
                cursor: "pointer",
              }}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              ...
            </div>
            <Popover
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={id}
              className="CompPopover"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <div className="PopoverComp Container-p-20">
                <div
                  className="d-flex gapp-10 mbb-20"
                  onClick={(e) => handleFormEdit(data.id, status)}
                >
                  <img src={edit} alt="" />
                  <Title four>Edit</Title>
                </div>
                <div
                  className="d-flex gapp-10 "
                  onClick={(e) => handleDelete(data && data.id)}
                >
                  <img src={Delete} alt="" />
                  <Title four>Delete</Title>
                </div>
              </div>
            </Popover>
          </div>
        )}
      </div>
      <div className="rules_wrapper">
        <div className="Date d-flex gapp-10 mbb-20">
          <img src={date} alt="" />
          <div className="date">
            {data &&
              `${new Date(data.Start_date).toDateString()} - ${new Date(
                data.End_Date
              ).toDateString()}`}
          </div>
        </div>
        <div className="Timer d-flex gapp-10 mbb-20">
          <img src={timer} alt="" />
          <div className="date" style={{ color: "#fff" }}>
            {/* <Timer startDate={startDate} /> */}
            {/*props.CountTime*/}
            <CountDown CountDate={data && data?.End_Date} />
          </div>
        </div>
        <div className="d-flex gapp-5 mbb-20 challengeContainer">
          <Title three>Challenge Accounts : </Title>
          <Title four> {(data && data?.contestants) || 0} Contestants</Title>
        </div>
        <div className="prizesWrapper">
          <div className="mbb-30 winnerTitle">
            <Title className="winnerTitle" three>
              Top 3 winner gets
            </Title>
          </div>
          <div className="mbb-10 prizesList">
            <div className="d-flex gapp-10 mbb-5">
              <img src={first} alt="" />
              <div className="d-flex">
                <Title four>1st Prize - </Title>
                <Title four>{data && data?.First_prize}</Title>
              </div>
            </div>
            <div className="d-flex gapp-10 mbb-5">
              <img src={second} alt="" />
              <div className="d-flex">
                <Title four>2nd Prize - </Title>
                <Title four>{data && data.second_prize}</Title>
              </div>
            </div>
            <div className="d-flex gapp-10 mbb-5">
              <img src={third} alt="" />
              <div className="d-flex">
                <Title four>3rd Prize - </Title>
                <Title four>{data && data.Third_prize}</Title>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="compbutton">
        {ButtonShow && (
          <button
            type="button"
            // disabled={props.ended || props.Participated}
            className="d-flex align-items-center justify-content-center gapp-10"
            onClick={handleclick}
          >
            <Title four>{button}</Title>
          </button>
        )}
      </div>
    </Grid>
  );
};

export default CompetitionBox;
