
const countryCodes = require('country-codes-list')

const myCountryNumberCodes = countryCodes.customList(
    'countryNameEn',
    '+{countryCallingCode}',
  )

// Add countries missing in the package
myCountryNumberCodes["Kosovo"] = "+383";

export default myCountryNumberCodes;