import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import "./style.scss";

const FreeTrialIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" rx="17.5" fill="#010101" />
    <g clip-path="url(#clip0_1_9335)">
      <mask
        id="mask0_1_9335"
        maskUnits="userSpaceOnUse"
        x="7"
        y="4"
        width="20"
        height="20"
      >
        <path d="M7 4H27V24H7V4Z" fill="#8f8f8f" />
      </mask>
      <g mask="url(#mask0_1_9335)">
        <path
          d="M13.5724 22.3537L14.4065 22.6426C15.3875 22.9826 16.4353 23.085 17.4636 22.9426L22.6124 22.2281H23.8317V17.3675L23.7175 17.312C21.6632 16.0883 20.5546 16.025 18.6161 16.871H15.8272C15.0763 16.871 14.4673 17.4799 14.4673 18.2309C14.4673 18.6063 14.6196 18.9462 14.8657 19.1923C15.1119 19.4384 15.4517 19.5908 15.8272 19.5908H18.6161V19.5925H14.2173L9.50031 18.2391C8.83926 18.0494 8.14605 18.4166 7.9325 19.0708C7.88953 19.2027 7.86914 19.3364 7.86914 19.4684C7.86914 19.9984 8.20117 20.4932 8.72945 20.6759L9.96644 21.1045L13.5724 22.3537Z"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.3631 21.1325V23.6094H23.8315V17.3673H26.3631V21.1325Z"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.7965 10.9388L14.7002 15.0352"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2418 10.7937C15.645 10.7937 15.9719 11.1206 15.9719 11.5238C15.9719 11.927 15.645 12.2539 15.2418 12.2539C14.8386 12.2539 14.5117 11.927 14.5117 11.5238C14.5117 11.1206 14.8386 10.7937 15.2418 10.7937Z"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2311 13.783C18.6343 13.783 18.9611 14.1098 18.9611 14.513C18.9611 14.9163 18.6343 15.2432 18.2311 15.2432C17.8279 15.2432 17.501 14.9163 17.501 14.513C17.501 14.1098 17.8279 13.783 18.2311 13.783Z"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.7084 15.0352H11.1833C10.6001 15.0352 10.1274 14.5625 10.1274 13.9793V11.7745C10.1274 11.1914 10.6001 10.7187 11.1833 10.7187H11.7084C12.2915 10.7187 12.7642 11.1914 12.7642 11.7745V13.9793C12.7642 14.5625 12.2915 15.0352 11.7084 15.0352Z"
          stroke="#8F8F8F"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_9335">
        <rect width="20" height="20" fill="white" transform="translate(7 4)" />
      </clipPath>
    </defs>
  </svg>
);

const AlphaProIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="funding-eval-card-icon"
  >
    <rect width="35" height="35" rx="17.5" fill="#010101" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0077 9.67595C18.4186 8.77469 17.0814 8.77468 16.4923 9.67595L13.5178 14.2261L11.2442 12.938C10.1051 12.2926 8.73562 13.304 9.04416 14.5627L10.5888 20.8636C10.6583 21.1474 10.9159 21.3472 11.2121 21.3472H24.2879C24.5841 21.3472 24.8417 21.1474 24.9113 20.8636L26.4558 14.5627C26.7644 13.304 25.3949 12.2927 24.2558 12.938L21.9822 14.2261L19.0077 9.67595ZM17.9296 10.3605C17.8456 10.2317 17.6545 10.2317 17.5704 10.3605L14.2657 15.4157C14.0807 15.6986 13.7032 15.789 13.4071 15.6213L10.605 14.0338C10.4423 13.9416 10.2467 14.086 10.2907 14.2658L11.7168 20.0833H23.7832L25.2093 14.2658C25.2533 14.086 25.0577 13.9416 24.895 14.0338L22.0929 15.6213C21.7968 15.789 21.4193 15.6986 21.2343 15.4157L17.9296 10.3605Z"
      fill="#8F8F8F"
    />
    <path
      d="M11.7652 22.6113C11.4111 22.6113 11.124 22.8943 11.124 23.2433C11.124 23.5923 11.4111 23.8753 11.7652 23.8753H23.7347C24.0888 23.8753 24.3759 23.5923 24.3759 23.2433C24.3759 22.8943 24.0888 22.6113 23.7347 22.6113H11.7652Z"
      fill="#8F8F8F"
    />
  </svg>
);

const ArrowLinkLight = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 9H3.5M14.5 9L12 12M14.5 9L12 6"
      stroke="white"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowLinkDark = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 9H3.5M14.5 9L12 12M14.5 9L12 6"
      stroke="#04D9FF"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CloseIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.9165 2.91699L11.0832 11.0837M2.91652 11.0837L6.99985 7.00033L11.0832 2.91699"
      stroke="#FD504F"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

const CheckIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6668 4.08301L8.52227 7.85648C7.27619 9.35177 6.65315 10.0994 5.8335 10.0994C5.01384 10.0994 4.3908 9.35177 3.14472 7.85648L2.3335 6.88301"
      stroke="#0CCE6B"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

const getChallengeString = (itemName) => {
  const match = itemName.match(/(\d+ ?k)/i);
  const challengeAmount = match ? match[1].toUpperCase() : itemName;
  return `${challengeAmount}`;
};

const Challenges = ({ data, type }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("=================data===================");
    console.log(data);
    console.log("====================================");
  }, [data]);
  return (
    <Grid
      className="row-2 challenge"
      maxWidth="100% !important"
      item
      xs={12}
      md={4}
      width="100%"
    >
      <div className="card Bgrey" width="100%">
        {type === "dynamic" ? (
          <div className="fundingBOX">
            <Grid
              container
              item
              xs={12}
              className="challenge-list"
              rowGap={2}
              flexWrap="wrap"
              gap="20px"
              // justifyContent="space-between"
            >
              {data?.reverse().map((item) => {
                return (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      className="card challenge-item"
                      sx={{ maxWidth: "369px !important", minWidth: "300px" }}

                      // style={{ gap: "20px !important" }}
                    >
                      <Grid item xs={12}>
                        <div className="d-flex aa-header">
                          <div
                            className="challenge-name"
                            style={{ fontSize: "18px", fontWeight: "700" }}
                          >
                            <span>{getChallengeString(item.name)}</span>
                            <p>Challenge</p>
                          </div>
                          {/* <div className="toolbar"></div> */}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <ul className="obj">
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              {" "}
                              1:{item.Leverage} <p>Leverage </p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              $ {item.Evaluation_Phase_1}{" "}
                              <p>Evaluation Phase 1</p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              ${item.Evaluation_Phase_2}{" "}
                              <p> Evaluation Phase 2</p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              {" "}
                              ${item.Max_Loss} <p>Max Loss</p>{" "}
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              {" "}
                              ${item.Max_Daily_Loss} <p>Max Daily Loss</p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              {item.Profit_Split}% <p>Profit Split</p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              {" "}
                              {item.Bi_Weekly_Profit_Split ? "Yes" : "No"}{" "}
                              <p>Bi Weekly Profit Split </p>
                            </span>
                          </li>
                          <li>
                            <span>{CheckIcon}</span>{" "}
                            <span>
                              ${parseInt(item.Max_Capital_Growth / 1000000)}{" "}
                              Million <p> Max Capital Growth</p>
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Button
                        className="payment-box-button"
                        variant="contained"
                        // endIcon={ArrowLinkLight}
                        onClick={(e) => {
                          navigate("/FundingEvaluationPayment/" + item.id);
                        }}
                        style={{}}
                      >
                        Buy Now
                      </Button>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </div>
        ) : (
          <>
            <Grid item xs={12} width="100%">
              <div className="d-flex aa-header">
                <div className="text-gray">CHALLENGES</div>
                <div className="toolbar"></div>
              </div>
              <Grid
                container
                item
                xs={12}
                className="challenge-list"
                rowGap={2}
                flexWrap="nowrap"
                gap="20px"
                justifyContent="space-between"
              >
                <Grid
                  container
                  item
                  xs={12}
                  className="card challenge-item"
                  sx={{ maxWidth: "369px !important" }}

                  // style={{ gap: "20px !important" }}
                >
                  <Grid item xs={12}>
                    <div className="d-flex aa-header">
                      <div
                        className="challenge-name"
                        style={{ fontSize: "18px", fontWeight: "700" }}
                      >
                        {AlphaProIcon} Alpha Pro 100k
                      </div>
                      <div className="toolbar"></div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ul className="obj">
                      <li>
                        <span>{CheckIcon}</span> <span>Leverage : 100</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 1 : $8,000(8%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 2 : $5,000(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Loss : $10,000(10%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Daily Loss $5,000(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span> <span>Profit Split : 80%</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Bi Weekly Profit Split : Yes</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Capital Growth : $2 Million</span>
                      </li>
                    </ul>
                  </Grid>
                  <Button
                    variant="contained"
                    // endIcon={ArrowLinkLight}
                    onClick={(e) => {
                      navigate("/FundingEvaluationPayment/4");
                    }}
                    style={{
                      background: "#010101",
                      borderRadius: "5px",
                      border: "1px solid #8F8F8F",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "white",
                      padding: "10px 0px",
                    }}
                  >
                    Buy Now
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className="card challenge-item blBorder"
                  sx={{ maxWidth: "369px !important" }}

                  // style={{ gap: "20px !important" }}
                >
                  <Grid item xs={12}>
                    <div className="d-flex aa-header">
                      <div
                        className="challenge-name"
                        style={{ fontSize: "18px", fontWeight: "700" }}
                      >
                        {AlphaProIcon} Alpha Pro 200k
                      </div>
                      <div className="toolbar"></div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ul className="obj">
                      <li>
                        <span>{CheckIcon}</span> <span>Leverage : 100</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 1 : $16,000(8%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 1 : $10,000(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Loss : $20,000(10%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Daily Loss $10,000(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span> <span>Profit Split : 80%</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Bi Weekly Profit Split : Yes</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Capital Growth : $2 Million</span>
                      </li>
                    </ul>
                  </Grid>
                  <Button
                    variant="contained"
                    // endIcon={ArrowLinkLight}
                    onClick={(e) => {
                      navigate("/FundingEvaluationPayment/4");
                    }}
                    style={{
                      background: "#010101",
                      borderRadius: "5px",
                      border: "1px solid #8F8F8F",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "white",
                      padding: "10px 0px",
                    }}
                  >
                    Buy Now
                  </Button>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className="card challenge-item"
                  sx={{ maxWidth: "369px !important" }}

                  // style={{ gap: "20px !important" }}
                >
                  <Grid item xs={12}>
                    <div className="d-flex aa-header">
                      <div
                        className="challenge-name"
                        style={{ fontSize: "18px", fontWeight: "700" }}
                      >
                        {AlphaProIcon} Alpha Pro 50k
                      </div>
                      <div className="toolbar"></div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ul className="obj">
                      <li>
                        <span>{CheckIcon}</span> <span>Leverage : 100</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 1 : $4,000(8%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Evaluation Phase 1 : $2,500(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Loss : $5,000(10%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Daily Loss $2,500(5%)</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span> <span>Profit Split : 80%</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Bi Weekly Profit Split : Yes</span>
                      </li>
                      <li>
                        <span>{CheckIcon}</span>{" "}
                        <span>Max Capital Growth : $2 Million</span>
                      </li>
                    </ul>
                  </Grid>
                  <Button
                    variant="contained"
                    // endIcon={ArrowLinkLight}
                    onClick={(e) => {
                      navigate("/FundingEvaluationPayment/4");
                    }}
                    style={{
                      background: "#010101",
                      borderRadius: "5px",
                      border: "1px solid #8F8F8F",
                      textTransform: "capitalize",
                      width: "100%",
                      color: "white",
                      padding: "10px 0px",
                    }}
                  >
                    Buy Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </Grid>
  );
};

export default Challenges;
