import {axiosInstance} from "../../Axios";
import logoutReducer from "./logout";
import { tokenConfig } from "./tokenReducer";

export const accountCreation = (data) => {
  return {
    type: "ACCOUNT_CREATION",
    payload: data,
  };
};

export const ButtonDisable = (data) => {
  return {
    type: "BUTTON_DISABLE",
    payload: data,
  };
};

export const CompetitionLeaderboard = (data) => {
  return {
    type: "COMPETITION_LEADERBOARD",
    payload: data,
  };
};

export const CompLeadId = (data) => {
  return {
    type: "COMP_ID",
    payload: data,
  };
};

const initialState = {
  isAccountCreating: false,
  isDisable: false,
  CompetitionLeaderboard: [],
  compID: false,
};

const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACCOUNT_CREATION":
      return {
        ...state,
        isAccountCreating: action.payload,
      };
    case "COMP_ID":
      return {
        ...state,
        compID: action.payload,
      };
    case "BUTTON_DISABLE":
      return {
        ...state,
        isDisable: action.payload,
      };
    case "COMPETITION_LEADERBOARD":
      return {
        ...state,
        CompetitionLeaderboard: action.payload,
      };
    default:
      return state;
  }
};

export const registerCompetition =
  (competition_id, {name, pwd, competition_password}) =>
  async (dispatch, getState) => {
    // dispatch(accountCreation(true))
    try {
      dispatch(accountCreation(true));
      const payload = {
        competition_id,
        name,
        pwd,
        ...(competition_password && { competition_password }),
      };
      const {data, status} = await axiosInstance.post(
        "v2/competition/participate/",
        payload,
        tokenConfig(getState)
      );
      if (status > 399) throw data;
      dispatch(accountCreation(data));
      return {};
    } catch (error) {
      dispatch(accountCreation(false));
      if (error.response.data.message) {
        throw {status: false, message: error.response.data.message};
        // throw new Error(error.response.data.message);
      }
      // dispatch(toggleLoading(false));
      // dispatch(saveError(error));
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      return error.message;
    }
  };

export const dispatchButtonDisable = () => async (dispatch, getState) => {
  dispatch(ButtonDisable(!getState().sidebar.accountCreate.isDisable));
};
export const dispatchCompetitionLeaderboard =
  (id) => async (dispatch, getState) => {
    try {
      const {data} = await axiosInstance.post(
        `/leaderboard-competiton/${id}/`,
        tokenConfig(getState)
      );
      dispatch(CompetitionLeaderboard(data));
      dispatch(CompLeadId(id));
    } catch (error) {}
  };

export const fetchLeaderboard =
  (id = "") =>
  async (dispatch, getState) => {
    try {
      const idToken = await getState().ACCtoken;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        params: {competition_id: id},
      };
      config.headers["AUTHORIZATION"] = idToken;
      const {data} = await axiosInstance.get(
        "v2/competition/leaderboard/",
        config
      );
      if (data === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }

      return data;
    } catch (error) {
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      throw error.response.data;
    }
  };

export const fetchUserCompetition = (id) => async (dispatch, getState) => {
  try {
    const idToken = await getState().ACCtoken;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {user_competition_id: id},
    };
    config.headers["AUTHORIZATION"] = idToken;

    const {data} = await axiosInstance.get(
      "v2/competition/leaderboard/user-stats/",
      config
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    return data;
  } catch (error) {
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    throw error.response.data;
  }
};

export default competitionReducer;
