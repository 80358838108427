import { useDispatch } from "react-redux";
import { axiosInstance } from "../../Axios";
import { setChartLoading, setLoading } from "./loader.js";
import logoutReducer, { sessionLogoutReducer } from "./logout.js";
import { tokenConfig } from "./tokenReducer";
export const TradingjournalData = (data, account_id) => {
  return {
    type: "TRADING_DATA",
    payload: data,
    account_id,
  };
};

export const toggleLoading = (data) => {
  return {
    type: "UPDATE_LOADING",
    payload: data,
  };
};
export const filterAccount = (data) => {
  console.log(data, "activeData");
  return {
    type: "FILTER_ACCOUNTS",
    payload: data === "Alpha pro" ? "Evaluation" : data,
  };
};

export const filterActiveAc = (data) => {
  return {
    type: "FILTER_ACTIVE_AC",
    payload: data,
  };
};

export const DashboardAcHeading = (data) => {
  return {
    type: "DASHBOARD_AC_HEADING",
    payload: data,
  };
};

export const AccountDetails = (data) => {
  return {
    type: "ACCOUNT_DETAILS",
    payload: data,
  };
};

export const ClearAccountDetails = (data) => {
  return {
    type: "CLEAR_ACCOUNT_DETAILS",
  };
};

export const saveAdvertise = (data) => {
  return {
    type: "SAVE_ADVERTISE",
    payload: data,
  };
};

export const saveCompetition = (data) => {
  return {
    type: "SAVE_COMPETITION",
    payload: data,
  };
};

export const saveLeaderboard = (data) => {
  return {
    type: "SAVE_LEADERBOARD",
    payload: data,
  };
};

export const saveAccountMetrics = (data, account_id) => {
  return {
    type: "SAVE_METRICS",
    payload: data,
    account_id,
  };
};

export const saveEquityHistory = (data, account_id) => {
  return {
    type: "SAVE_EQUITY_HISTORY",
    payload: data,
    account_id,
  };
};

export const saveBalanceHistory = (data, account_id) => {
  return {
    type: "SAVE_BALANCE_HISTORY",
    payload: data,
    account_id,
  };
};

export const savePerformanceChart = (data) => {
  return {
    type: "SAVE_PERFORMANCE",
    payload: data,
  };
};

export const dashboardCalc = (data, account_id) => {
  return {
    type: "DASHBOARD_CALC",
    payload: data,
    account_id,
  };
};

export const saveError = (data) => {
  return {
    type: "SAVE_ERROR",
    payload: data,
  };
};

export const removeDashboard = () => {
  return {
    type: "REMOVE_DASHBOARD",
  };
};

export const traderJournalNew = (data) => {
  return {
    type: "TRADE_JOURNAL",
    payload: data,
  };
};

export const CleartraderJournalNew = (data) => {
  return {
    type: "CLEAR_TRADE_JOURNAL",
  };
};

export const ClearPerformanceChart = (data) => {
  return {
    type: "CLEAR_PERFORMANCE_CHART",
  };
};

export const ClearAccountInsights = (data) => {
  return {
    type: "CLEAR_ACCOUNT_INSIGHT",
  };
};

export const AccountInsights = (data) => {
  return {
    type: "ACCOUNT_INSIGHTS",
    payload: data,
  };
};

export const NewDashboard = (data) => {
  return {
    type: "SET_NEW_DASHBOARD",
    payload: data,
  };
};
export const SetTradesData = (data) => {
  return {
    type: "SET_TRADESDATA",
    payload: data,
  };
};
export const setAccountType = (data) => {
  return {
    type: "SET_ACCOUNT_TYPE",
    payload: data,
  };
};
export const setActiveInactive = (data) => {
  return {
    type: "SET_ACTIVE_INACTIVE",
    payload: data,
  };
};
export const setAccountTypeForDash = (data) => {
  return {
    type: "SET_ACCOUNT_TYPE_FOR_DASH",
    payload: data,
  };
};
export const setActiveInactiveForDash = (data) => {
  return {
    type: "SET_ACTIVE_INACTIVE_FOR_DASH",
    payload: data,
  };
};
export const setProgramObjectives = (data) => {
  return {
    type: "SET_PROGRAM_OBJECTIVES",
    payload: data,
  };
};

const initialState = {
  loading: false,
  success: null,
  error: null,
  advertisementList: [],
  leaderboardList: [],
  competitionList: [],
  account_metrics: [],
  dashboardcalcList: [],
  TradeJournal: [],
  SavePerformance: [],
  AccDetails: [],
  accountInsights: [],
  newdashboard: false,
  tradesData: [],
  filteredbyaccount: null,
  filteredActiveAc: "active",
  isActiveInactive: null,
  accountType: null,
  isActiveInactiveForDash: null,
  accountTypeForDash: null,
  backToMetrics: false,
  programObjectives: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_INACTIVE":
      return {
        ...state,
        isActiveInactive: action.payload,
      };
    case "SET_ACCOUNT_TYPE":
      return {
        ...state,
        accountType: action.payload,
      };
    case "SET_ACTIVE_INACTIVE_FOR_DASH":
      return {
        ...state,
        isActiveInactiveForDash: action.payload,
      };
    case "SET_ACCOUNT_TYPE_FOR_DASH":
      return {
        ...state,
        accountTypeForDash: action.payload,
      };
    case "UPDATE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "FILTER_ACCOUNTS":
      return {
        ...state,
        filteredbyaccount: action.payload,
      };
    case "FILTER_ACTIVE_AC":
      return {
        ...state,
        filteredActiveAc: action.payload,
      };

    case "DASHBOARD_AC_HEADING":
      return {
        ...state,
        dashboardAcHeading: action.payload,
      };

    case "TRADE_JOURNAL":
      return {
        ...state,
        TradeJournal: action.payload,
      };
    case "ACCOUNT_INSIGHTS":
      return {
        ...state,
        accountInsights: action.payload,
      };
    case "ACCOUNT_DETAILS":
      return {
        ...state,
        AccDetails: action.payload,
      };
    case "CLEAR_ACCOUNT_DETAILS":
      return {
        ...state,
        AccDetails: null,
      };
    case "SAVE_PERFORMANCE":
      return {
        ...state,
        SavePerformance: action.payload,
      };
    case "SAVE_ADVERTISE":
      return {
        ...state,
        advertisementList: action.payload,
      };
    case "SAVE_LEADERBOARD":
      return {
        ...state,
        leaderboardList: action.payload,
      };
    case "SAVE_COMPETITION":
      return {
        ...state,
        competitionList: action.payload,
      };
    case "SAVE_METRICS":
      const { account_id, payload } = action;
      const account_metrics = {
        ...state.account_metrics,
      };
      account_metrics[account_id] = payload;
      return {
        ...state,
        account_metrics,
      };
    case "CLEAR_TRADE_JOURNAL":
      return {
        ...state,
        TradeJournal: null,
      };
    case "CLEAR_PERFORMANCE_CHART":
      return {
        ...state,
        SavePerformance: [],
      };
    case "CLEAR_ACCOUNT_INSIGHT":
      return {
        ...state,
        accountInsights: null,
      };
    case "TRADING_DATA":
      const trading_history = {
        ...state.trading_history,
      };
      trading_history[action.account_id] = action.payload;
      return {
        ...state,
        trading_history,
      };
    case "SAVE_ACCOUNT_INFO":
      return {
        ...state,
        accountInfo: action.payload,
      };

    case "SAVE_EQUITY_HISTORY":
      const equity_history = {
        ...state.equity_history,
      };
      equity_history[action.account_id] = action.payload;
      return {
        ...state,
        equity_history,
      };
    case "SAVE_BALANCE_HISTORY":
      const balance_history = {
        ...state.balance_history,
      };
      balance_history[action.account_id] = action.payload;
      return {
        ...state,
        balance_history,
      };
    case "DASHBOARD_CALC":
      const dashboardcalcList = {
        ...state.dashboardcalcList,
      };
      dashboardcalcList[action.account_id] = action.payload;
      return {
        ...state,
        dashboardcalcList,
      };
    case "SAVE_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "REMOVE_DASHBOARD":
      return {};
    case "SET_NEW_DASHBOARD":
      return {
        ...state,
        newdashboard: action.payload,
      };
    case "SET_TRADESDATA":
      return { ...state, tradesData: action.payload };

    case "SET_PROGRAM_OBJECTIVES":
      return { ...state, programObjectives: action.payload };

    default:
      return state;
  }
};

export const fetchAccountObjectives =
  (login_id, platform) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { data, status } = await axiosInstance.get(
        `v2/account/objectives/${login_id}/?platform=${platform}`,
        tokenConfig(getState)
      );
      dispatch(setLoading(false));
      if (status > 400) {
        if (data === "Please verify the Access Token!") {
          dispatch(logoutReducer());
          return;
        }
      }
      return data;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(saveError(error));
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      throw error;
    }
  };

// done
export const fetchAccountInsights =
  (login_id, platform) => async (dispatch, getState) => {
    try {
      const { data, status } = await axiosInstance.get(
        `v2/account-insights/${login_id}/?platform=${platform}`,
        tokenConfig(getState)
      );
      if (status > 400) {
        if (data === "Please verify the Access Token!") {
          dispatch(logoutReducer());
          return;
        }
      }
      dispatch(ClearAccountInsights());
      dispatch(AccountInsights(data));
    } catch (error) {
      dispatch(saveError(error));
      dispatch(ClearAccountInsights());
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
    }
  };

// done
export const fetchAccountDetails =
  (login_id, platform) => async (dispatch, getState) => {
    try {
      const { data, status } = await axiosInstance.get(
        `v2/account-details/${login_id}/?platform=${platform}`,
        tokenConfig(getState)
      );
      if (status > 400) {
        if (data === "Please verify the Access Token!") {
          dispatch(logoutReducer());
          return;
        }
      }
      dispatch(ClearAccountDetails());
      dispatch(AccountDetails(data));
    } catch (error) {
      dispatch(ClearAccountDetails());
      dispatch(saveError(error));
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
    }
  };

export const fetchPerformanceChart =
  (login_id, platform) => async (dispatch, getState) => {
    dispatch(setChartLoading(true));
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    config.headers["AUTHORIZATION"] = getState().ACCtoken;
    try {
      const { data } = await axiosInstance.get(
        `/performance-chart/v2/${login_id}/?platform=${platform}`,
        tokenConfig(getState)
      );
      dispatch(ClearPerformanceChart());
      dispatch(savePerformanceChart(data));
    } catch (error) {
      dispatch(ClearPerformanceChart());
      dispatch(saveError(error));
    }
    dispatch(setChartLoading(false));
  };

export const fetchTraderJournal =
  (login_id, platform) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const { data } = await axiosInstance.get(
        `v2/account-metrics/${login_id}/?platform=${platform}`,
        tokenConfig(getState)
      );
      if (data === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      dispatch(setLoading(false));
      dispatch(CleartraderJournalNew());
      dispatch(traderJournalNew(data));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(CleartraderJournalNew());
      dispatch(saveError(error));
      if (error === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
    }
  };

export const fetchAdvertisementDispatch = () => async (dispatch, getState) => {
  try {
    const idToken = await getState().ACCtoken;
    dispatch(toggleLoading(true));
    const payload = {
      idToken,
    };
    const { data } = await axiosInstance.post("/sda/get/", payload);
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(saveAdvertise(data));
    dispatch(toggleLoading(false));
  } catch (error) {
    dispatch(toggleLoading(false));
    dispatch(saveError(error));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const fetchCompetitionDispatch = () => async (dispatch, getState) => {
  try {
    dispatch(toggleLoading(true));
    const { data } = await axiosInstance.get(
      "v2/get/competition/",
      tokenConfig(getState)
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(saveCompetition(data));
    dispatch(toggleLoading(false));
  } catch (error) {
    dispatch(toggleLoading(false));
    dispatch(saveError(error));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const fetchLeaderboardDispatch = () => async (dispatch, getState) => {
  try {
    const idToken = await getState().ACCtoken;
    dispatch(toggleLoading(true));
    const payload = {
      idToken,
    };
    const { data } = await axiosInstance.post(`/leaderboards/`, payload);
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
    dispatch(saveLeaderboard(data.data));
    dispatch(toggleLoading(false));
  } catch (error) {
    dispatch(toggleLoading(false));
    dispatch(saveError(error));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }
  }
};

export const fetchNewDashboard = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axiosInstance.get(
      `v2/new-dashboard/`,
      tokenConfig(getState)
    );

    dispatch(
      fetchPerformanceChart(
        data?.accounts[0]?.login_id,
        data?.accounts[0]?.platform?.toLowerCase()
      )
    );
    if (data === "Please verify the Access Token!") {
      dispatch(logoutReducer());
      return;
    }

    setLoading(false);
    dispatch(NewDashboard(data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());

      return;
    }
  }
};

export const getDigiSignerSigningURL = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get(
      `/dg/get_signing_url/`,
      tokenConfig(getState)
    );
    return data;
  } catch (error) {
    if (error.response && error.response?.detail === "invalid-auth-token") {
      dispatch(sessionLogoutReducer());
      return;
    }
    throw error;
  }
};

export const getDigiSignerStatus = () => async (dispatch, getState) => {
  try {
    const { data } = await axiosInstance.get(
      `/dg/get_sign_status/`,
      tokenConfig(getState)
    );
    return data;
  } catch (error) {
    if (error.response && error.response?.detail === "invalid-auth-token") {
      dispatch(sessionLogoutReducer());
      return;
    }
    throw error;
  }
};

export const sendAccountInfo = (requestData) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    const { data, status } = await axiosInstance.post(
      `v3/accoun-info/`,
      requestData,
      tokenConfig(getState)
    );

    if (status >= 400) {
      if (data === "Please verify the Access Token!") {
        dispatch(logoutReducer());
        return;
      }
      dispatch(saveError("An error occurred!"));
      return;
    }

    // dispatch(saveAccountInfo(data));
  } catch (error) {
    dispatch(saveError(error));
    if (error === "Please verify the Access Token!") {
      dispatch(logoutReducer());
    }
  }
  dispatch(setLoading(false)); // End loading state
};

export default dashboardReducer;
