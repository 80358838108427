const SidebarMain = (state = 1, action) => {
  switch (action.type) {
    case 'SETSIDEBARMAIN':
      return (state = action.payload)
    default:
      return state
  }
}
const SidebarSub = (state = 0, action) => {
  switch (action.type) {
    case 'SETSIDEBARSUB':
      return (state = action.payload)
    default:
      return state
  }
}

export { SidebarMain, SidebarSub }
