import React, { useState, useEffect } from "react";
import AwardFirst from "../assets/images/AwardFirst.svg";
import BootstrapTable from "react-bootstrap-table-next";
import "../assets/css/Leaderboard.scss";
import * as ReactBootstrap from "react-bootstrap";
import { Grid } from "@mui/material";
import StatCard from "../components/Dashboard/StatCard";
import compicon from "../assets/images/competetionicon.svg";
import fundingicon from "../assets/images/fundingicon.svg";
import lossicon from "../assets/images/lossicon.svg";
import winicon from "../assets/images/winicon.svg";
import CompetetionBanner from "../components/Dashboard/CompetetionBanner";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import caret from "../assets/images/charm_tick.svg";
import Title from "../components/ReusableComponents/title";
import line from "../assets/images/Line.svg";
import Calendar from "react-calendar";
import {
  CalendarComponent,
  ChangedEventArgs,
} from "@syncfusion/ej2-react-calendars";
import TableComponent from "../components/Dashboard/Leaderboard";
import LeaderboardMBox from "../components/ReusableComponents/LeaderboardMBox";
import HeadAndDesc from "../components/ReusableComponents/HeadAndDesc";
import {
  fetchAdvertisementDispatch,
  fetchLeaderboardDispatch,
} from "../store/reducers/dashboardReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const renderLeaderBoard = (leaderboard, index) => {
  return (
    <tr key={index}>
      <td>{leaderboard.id}</td>
      <td>{leaderboard.award}</td>
      <td className="LeaderBoardName">{leaderboard.Name}</td>
      <td>{leaderboard.Date}</td>
      <td>{leaderboard.AccoountSize}</td>
      <td>{leaderboard.Challenge}</td>
      <td>{leaderboard.Prizewon}</td>
    </tr>
  );
};

const Leaderboard = (Participated) => {
  const dispatch = useDispatch();
  const { advertisementList, leaderboardList } = useSelector(
    (state) => state.dashboard
  );
  // const { Published } = advertisementList;
  useEffect(() => {
    dispatch(fetchAdvertisementDispatch());
    dispatch(fetchLeaderboardDispatch());
  }, [dispatch]);

  const [showChall, setshowChall] = useState(false);
  const [showCalender, setshowshowCalender] = useState(false);
  const [List, setList] = useState([]);
  const [ListAll, setListAll] = useState(true);
  const [Tick, setTick] = useState(false);
  const [tickAll, setTickAll] = useState(true);
  const [tick25, setTick25] = useState(false);
  const [tick50, setTick50] = useState(false);
  const [tick100, setTick100] = useState(false);
  const [tick200, setTick200] = useState(false);

  const ShowChallenge = () => {
    setshowChall(!showChall);
    setshowshowCalender(false);
  };

  const ShowCalender = () => {
    setshowshowCalender(!showCalender);
    setshowChall(false);
  };

  const handleChangeAll = () => {
    setTickAll(!tickAll);
    setTick25(false);
    setTick50(false);
    setTick100(false);
    setTick200(false);
    setListAll(true);
  };

  // const handleChange25K = (e) => {
  //   setListAll(false);
  //   setTickAll(false);
  //   setTick25(!tick25);
  //   let varone = "$25K";
  //   setList((oldArray) => [...oldArray, varone]);
  //   if (tick25) {
  //     setList((oldArray) => oldArray.filter((item) => item !== varone));
  //   }
  // };

  // const handleChange50K = (e) => {
  //   setListAll(false);
  //   setTick50(!tick50);
  //   let vartwo = "$50K";
  //   setList((oldArray) => [...oldArray, vartwo]);
  //   if (tick50) {
  //     setList((oldArray) => oldArray.filter((item) => item !== vartwo));
  //   }
  // };

  // const handleChange100K = (e) => {
  //   setListAll(false);
  //   setTick100(!tick100);
  //   let varthree = "$100K";
  //   setList((oldArray) => [...oldArray, varthree]);
  //   if (tick100) {
  //     setList((oldArray) => oldArray.filter((item) => item !== varthree));
  //   }
  // };

  // const handleChange200K = (e) => {
  //   setListAll(false);
  //   setTick200(!tick200);
  //   let varfour = "$200K";
  //   setList((oldArray) => [...oldArray, varfour]);
  //   if (tick200) {
  //     setList((oldArray) => oldArray.filter((item) => item !== varfour));
  //   }
  // };

  const handleChangeList = (e) => {
    e.preventDefault();
    setListAll(false);
    const clicked = e.currentTarget.getAttribute("value");
    setList((oldArray) => [...oldArray, clicked]);
    if (Tick) {
      setList((oldArray) => oldArray.filter((item) => item !== clicked));
    }
  };

  const ChallengePopup = () => {
    return (
      <div className="DropChall">
        <div
          className="DropOpt d-flex justify-content-between"
          onClick={handleChangeAll}
        >
          <Title four>All</Title>
          <div>
            <div>{tickAll === true ? <img src={caret} alt="" /> : ""}</div>
          </div>
        </div>
        <img src={line} alt="" />

        {leaderboardList &&
          leaderboardList.map((data, i) => {
            return (
              <div>
                <div
                  className="DropOpt d-flex justify-content-between"
                  onClick={handleChangeList}
                  value={data.Challenge}
                >
                  <Title four>{data.Challenge}</Title>
                  <div>
                    <div>{Tick === true ? <img src={caret} alt="" /> : ""}</div>
                  </div>
                </div>
                <img src={line} alt="" />
              </div>
            );
          })}
        {/* <div
          className="DropOpt d-flex justify-content-between"
          onClick={handleChange25K}
        >
          <Title four>25K Challenge</Title>
          <div>
            <div>{tick25 === true ? <img src={caret} alt="" /> : ""}</div>
          </div>
        </div>
        <img src={line} alt="" /> */}
        {/* <div
          className="DropOpt d-flex justify-content-between"
          onClick={handleChange50K}
        >
          <Title four>50K Challenge</Title>
          <div>
            <div>{tick50 === true ? <img src={caret} alt="" /> : ""}</div>
          </div>
        </div>
        <img src={line} alt="" />
        <div
          className="DropOpt d-flex justify-content-between"
          onClick={handleChange100K}
        >
          <Title four>100K Challenge</Title>
          <div>
            <div>{tick100 === true ? <img src={caret} alt="" /> : ""}</div>
          </div>
        </div>
        <img src={line} alt="" />
        <div
          className="DropOpt d-flex justify-content-between"
          onClick={handleChange200K}
        >
          <Title four>200K Challenge</Title>
          <div>
            <div>{tick200 === true ? <img src={caret} alt="" /> : ""}</div>
          </div>
        </div> */}
      </div>
    );
  };

  const [date, setDate] = useState(new Date());
  const handleChangeCalender = (e) => {
    ListAll(false);
    List(false);
    var doptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    let var1 = e.target.value.toLocaleDateString("en-GB", doptions);
    setDate(var1);
    alert(var1);
  };

  const TableCalender = () => {
    return (
      <div className="TableCalenderBox">
        {/* <Calendar onChange={handleChangeCalender} value={date}/> */}
        <CalendarComponent
          id="calendar"
          value={date}
          onChange={handleChangeCalender}
        ></CalendarComponent>
      </div>
    );
  };

  const handleChangeTest = (e) => {
    let data = e.currentTarget.textContent.split(",").join("");
    alert(data);
  };

  const renderLeaderBoard = (leaderboard, index) => {
    {
      if (List.includes(leaderboard.Challenge) || ListAll === true) {
        return (
          <tr key={index}>
            <td>{leaderboard.id}</td>
            <td>{leaderboard.award}</td>
            <td className="LeaderBoardName">
              {leaderboard.Name &&
                Array.isArray(leaderboard.Name) &&
                leaderboard.Name[0] &&
                leaderboard.Name[0].Email}
            </td>
            <td onClick={handleChangeTest}>
              {moment(leaderboard.Date).format("Do MMM yy")}
            </td>
            <td>{leaderboard["Account Size"]}</td>
            <td>{leaderboard.Challenge}</td>
            <td>{leaderboard["Price won"]}</td>
          </tr>
        );
      }
    }
  };

  // {
  //   id: 1,
  //   award: <img src={AwardFirst} alt="" />,
  //   Name: "Snow",
  //   Date: "11 Jan, 2022",
  //   AccoountSize: "$100,000",
  //   Challenge: "$200K",
  //   Prizewon: "Macbook Pro",
  // },

  return (
    <div className="PageContainer LeaderboardPage mtt-20">
      <div className="LeaderboardMobileBox">
        <div className="d-flex gapp-10 mbb-20">
          <div className="Filter">
            Date
            <span style={{ cursor: "pointer" }} onClick={ShowCalender}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.47473 6.22489C7.71881 5.98081 8.11454 5.98081 8.35861 6.22489L11.6919 9.55822C11.936 9.8023 11.936 10.198 11.6919 10.4421L8.35861 13.7754C8.11454 14.0195 7.71881 14.0195 7.47473 13.7754C7.23065 13.5314 7.23065 13.1356 7.47473 12.8916L10.3661 10.0002L7.47473 7.10877C7.23065 6.86469 7.23065 6.46896 7.47473 6.22489Z"
                  fill="#ffffff"
                />
              </svg>
            </span>
          </div>
          <div className="Filter">
            Challenge
            <span style={{ cursor: "pointer" }} onClick={ShowChallenge}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.47473 6.22489C7.71881 5.98081 8.11454 5.98081 8.35861 6.22489L11.6919 9.55822C11.936 9.8023 11.936 10.198 11.6919 10.4421L8.35861 13.7754C8.11454 14.0195 7.71881 14.0195 7.47473 13.7754C7.23065 13.5314 7.23065 13.1356 7.47473 12.8916L10.3661 10.0002L7.47473 7.10877C7.23065 6.86469 7.23065 6.46896 7.47473 6.22489Z"
                  fill="#ffffff"
                />
              </svg>
            </span>
          </div>
        </div>

        <div className="ShowChallengePopup">
          {showChall && <ChallengePopup />}
        </div>

        <div className="ShowChallengePopup ShowDate">
          {showCalender && <TableCalender />}
        </div>

        {leaderboardList &&
          leaderboardList.map((data, i) => {
            return (
              <div className="LeaderboardMBox">
                <Title three>Name</Title>
                <div className="LBMFirst">
                  <div className="LMBName">
                    <div>{i}.</div>
                    <div>
                      {data.Name &&
                        Array.isArray(data.Name) &&
                        data.Name[0] &&
                        data.Name[0].Email}
                    </div>
                  </div>
                  <img src={AwardFirst} alt="" />
                  <Title three>{data.Date}</Title>
                </div>
                <div className="LBMSecond">
                  <HeadAndDesc
                    HeadTitle="Account Size"
                    SubTitle={data["Account Size"]}
                  />
                  <HeadAndDesc
                    HeadTitle="Challenge"
                    SubTitle={data.Challenge}
                  />
                  <HeadAndDesc
                    HeadTitle="Prize won"
                    SubTitle={data["Price won"]}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <Grid className="LeaderboardPageBox d-flex gapp-10">
        <Grid className="LBSectionF" xs={12} sm={12} md={8} lg={8} xl={8}>
          <ReactBootstrap.Table className="LeaderboardTable">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Name</th>
                <th>
                  Date
                  <span style={{ cursor: "pointer" }} onClick={ShowCalender}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.47473 6.22489C7.71881 5.98081 8.11454 5.98081 8.35861 6.22489L11.6919 9.55822C11.936 9.8023 11.936 10.198 11.6919 10.4421L8.35861 13.7754C8.11454 14.0195 7.71881 14.0195 7.47473 13.7754C7.23065 13.5314 7.23065 13.1356 7.47473 12.8916L10.3661 10.0002L7.47473 7.10877C7.23065 6.86469 7.23065 6.46896 7.47473 6.22489Z"
                        fill="#04D9FF"
                      />
                    </svg>
                  </span>
                  <div className="ShowChallengePopup">
                    {showCalender && <TableCalender />}
                  </div>
                </th>
                <th>AccoountSize</th>
                <th>
                  Challenge
                  <span style={{ cursor: "pointer" }} onClick={ShowChallenge}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.47473 6.22489C7.71881 5.98081 8.11454 5.98081 8.35861 6.22489L11.6919 9.55822C11.936 9.8023 11.936 10.198 11.6919 10.4421L8.35861 13.7754C8.11454 14.0195 7.71881 14.0195 7.47473 13.7754C7.23065 13.5314 7.23065 13.1356 7.47473 12.8916L10.3661 10.0002L7.47473 7.10877C7.23065 6.86469 7.23065 6.46896 7.47473 6.22489Z"
                        fill="#04D9FF"
                      />
                    </svg>
                  </span>
                  <div className="ShowChallengePopup">
                    {showChall && <ChallengePopup />}
                  </div>
                </th>
                <th>Prize won</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardList && leaderboardList.map(renderLeaderBoard)}
            </tbody>
          </ReactBootstrap.Table>
        </Grid>
        <Grid className="LBSectionS" xs={12} sm={12} md={4} lg={4} xl={4}>
          <div className="d-grid gapp-20">
            <Grid container className="gapp-20 Sec">
              <Grid className="LeaderBStat">
                <StatCard
                  StatName="Competition"
                  StatDetail="50 Competition "
                  StatIcon={compicon}
                />
              </Grid>
              <Grid className="LeaderBStat">
                <StatCard
                  StatName="Total Funding"
                  StatDetail="$ 20,750.00"
                  StatIcon={fundingicon}
                />
              </Grid>
            </Grid>
            <Grid container className="gapp-20 Sec">
              <Grid className="LeaderBStat">
                <StatCard
                  StatName="Pariticipated"
                  StatDetail="3052 Challenger"
                  StatIcon={lossicon}
                />
              </Grid>
              <Grid className="LeaderBStat">
                <StatCard
                  StatName="Total Winner"
                  StatDetail="750 Winner"
                  StatIcon={winicon}
                />
              </Grid>
            </Grid>
          </div>
          {/* <div>
            <TableComponent StyleName="LeaderboardShort"/>
          </div> */}
          <Grid container className="mtt-20">
            <Carousel
              showArrows={false}
              showStatus={false}
              className="CompetetionCarousel"
            >
              {advertisementList?.Published?.map((item) => (
                <CompetetionBanner
                  // HeadDate="December 2023"
                  CompName={item.Ad_name}
                  Prize={item.Description}
                  FromDatte={item.Start_date}
                  ToDate={item.End_date}
                />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Leaderboard;
