import React from "react";
import Title from "../ReusableComponents/title";

const StatCard = ({
  StatIcon,
  StatName,
  StatDetClass,
  StatDetail,
  StatDetailSec,
}) => {
  return (
    <div className="StatBox">
      <div className="StatF d-flex gapp-10 align-items-center mbb-10">
        {/* <img src={StatIcon} alt="" /> */}
        <Title three>{StatName}</Title>
      </div>
      <div
        className={`StatDetail d-flex ${StatDetClass}`}
        // style={{ border: `${StatName === "P&L" && "1px solid #FD504F"}` }}
      >
        {StatDetail && (
          <div
            className="InnerDiv"
            style={{
              display: "flex",
              color: `${StatName === "P&L" && "1px solid #FD504F"}`,
            }}
          >
            <div className="data_1">
              {StatDetail} {StatDetailSec && <div>{`(${StatDetailSec})`}</div>}{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default StatCard;
