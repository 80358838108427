import React, { useState, useRef } from "react";
import "./style.scss";
import CompanyLogo from "../../../assets/images/company-logo.png";
import LockIcon from "../../../assets/icons/lock.svg";
import LockOpenedIcon from "../../../assets/icons/lock-opened.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
// import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";

import { Link } from "react-router-dom";
// import { validatePassword } from "../../../utils/helpers/string";
import Tooltip from "../../../reusableComponents/Tooltip";

function ResetPasswordForm() {
  const [inputValue, setInputValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [inputError, setInputError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isInputError, setIsInputError] = useState({
    password: false,
    confirmPassword: false,
  });

  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    id === "password" && passwordRef.current.classList.remove("input-error");
    id === "confirmPassword" &&
      confirmPasswordRef.current.classList.remove("input-error");
  };

  const handleFocus = (e) => {
    const { id } = e.target;
    setIsInputError((prev) => ({ ...prev, [id]: false }));
  };

  const handlePasswordVisibility = (e) => {
    const { name } = e.target;
    setIsPasswordVisible((prev) => ({
      ...prev,
      [name]: !isPasswordVisible[name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = inputValue;

    // if (password === confirmPassword) {
    // if (password === "" || !validatePassword(password)) {
    //   passwordRef.current.classList.add("input-error");
    //   console.log(inputError.password);
    //   setIsInputError((prev) => ({ ...prev, password: true }));
    // }
    // if (confirmPassword === "" || !validatePassword(confirmPassword)) {
    //   confirmPasswordRef.current.classList.add("input-error");
    //   setIsInputError((prev) => ({ ...prev, confirmPassword: true }));
    // }

    // if (password === "") {
    //   setInputError((prev) => ({
    //     ...prev,
    //     password: "Please enter the new password",
    //   }));
    // } else if (!validatePassword(password)) {
    //   setInputError((prev) => ({
    //     ...prev,
    //     password: "Password must be atleast 8 characters",
    //   }));
    // }
    //     if (confirmPassword === "") {
    //       setInputError((prev) => ({
    //         ...prev,
    //         confirmPassword: "Please enter the new password",
    //       }));
    //     } else if (!validatePassword(confirmPassword)) {
    //       setInputError((prev) => ({
    //         ...prev,
    //         confirmPassword: "Password must be atleast 8 characters",
    //       }));
    //     } else {
    //       setInputError({ password: "", confirmPassword: "" });
    //       setIsInputError({ password: false, confirmPassword: false });
    //       passwordRef.current.classList.remove("input-error");
    //       confirmPasswordRef.current.classList.remove("input-error");
    //     }
    //   } else {
    //     setInputError({
    //       password: "",
    //       confirmPassword: "Password doesn't match",
    //     });
    //     setIsInputError({
    //       password: false,
    //       confirmPassword: true,
    //     });
    //     passwordRef.current.classList.add("input-error");
    //     confirmPasswordRef.current.classList.add("input-error");
    //   }
    //   try {
    //   } catch (err) {}
  };

  return (
    <div className="resetpasswordform-container">
      <div className="resetpasswordform-subcontainer">
        <div className="resetpasswordform-top-banner">
          <img src={CompanyLogo} alt="" />
          <h2 className="resetpasswordform-title">Reset Password</h2>
          <p className="resetpasswordform-subtitle">
            Let&#39;s reset your password
          </p>
        </div>
        <form className="resetpasswordform-innercontainer resetpasswordform-container_form">
          <div className="resetpasswordform-subcontainer_form">
          <div className="resetpassword-form-container">
            <div
              ref={passwordRef}
              className="resetpasswordform-innercontainer_form"
            >
              <img src={LockIcon} alt="" className="resetpasswordform-icon" />
                <label className="resetpasswordform-label" htmlFor="password">Password
                </label>
              <div className="resetpasswordform-container_input">
                <input
                  type={isPasswordVisible.password ? "text" : "password"}
                  name="password"
                  id="password"
                  className="resetpasswordform-input"
                  value={inputValue.password}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Password"
                />
              </div>
              <img
                name="password"
                src={isPasswordVisible.password ? EyeIcon : EyeSlashedIcon}
                alt=""
                className="resetpasswordform-icon"
                onClick={handlePasswordVisibility}
              />
            </div>
            <Tooltip isActive={isInputError.password}>
              {inputError.password}
            </Tooltip>
          </div>
          </div>
          {/* <p className="resetpasswordform-error">
            Must be at least 8 characters.
          </p> */}
          <div className="resetpasswordform-subcontainer_form  new-password">
          <div className="resetpassword-newpassword-container">
            <div
              ref={confirmPasswordRef}
              className="resetpasswordform-innercontainer_form"
            >
              <img
                src={LockIcon}
                alt=""
                className="resetpasswordform-icon"
              />
                <label
                  className="resetpasswordform-label"
                  htmlFor="confirmPassword"
                >Confirm Password
                </label>
              <div className="resetpasswordform-container_input">
                <input
                  type={isPasswordVisible.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="resetpasswordform-input"
                  value={inputValue.confirmPassword}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Confirm Password"
                />
              </div>
              <img
                name="confirmPassword"
                src={
                  isPasswordVisible.confirmPassword ? EyeIcon : EyeSlashedIcon
                }
                alt=""
                className="resetpasswordform-icon"
                onClick={handlePasswordVisibility}
              />
            </div>
            <Tooltip isActive={isInputError.confirmPassword}>
              {inputError.confirmPassword}
            </Tooltip>
          </div>
          </div>
          {/* <p className="resetpasswordform-error">
            {inputError}
          </p> */}
        </form>
          <div className="resetpasswordform-container_button">
            <button className="resetpasswordform-button" onClick={handleSubmit}>
              Reset Password
              {/* <img
                src={ArrowRightIcon}
                alt=""
                className="resetpasswordform-icon"
              /> */}
            </button>
          </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;