import { axiosInstance } from "../../Axios";

export const traderList = (data) => {
    return {
        type: "TRADER_LIST",
        payload: data,
    };
};

export const traderDetails = (data) => {
    return {
        type: "TRADER_DETAILS",
        payload: data,
    };
};

export const traderPayment = (data) => {
    return {
        type: "TRADER_PAYMENT",
        payload: data,
    };
};

export const traderProfile = (data) => {
    return {
        type: "TRADER_PROFILE",
        payload: data,
    };
};

export const traderID = (data) => {
    return {
        type: "TRADER_ID",
        payload: data,
    };
};

const initianstate = {
    trader_list: [],
    trader_details: [],
    trader_id: false,
    trader_payment: [],
    trader_profile: []
}


const TraderListReducer = (state = initianstate, action) => {
    switch (action.type) {
        case "TRADER_LIST":
            return {
                ...state,
                trader_list: action.payload
            }
        case "TRADER_DETAILS":
            return {
                ...state,
                trader_details: action.payload
            }
        case "TRADER_PAYMENT":
            return {
                ...state,
                trader_payment: action.payload
            }
        case "TRADER_PROFILE":
            return {
                ...state,
                trader_profile: action.payload
            }
        case "TRADER_ID":
            return {
                ...state,
                trader_id: action.payload
            }
        default:
            return state;
    }
}

export const fetchTraderList = () => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
        };
        const { data } = await axiosInstance.post("/trader-list/", payload);
        dispatch(traderList(data));
    }
    catch (error) { }
}

export const fetchTraderDetails = (id) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
        };
        const { data } = await axiosInstance.post(`/trader/${id}/`, payload);
        // dispatch(traderID(id))
        dispatch(traderDetails(data));
    }
    catch (error) { }
}

export const fetchTraderPayment = (user_id) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
        };
        const { data } = await axiosInstance.post(`/payment/${user_id}/`, payload);
        dispatch(traderPayment(data));
    }
    catch (error) { }
}

export const fetchTraderProfile = (user_id) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
        };
        const { data } = await axiosInstance.post(`/profile/${user_id}/`, payload);
        dispatch(traderProfile(data));
    }
    catch (error) { }
}

export default TraderListReducer