import { axiosInstance } from "../../Axios";

export const SupportCases = (data) => {
    return {
        type: "SUPPORT_CASES",
        payload: data,
    };
};
export const UserID = (data) => {
    return {
        type: "USER_ID",
        payload: data,
    };
};

const initianstate = {
    support_cases: [],
    user_id: []
}


const SupportCasesReducer = (state = initianstate, action) => {
    switch (action.type) {
        case "SUPPORT_CASES":
            return {
                ...state,
                support_cases: action.payload
            }
        case "USER_ID":
            return {
                ...state,
                user_id: action.payload
            }
        default:
            return state;
    }
}

export const fetchSupportCases = () => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
        };
        await axiosInstance.post("/support/cases/get/all/", payload)
            .then((res) => {

                // dispatch(UserID(res.data.id));
                dispatch(SupportCases(res.data));
            })

    }
    catch (error) { }
}

export const UpdateSupportCases = (data, id) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken;
        const payload = {
            idToken,
            ...data
        };
        await axiosInstance.patch(`/support/case/history/${id}/`, payload)
            .then((res) => {
                dispatch(fetchSupportCases());
            })

    }
    catch (error) { }
}

export default SupportCasesReducer