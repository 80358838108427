
import React from 'react'
import '../../assets/css/dashboard.scss'
import calender from '../../assets/images/calenderwhite.svg'

const CompetetionBanner = (props) => {
    return (
        // <div className='CompetetionBannerBox' style={{ backgroundImage: `url(${props.BackgroundImage})` }}>
        <div className='CompetetionBannerBox' style={{background: `url(${props.bg_img})`, backgroundSize: "contain"}}>
            <div className='CompBanner'>
                <div className='Head mbb-20'>{props.HeadDate}</div>
                <div className='Head mbb-20'>{props.CompName}</div>
                {props.PrizedGood && <div className='Prize mb-15'><span>{props.Prize}</span>-<span>{props.PrizedGood}</span> <span>({props.Challenge})</span></div>}
                <div className='Date d-flex gapp-10 mbb-30'>
                    <div><img src={calender} alt=""/></div>
                    <div className='DateTime'><span>{props.FromDatte}</span> to <span>{props.ToDate}</span></div>
                </div>
                <div className='Button'>
                   
                    <button onClick={props.onClickHandle}>Enter Competition</button>
                   
                </div>
            </div>
        </div>
    )
}
export default CompetetionBanner;