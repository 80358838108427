import { lazy } from 'react';

function wait(time) {
    return new Promise(resolve=>{
        setTimeout(resolve, time)
    })
}

export default function lazyLoad(importPromise, namedExport=null) {
    return lazy(()=>{
        const promise = importPromise;
        if(namedExport===null){
            return promise;
        }else{
            return promise.then(module=>({default: module[namedExport]}));
        }
    })
}