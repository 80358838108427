export const selectAddress = (data) => {
    return{
        type: 'SELECT_ADDRESS',
        payload: data
    }
}

const initialState = []

const AddressReducer = (state=initialState, action) => {
       switch(action.type){
           case 'SELECT_ADDRESS': 
           return({
               ...state,
               isSelected: action.payload
           })
           default:
               return state;
       }
}

export const dispatchSelectAddress = () => async (dispatch, getState) => {
    dispatch(selectAddress)
}
export default AddressReducer