import React, { useRef, useState } from "react";
import "./style.scss";
import { useEffect } from "react";
import CompanyLogo from "../../../assets/images/company-logo.png";
import EmailIcon from "../../../assets/images/SignupImg/Email.svg";
import LockIcon from "../../../assets/images/SignupImg/Password.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import EyeSlashedIcon from "../../../assets/icons/eye-slashed.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import GoogleIcon from "../../../assets/images/SignupImg/Google.svg";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import DiscordIcon from "../../../assets/icons/discord.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { validateEmail, validatePassword } from "../../../utils/helpers/string";
// import Tooltip from "../../../reusableComponents/Tooltip";
// import { authenticate, setAuthenticationStatus } from "../../../store/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../reusableComponents/Spinner";

import {
  getUserProfile,
  sessionTimeout,
} from "../../../store/reducers/userProfileReducer";
import { RefftokenD } from "../../../store/actions/index";
import { AcctokenD, login, logout } from "../../../store/actions";
import { axiosInstance } from "../../../Axios";
import CombinedPopup from "../../Popups/CombinedPopup";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import { setExpiry } from "../../../store/reducers/tokenReducer";
function SigninForm({ setActiveTab }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [inputError, setInputError] = useState({ email: "", password: "" });
  const [isInputError, setIsInputError] = useState({
    email: false,
    password: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    // id === "email" && emailRef.current.classList.remove("input-error");
    // id === "password" && passwordRef.current.classList.remove("input-error");
  };

  const handleFocus = (e) => {
    const { id } = e.target;
    setIsInputError((prev) => ({ ...prev, [id]: false }));
  };

  const handleGoogleAuth = async (e) => {
    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=code&state=pass-through value&redirect_uri=https://app.alphacapitalgroup.uk/auth/google&client_id=402277231112-imkppquf33eo2ap9btus3lr7m74okdvh.apps.googleusercontent.com";
  };

  const [Staff, setStaff] = useState(false);
  const [SuperUser, setSuperUser] = useState(false);

  const sessionTimeOut = useSelector((state) => state.user.sessionTimeOut);
  useEffect(() => {
    // if{dispatch(sessionTimeout(true));}
    if (sessionTimeOut) {
      setShowPopup(true);
      setPopUpType("error");
      setCombinedPUHead("Session expired!");
      setCombinedPUBody("Please login again");
      dispatch(sessionTimeout(false));
    }
  }, [sessionTimeOut]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const [showPopup, setShowPopup] = useState(false);
  const [combinedPUHead, setCombinedPUHead] = useState("");
  const [combinedPUBody, setCombinedPUBody] = useState("");
  const [popUpType, setPopUpType] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(window.location.origin, "origin");

  const handleSubmit = async () => {
    setIsLoading(true);
    console.log("==========inputValue==========================");
    console.log(inputValue);
    console.log("====================================");

    const data = {
      email: inputValue.email,
      password: inputValue.password,
      returnSecureToken: true,
    };
    axiosInstance
      .post("email/signin/", data)
      .then((res) => {
        setStaff(res.data.user_stat.isStaff);
        setSuperUser(res.data.user_stat.isSuperUser);
        if (res.data.error) {
          if (res.data.error.message) {
            setShowPopup(true);
            setPopUpType("error");
            setCombinedPUHead("Oh snap!");
            setCombinedPUBody(res.data.error.message);
            setIsLoading(false);
            return;
          }
        }

        dispatch(AcctokenD(res.data.idToken));
        dispatch(RefftokenD(res.data.refreshToken));

        const expiresInSeconds = 86400;
        const expiresAtDate = new Date();
        expiresAtDate.setSeconds(expiresInSeconds);
        dispatch(setExpiry(expiresAtDate));

        dispatch(getUserProfile()).then((data) => {
          if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length === 0
          ) {
            navigate("/FundingEvaluationPayment/4");
            setLoading(false);
          } else if (
            Array.isArray(data["accounts"]) &&
            data["accounts"].length > 0
          ) {
            navigate("/AccountMetric");
            setLoading(false);
          }
        });

        // if (res.data.user_stat.isStaff === true) {
        //   dispatch(isAdmin(true));
        //   navigate("/admin/TraderList");
        // } else {
        //   window.location.reload();
        //    navigate('/login')
        // }

        // navigate('/admin/TraderList')
        dispatch(login());
        // navigate('/FundingEvaluation')
      })
      .catch((error) => {
        const errorMsg = toTitleCase(
          error.response.data.detail.replaceAll("_", " ")
        );
        setIsLoading(false);
        if (error.response) {
          if (error.response.data.detail) {
            setShowPopup(true);
            setPopUpType("error");
            setCombinedPUHead("Oh snap!");
            setCombinedPUBody(errorMsg);
            return;
          }
        }
        console.log(error);
        dispatch(AcctokenD(0));
        dispatch(RefftokenD(0));
        dispatch(logout());
      });
  };

  return (
    <>
      {showPopup && (
        <ErrorModal
          setModalOpen={setShowPopup}
          desc={combinedPUBody}
          btnText="Close"
        />
      )}{" "}
      <div className="signinform-container">
        {/* <CombinedPopupPA
        type={popUpType}
        trigger={showPopup}
        setTrigger={setShowPopup}
        head={combinedPUHead}
        subhead={combinedPUBody}
      /> */}

        <div className="signinform-subcontainer">
          <div>
            <div className="signinform-top-banner">
              {/* <img src={CompanyLogo} alt="" /> */}
              <h2 className="signinform-title">Sign In</h2>
              <p className="signinform-subtitle">
                Welcome Back! Please enter your Details
              </p>
            </div>
            <form className="signinform-innercontainer signinform-container_form">
              <div className="signinform-subcontainer_form">
                <div className="signinform-email-container">
                  <div
                    // ref={emailRef}
                    className="signinform-innercontainer_form"
                  >
                    <div className="signinform-container_icon">
                      <img src={EmailIcon} alt="" className="signinform-icon" />
                    </div>
                    <label className="signinform-label" htmlFor="email">
                      Email Address
                    </label>
                    <div className="signinform-container_input">
                      <input
                        style={{ color: "#000 !important" }}
                        type="text"
                        name="email"
                        id="email"
                        className="signinform-input"
                        value={inputValue.email}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder="Enter Your E-Mail"
                      />
                    </div>

                    <div className="signinform-container_icon">
                      {/* {validateEmail(inputValue.email) && (
                  <img
                    src={CheckSolidGreenIcon}
                    alt=""
                    className="signinform-icon"
                  />
                )} */}
                    </div>
                  </div>
                  {/* <Tooltip isActive={isInputError.email}>{inputError.email}</Tooltip> */}
                </div>
              </div>

              <div className="signinform-subcontainer_form">
                <div className="signinform-password-container">
                  <div
                    // ref={passwordRef}
                    className="signinform-innercontainer_form"
                  >
                    <div className="signinform-container_icon">
                      <img src={LockIcon} alt="" className="signinform-icon" />
                    </div>
                    <label className="signinform-label" htmlFor="password">
                      Password
                    </label>
                    <div className="signinform-container_input">
                      <input
                        // type="password"
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        id="password"
                        className="signinform-input"
                        value={inputValue.password}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder="Enter Your Password"
                      />
                    </div>

                    <div className="signinform-container_icon">
                      <img
                        src={isPasswordVisible ? EyeIcon : EyeSlashedIcon}
                        alt=""
                        className="signinform-icon"
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  {/* <Tooltip isActive={isInputError.password}>{inputError.password}</Tooltip> */}
                </div>
              </div>
              {/* <p className="signinform-error">The password entered is wrong!</p> */}

              {/* <div
              className="signinform-subcontainer_form signinform-container_remember-me"
              style={{ marginTop: "40px" }}
            >
              <div className="signinform_subcontainer_wrapper">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="checkbox"
                  className="signinform-input"
                />
                <label
                  className="signinform-label signinform-label_checkbox"
                  htmlFor="checkbox"
                >
                  Remember me
                </label>
              </div>
              <div className="signinform_subcontainer_wrapper">
                <Link to="/ForgotPassword">
                  <label
                    className="signinform-label forgot_password"
                    htmlFor="checkbox"
                  >
                    Forgot Password ?
                  </label>
                </Link>
              </div>
            </div> */}
              <div
                className="forgotPasswordText"
                onClick={() => setActiveTab("forgot-password")}
              >
                <p>Forgot Password ?</p>
              </div>
              <div className="signinform-container_button">
                <button
                  className="signinform-button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      Sign in
                      {/* <img
                    src={ArrowRightIcon}
                    alt=""
                    className="signinform-icon"
                  /> */}
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>

          <div className="signup_with_google" onClick={handleGoogleAuth}>
            <p className="signinform-continue">Or sign in using</p>
            <div className="signinform-innercontainer signinform-container_icon">
              <div className="signinform-subcontainer_icon">
                <img
                  style={{ cursor: "pointer" }}
                  src={GoogleIcon}
                  alt="google logo"
                />
              </div>
              {/* <div className="signinform-subcontainer_icon">
            <img
              src={DiscordIcon}
              alt=""
              className="signinform-icon"
            />
          </div> */}
              {/* <div className="signinform-subcontainer_icon">
            <img src={FacebookIcon} alt="" className="signinform-icon" />
            <div>
              <p>Facebook</p>
            </div>
          </div> */}
            </div>
            {/* <p className="signinform-link">
              Don&#39;t have an account?{" "}
              <Link to="/signup">
                <b className="signinform-link_bold">Sign Up</b>
              </Link>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SigninForm;
