import lazyLoad from "../lazyLoad";

// Essential components first
export const LoginForm = lazyLoad(import("../forms/LoginForm"));
export const ForgotPassword = lazyLoad(import("./../forms/ForgotPassword"));
export const EnterOTP = lazyLoad(import("../forms/EnterOTP"));
export const SignUpForm = lazyLoad(import("./../forms/SignUpForm"));
export const SuccessPopup = lazyLoad(import("../components/Popups/Success"));
export const ResetPassword = lazyLoad(import("./../forms/ResetPassword"));
export const PageLayout = lazyLoad(
  import("../components/pageLayout/PageLayout")
);
export const UserRoute = lazyLoad(import("../components/Routes/UserRoute"));
export const CompetitionRule = lazyLoad(
  import("../components/Competition/CompetitionRules")
);
export const ChallengesBoxM = lazyLoad(
  import("../components/ReusableComponents/ChallengesBoxM")
);
export const Checkout = lazyLoad(import("./Checkout/Checkout"));
export const PageNotFound = lazyLoad(import("./PageNotFound"));
export const Achievements = lazyLoad(import("./Achievements/indexNew"));
export const UnAuthCheckout = lazyLoad(
  import("./UnAuthCheckout/UpdatedPublicCheckout")
);
export const NewWPCheckout = lazyLoad(import("./NewWPCheckout/NewWPCheckout"));
export const AccountMetric = lazyLoad(import("./AccountMetric"));
export const FundingEvaluation = lazyLoad(
  import("./Payment/FundingEvaluation")
);
export const FundingEvaluationPayment = lazyLoad(
  import("./fundingEvaluationPayment/FundingEvaluationPayment")
);
export const SupportCenter = lazyLoad(import("./SupportCenter"));
export const Profile = lazyLoad(import("./profile/Profile"));
export const CompetitionList = lazyLoad(import("./CompetitionList"));
export const LeaderboardParticipated = lazyLoad(
  import("./LeaderboardParticipated")
);
export const AccountAnalysis = lazyLoad(import("./AccountAnalysis"));
export const PaymentHistory = lazyLoad(import("./Payment/PaymentHistory"));
export const LeaderboardMobile = lazyLoad(import("./LeaderboardMobile"));
export const CompetitionOverview = lazyLoad(import("./CompetitionOverview"));
export const CompetitionOverviewV2 = lazyLoad(
  import("./CompetitionOverviewV2")
);
export const Trademate = lazyLoad(import("./Trademate/Trademate"));
export const AffiliateMarketing = lazyLoad(import("./AffiliateMarketing"));
export const GettingStarted = lazyLoad(import("./GettingStarted"));
export const NewDashboard = lazyLoad(import("./NewDashboard"));
export const PaymentStatus = lazyLoad(
  import("./fundingEvaluationPayment/PaymentStatus")
);
export const GetDocuSignSigningURL = lazyLoad(
  import("./docusign/GetSigningURL")
);
export const DocuSignSigningCompleted = lazyLoad(
  import("./docusign/SigningCompleted")
);
export const TradingPlatform = lazyLoad(import("./TradingPlatform"));
export const Test = lazyLoad(import("./Test"));
