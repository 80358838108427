import { axiosInstance } from "../../Axios";
import { saveError } from "./dashboardReducer";
import logoutReducer from './logout.js';

const setAchievementAccounts = (data) => ({
    type: "SET_ACHIEVEMENT_ACCOUNTS",
    payload: data
})

const setKYC = (data) => ({
    type: "SET_KYC",
    payload: data
})

const initialState = {
    kyc: false
};

const achievementsReducer = (state=initialState, action) => {
    switch (action.type) {
        case "SET_ACHIEVEMENT_ACCOUNTS":
            return {
                ...state,
                ...action.payload
            }
        case "SET_KYC":
            return {
                ...state,
                kyc: action.payload
            }
    
        default:
            return state;
    }
}

export const fetchAchievementAccounts  =  () => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken
        const payload = {idToken};
        const {data, status}  = await axiosInstance.post("v2/achievements/accounts/", payload);
        if(status===401){
            dispatch(logoutReducer());
            return
        }
        dispatch(setAchievementAccounts(data));

    } catch (error) {
        dispatch(saveError(error));
    }
}

export const fetchKYC = () => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken
        const payload = {idToken};
        const {data, status}  = await axiosInstance.post("/kyc/get/", payload);
        if(status===401){
            dispatch(logoutReducer());
            return
        }
        dispatch(setKYC(data));

    } catch (error) {
        dispatch(saveError(error));
    }
}

export const uploadKYC = (form_data) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken
        form_data.append('idToken', idToken);
        const {data, status}  = await axiosInstance.post("/kyc/upload/", form_data);
        if(status===401){
            dispatch(logoutReducer());
            return
        }
        dispatch(fetchKYC());
        return data

    } catch (error) {
        dispatch(saveError(error));
        throw(error)
    }
}

export const deleteKYC = (id) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken
        const payload = {idToken, id};
        const {data, status}  = await axiosInstance.post("/kyc/delete/", payload);
        if(status===401){
            dispatch(logoutReducer());
            return
        }
        dispatch(fetchKYC());
        return data

    } catch (error) {
        dispatch(saveError(error));
        throw(error)
    }
}

export const withdrawRequest = (payload) => async (dispatch, getState) => {
    try {
        const idToken = await getState().ACCtoken
        payload['idToken'] = idToken
        const {data, status}  = await axiosInstance.post("support/cases/create/", payload);
        if(status===401){
            dispatch(logoutReducer());
            return
        }
        if(status>399) throw(data);
        return data

    } catch (error) {
        dispatch(saveError(error));
        throw(error.response.data);
    }
}

export default achievementsReducer