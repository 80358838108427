import { axiosInstance } from "../../Axios";
import { AcctokenD, RefftokenD } from "../actions";
import { sessionLogoutReducer } from "./logout";

const tokenReducer = (state = 0, action) => {
  switch (action.type) {
    case "SETACCTOKEN":
      return (state = action.payload);
    default:
      return state;
  }
};

const RefftokenReducer = (state = 0, action) => {
  switch (action.type) {
    case "SETREFFACCTOKEN":
      state=null;
      return action.payload;
    default:
      return state;
  }
};

const tokenExpiryReducer = (state = 0, action) => {
  switch (action.type) {
    case 'SET_EXPIRY':
      return (state = action.payload)
    case 'CLEAR_EXPIRY':
      return 0;
    default:
      return state
  }
}

export const setExpiry = (payload) => ({
  type: "SET_EXPIRY",
  payload
})

export const clearExpiry = () => ({
  type: 'CLEAR_EXPIRY'
})

export const apiConfig = () => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  config.headers[
    "x-api-key"
  ] = `ONRzS8gkWBRkr76XYyI6z1e6p6NX72x83KABC4dpx90sSxgzF9n3QqlQoBHGvGZKgu3a2hVPVtMeUYxlyILZMK5cZo2VspEKWORYvdN4fk1WVmtALKKlfwAFFXRpBFyIGQOcNHRtn0dsr6YJhhdNXrDh4Fk5dlt4Cns2CPl5e7QgByxNaSpfJE6vewJVsgmp6KMMWnnOqyMmBWw33NQyTbdP0gCwaLHPlspCEWJDokCUFSVf0IwG8hWJkulr5Al`;

  return config;
};

export const tokenConfig = (getState) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (getState().ACCtoken !== 0) {
    config.headers["AUTHORIZATION"] = getState().ACCtoken;
  }

  return config;
};


export const getRefreshToken = () => async (dispatch, getState) => {
  try {
      const REFFtoken = await getState().REFFtoken;
      if(!REFFtoken){
        const error_msg = new Error("Please login again!")
        throw(error_msg)
      }
      const payload = {
          refresh_token: REFFtoken
      };
      const { data } = await axiosInstance.post(`get/refreshtoken/`, payload);
      dispatch(AcctokenD(data.idToken));
      dispatch(RefftokenD(data.refreshToken));
      return {"access_token": data.idToken, "refresh_token": data.refreshToken}
  } catch (error) {
      if(error.response.status === 401  || error.response.data?.detail === "invalid-auth-token"){
          dispatch(sessionLogoutReducer());
          return
      }
      throw(error)
  }
}

export const handleInvalidToken = (by_pass_refresh_token=false) => async (dispatch, getState) => {
  try {
    const expiresAtDate = getState().expiresIn;
    const expiresAtDateIns = new Date(expiresAtDate);
    const now = new Date();
    const timeleft_in_milisec = (expiresAtDateIns - now);
    
    if(by_pass_refresh_token || timeleft_in_milisec <=0) throw new Error({ code : 403, message : "session timeout" });
    return dispatch(getRefreshToken()).then(res=>{
      if("access_token" in res) return res["access_token"] ;
      return 
    }).catch(error=>{
      if(error.response.data?.detail === "INVALID_REFRESH_TOKEN"){
        dispatch(sessionLogoutReducer());
      }
      throw(error)
    })
  } catch (error) {
    dispatch(sessionLogoutReducer());
  }
}

export { tokenReducer, RefftokenReducer, tokenExpiryReducer };
