import React, { useEffect, useState } from "react";
import "./style.scss";
import CompanyLogo from "../../../assets/images/companyLogo.png";
import AuthFrame from "../../../components/Auth/AuthFrame";
import SigninForm from "../../../components/Auth/SigninForm";
// import { useSelector } from "react-redux";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import { setTheme } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import SignupForm from "../../../components/Auth/SignupForm";

import Logo from "../../../assets/images/SignupImg/Logo.svg";
import vector_background_left from "../../../assets/images/SignupImg/vector_background_left.svg";
import vector_background_right from "../../../assets/images/SignupImg/vector_background_right.svg";
import ForgotPasswordForm from "../../../components/Auth/ForgotPasswordForm";

function Signin() {
  const [errormodalOpen, setErrorModalOpen] = useState(false);
  // const [msg, setMsg] = useState("");
  // const { theme } = useSelector((state) => state.theme);
  // useEffect(() => {
  //   document.body.classList["value"] === "" &&
  //     (document.body.classList = theme);
  // }, [theme]);
  // const authState = useSelector((state) => state.auth);
  // useEffect(() => {
  //   setMsg(authState.message);
  //   setErrorModalOpen(authState.error);

  // }, [authState]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTheme("light"));
  }, []);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("signin");
  const [isAuthFrameHidden, setIsAuthFrameHidden] = useState(false); // State to hide/show AuthFrame

  useEffect(() => {
    dispatch(setTheme("light"));
  }, []);

  // Function to handle tab click and hide AuthFrame
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setIsAuthFrameHidden(true); // Hide AuthFrame on tab click
  };

  const location = useLocation();

  // For path wise rendering
  useEffect(() => {
    if (location.pathname === "/signup" || location.pathname === "/signup/") {
      setActiveTab("signup");
    } else if (location.pathname === "/forgot-password") {
      setActiveTab("forgot-password");
    } else {
      setActiveTab("signin");
    }
  }, [location.pathname]);

  // For Scrolling to top
  useEffect(() => {
    document
      .querySelector(".signin-container")
      .scrollTo({ top: 0, behavior: "smooth" });
  }, [activeTab, location.pathname]);

  return (
    <div
      className={`signin-container ${
        activeTab === "signin" || activeTab === "forgot-password"
          ? "overFlow_hidden"
          : "overflow_normal"
      }`}
    >
      {/* <img src={vector_background_left} alt="" /> */}

      {errormodalOpen && (
        <ErrorModal
          setModalOpen={setErrorModalOpen}
          // desc={msg}
          btnText="Close"
        />
      )}
      <div className="signin-left-wrapper">
        <div className={`signin-right ${isAuthFrameHidden ? "hidden" : ""}`}>
          <AuthFrame />
          <div
            className={`auth_mobileTabs ${isAuthFrameHidden ? "hidden" : ""}`}
          >
            <button
              className={activeTab === "signin" ? "active" : ""}
              onClick={() => handleTabClick("signin")}
            >
              Sign In
            </button>
            <div className="auth_mobileTabs_line"></div>
            <button
              className={activeTab === "signup" ? "active" : ""}
              onClick={() => handleTabClick("signup")}
            >
              Sign Up
            </button>
          </div>
        </div>

        <div className={`signin-left ${isAuthFrameHidden ? "hidden" : ""}`}>
          <div className={`authLogo_img ${!isAuthFrameHidden ? "hidden" : ""}`}>
            <img src={Logo} alt="Logo" />
          </div>

          <div className="signup_signin_tab">
            <div className="signup_signin_tab_inner">
              <button
                className={activeTab === "signin" ? "active" : ""}
                onClick={() => setActiveTab("signin")}
              >
                <span>Sign In</span>
              </button>
              <button
                className={activeTab === "signup" ? "active" : ""}
                onClick={() => setActiveTab("signup")}
              >
                <span>Sign Up</span>
              </button>
            </div>
          </div>
          {activeTab === "signin" ? (
            <SigninForm setActiveTab={setActiveTab} />
          ) : activeTab === "signup" ? (
            <SignupForm />
          ) : activeTab === "forgot-password" ? (
            <ForgotPasswordForm />
          ) : (
            <SigninForm />
          )}
        </div>
      </div>
    </div>
  );
}

export default Signin;
