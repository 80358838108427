import { ButtonBase, styled } from '@mui/material'
import React from 'react'

const OutlinedButton = styled((props)=>(
    <ButtonBase {...props} />
    ))(({theme})=>({
        background:'#161D26',
        borderRadius: '30px',
        border: '1px solid #3EAFFF',
        color: '#3EAFFF',
        fontFamily: 'Outfit',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        padding: "10px",
        width: '100%'

    }))

export default OutlinedButton;
